import React from 'react';
import c from 'classnames';
import intl from 'react-intl-universal';

import { NextImage } from '@/components';

import styles from './index.module.less';
import { ColumnListItemPropsType } from '../type';

const MColumnListItem = (props: ColumnListItemPropsType) => {
    const { columnData, onFocusColumn, isShowFocus } = props;
    const { cover, title, is_focus } = columnData || {};
    return (
        <div className={c(styles.wrapper, props.className || '')}>
            <div className={styles.cover}>
                <NextImage src={cover?.url} layout="fill" objectFit="cover" />
            </div>
            <div className={styles.info__wrapper}>
                <div className={styles.title}>{title}</div>
            </div>
            {isShowFocus && (
                <div className={c(styles.focus, { [styles.not]: is_focus !== 1 })} onClick={onFocusColumn}>
                    {is_focus === 1 ? (
                        intl.get('Topic.AlreadyFollowed').d('已订阅')
                    ) : (
                        <React.Fragment>
                            <NextImage src={'/h5/attention.png'} alt="attention" needPrefix width={14} height={14} />
                            <span>{intl.get('Topic.Followed').d('订阅')}</span>
                        </React.Fragment>
                    )}
                </div>
            )}
        </div>
    );
};

export default MColumnListItem;
