import React from 'react';
import c from 'classnames';

import { currentLocaleUrl } from '@/utils';
import { NextImage } from '@/components';

import styles from './index.module.less';
import { ActivityListItemType } from '../type';
import { useActivityTime, useStateInfo } from '../hook';
import intl from 'react-intl-universal';

const MActivityListItem = (props: ActivityListItemType) => {
    const { dataSource, className, showRegion = true } = props;
    const { id, picture, title, activityStartTimeMon, activityEndTimeMon, city } = dataSource || {};

    /** 格式显示时间*/
    const { activityTime } = useActivityTime(activityStartTimeMon, activityEndTimeMon);

    /** 获取状态信息*/
    const { activityState, region, countdownPrefix } = useStateInfo(activityStartTimeMon, activityEndTimeMon, city);

    return (
        <a href={currentLocaleUrl(`/activity/${id}`)} className={className || ''} target="_blank">
            <div className={styles.listItem}>
                <div className={styles.listItemCover}>
                    <NextImage src={picture} layout="fill" alt={title} />
                </div>
                <div className={styles.listItemContent}>
                    <div className={styles.title}>{title}</div>
                    <div className={styles.time_con} style={{ marginTop: showRegion ? 8 : 4 }}>
                        <div className={styles.time}>
                            <span className={c(styles.arrow__more, 'iconfont', 'icon-shijian1')} />
                            <span>{activityTime}</span>
                        </div>
                    </div>
                    <div className={styles.btns}>
                        {showRegion && (
                            <span className={styles.city}>
                                <i className={c(styles.icon, 'iconfont', 'icon-dingwei')} />
                                <span>{region}</span>
                            </span>
                        )}
                        {activityState === 1 && (
                            <div className={styles.countdown}>
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: intl.get('Activity.ManyDayEvent', {
                                            field1: countdownPrefix,
                                            field2: intl.get('Activity.Start').d('开始'),
                                            field3: styles.before,
                                        }),
                                    }}
                                />
                            </div>
                        )}
                        {activityState === 2 && (
                            <span className={c(styles.countdown, styles.progressing)}>
                                <span>{intl.get('Activity.Progress').d('进行中')}</span>
                            </span>
                        )}
                        {activityState === 3 && (
                            <span className={c(styles.countdown, styles.end)}>
                                <span>{intl.get('Activity.Over').d('已结束')}</span>
                            </span>
                        )}
                    </div>
                </div>
            </div>
        </a>
    );
};

export default MActivityListItem;
