import React, { useState, useMemo, useRef, memo, forwardRef, useImperativeHandle } from 'react';
import TransferCom from '@/components/public/TransferCom';
import { ModalStyle as PcModalStyle, TitleRender as PcTitleRender } from './pc';
import { ModalStyle as MModalStyle, TitleRender as MTitleRender } from './m';

interface ModalProps {
    confirm: (init: any) => void;
    setParmas: (parmas: any) => void;
    open: () => void;
    close: () => void;
}

const ModalContent = memo(
    forwardRef((_parmas: any, ref) => {
        const [open, setOpen] = useState<boolean>(false);
        const [modalProps, setModalProps] = useState<any>({});
        const [modalChildren, setModalChildren] = useState<React.ReactNode>();

        useImperativeHandle(
            ref,
            () => ({
                // 注入参数
                injectProps: (props: any) => {
                    const { children, open, title, ...resProps } = props;
                    setOpen(open);
                    const titleRender = (
                        <TransferCom
                            pc={<PcTitleRender title={title} />}
                            ipad={<PcTitleRender title={title} />}
                            m={<MTitleRender title={title} />}
                        />
                    );
                    setModalProps({
                        ...resProps,
                        title: titleRender,
                    });
                    setModalChildren(children);
                },
                // 打开
                open: () => {
                    setOpen(true);
                },
                // 关闭
                close: () => {
                    setOpen(false);
                },
            }),
            [],
        );

        const onClose = () => {
            setOpen(false);
        };

        const normalProps = {
            open,
            onCancel: onClose,
            children: modalChildren,
            ...modalProps,
        };

        return (
            <TransferCom
                pc={<PcModalStyle {...normalProps} />}
                ipad={<PcModalStyle {...normalProps} />}
                m={<MModalStyle {...normalProps} />}
            />
        );
    }),
);

interface CustomModalProps {
    open: () => void;
    close: () => void;
    injectProps: (a: any) => void;
}

const CustomModal = () => {
    const modalRef = useRef<CustomModalProps>(null);

    const handle = useMemo(() => {
        return {
            /** 弹窗初始配置并启动 */
            confirm: (init: any) => {
                modalRef?.current?.injectProps({ ...init }); // 注入参数
            },
            /** 设置参数 */
            setParmas: (parmas: any) => {
                modalRef?.current?.injectProps({ ...parmas });
            },
            /** 打开弹窗 */
            open: () => {
                modalRef?.current?.open();
            },
            /** 关闭弹窗 */
            close: () => {
                modalRef?.current?.close();
            },
        };
    }, []);

    return [handle, <ModalContent key="modal" ref={modalRef} />] as [ModalProps, React.JSX.Element];
};

export default CustomModal;
