import TransferCom from '@/components/public/TransferCom';
import MBusinessTag from './m';
import PCBusinessTag from './pc';
import type { BusinessTagProps } from './type';

/**
 * 交易商 业务类型
 * @param props
 * @returns
 */
const BusinessTag: React.FC<BusinessTagProps> = (props) => {
    return (
        <TransferCom
            m={<MBusinessTag {...props} />}
            ipad={<PCBusinessTag {...props} />}
            pc={<PCBusinessTag {...props} />}
        />
    );
};

export default BusinessTag;
