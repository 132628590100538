import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import c from 'classnames';

import styles from './index.module.less';
import { highlightPage } from './config';
import { currentLocaleUrl, formatMts, resizeObserverFun } from '@/utils';
import Link from 'next/link';
import intl from 'react-intl-universal';

const FooterMenus = ({ menus }: { menus: any[] }) => {
    const menusRef = useRef<any>(); // 目录容器
    const menusWrapperRef = useRef<any>(); // 目录外层容器
    // 目录宽度
    const [menusWidth, setMenusWidth] = useState<number>(0);
    // 是否展示
    const [isShow, setIsShow] = useState<boolean>(false);
    // 目录外层宽度
    const [wrapperWidth, setWrapperWidth] = useState<number>(0);
    // 页面语言，用于重新执行宽度计算
    const currentLocale = intl.getInitOptions().currentLocale || 'zh-CN';
    // 列数
    const gridNum = useRef<number>(9);
    // 间距
    const gapStyleRef = useRef<string>('40px 60px');

    /**
     * 渲染菜单每项
     */
    const navItemRender = useCallback((e: any) => {
        const { id, name, nameMts, web, type, inventedUrl } = e;
        const navMtsName = formatMts(nameMts) || name;
        switch (type) {
            case 'page': // 展示页面
                return (
                    <Link href={`/${inventedUrl || id}`}>
                        <a target="_blank" className={styles.aHref}>
                            {navMtsName}
                        </a>
                    </Link>
                );

            case 'web': // 第三方链接
                const { url } = web;
                return (
                    <a className={styles.aHref} href={currentLocaleUrl(url)} target="_blank">
                        <span>{navMtsName}</span>
                    </a>
                );
            default:
                return null;
        }
    }, []);

    useEffect(() => {
        resizeObserverFun(menusRef.current, setMenusWidth);
        resizeObserverFun(menusWrapperRef.current, setWrapperWidth);
    }, []);

    useEffect(() => {
        gridNum.current = 9;
        gapStyleRef.current = '40px 60px';
        setIsShow(false);
    }, [currentLocale]);

    /** 间距样式 */
    const gapStyle = useMemo(() => {
        if (wrapperWidth >= menusWidth && !isShow) {
            const diffWidth = wrapperWidth - menusWidth;
            const len = menus.length;
            const diffGap = diffWidth / len;
            setIsShow(true);
            gapStyleRef.current = `40px ${diffGap > 60 ? '120' : `${diffGap + 60}`}px`;
            return {
                gap: gapStyleRef.current,
                gridTemplateColumns: `repeat(${gridNum.current}, min-content)`,
            };
        }

        if (wrapperWidth < menusWidth) {
            gridNum.current = gridNum.current - 1;
        }
        return { gap: gapStyleRef.current, gridTemplateColumns: `repeat(${gridNum.current}, min-content)` };
    }, [menusWidth, wrapperWidth, currentLocale, gapStyleRef.current]);

    return (
        <div className={styles.wrapper__menus}>
            <div className={styles.main__menus}>
                <div className={styles.left__main} style={{ opacity: isShow ? 1 : 0 }} ref={menusWrapperRef}>
                    <div className={styles.wrapper__left__menus} style={gapStyle} ref={menusRef}>
                        {[...menus].map((e: any) => {
                            const { id, children = [] } = e;
                            return (
                                <div key={id} className={c(styles.wrapper__label)}>
                                    <div className={c(styles.top__label)}>{navItemRender(e)}</div>
                                    <ul className={c(styles.wrapper__children)}>
                                        {children.map((cItem: any) => {
                                            return (
                                                <li key={cItem.id} className={c(styles.item__label)}>
                                                    {navItemRender(cItem)}
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </div>
                            );
                        })}
                    </div>
                </div>
                <em className={styles.line__main} />
                <div className={styles.right__main}>
                    {highlightPage.map((pItem) => {
                        const { label, link, mtsKey } = pItem;
                        return (
                            <a
                                key={mtsKey}
                                target="_blank"
                                href={currentLocaleUrl(link)}
                                className={styles.label__right}
                            >
                                {intl.get(mtsKey).d(label)}
                            </a>
                        );
                    })}
                </div>
            </div>
            {/* 线 */}
            <em className={styles.line} />
        </div>
    );
};

export default FooterMenus;
