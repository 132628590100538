import React, { useContext, useRef, useState } from 'react';
import { Button, Form, Input } from 'antd';
import intl from 'react-intl-universal';

import AuthCode from '@/components/public/AuthCode';
import formUtils from '@/utils/form';

import styles from './index.module.less';
import { LoginContext } from '../../content';
import { NextImage } from '@/components';
import BindAccount from '../BindAccount';

const { pleaseEnter, pleaseEnterCorrect } = formUtils;
const { Password } = Input;

const Email = () => {
    const { loading, verifyType, formType, loginBtnText, emailForm, onFormFinish } = useContext(LoginContext);

    const validateMobile = () => {
        return emailForm?.validateFields(['mail']);
    };

    // 禁用提交按钮
    const btnDisabel = (value: any) => {
        const { verificationCode, password, confirm, mail } = value;

        // 绑定流程的额外判断
        const bindDisable =
            formType === 'bind' ? !confirm || !password || !(verificationCode && verificationCode.length === 6) : false;

        return (!(verificationCode && verificationCode.length === 6) && !password) || !mail || bindDisable;
    };

    return (
        <div className={styles.mobile}>
            <Form form={emailForm} size="large" autoComplete="off" className={styles.form} onFinish={onFormFinish}>
                <Form.Item
                    style={{ marginBottom: 40 }}
                    name="mail"
                    rules={[
                        { required: true, message: pleaseEnter(intl.get('BindAccount.Email').d('请输入电子邮箱')) },
                        {
                            type: 'email',
                            message: pleaseEnterCorrect(intl.get('BindAccount.Email').d('请输入正确的电子邮箱')),
                        },
                    ]}
                >
                    <Input
                        className={styles.email}
                        placeholder={pleaseEnter(intl.get('BindAccount.Email').d('请输入电子邮箱'))}
                    />
                </Form.Item>
                {verifyType === 'code' && (
                    <Form.Item
                        style={{ marginBottom: 40 }}
                        name="verificationCode"
                        rules={[
                            {
                                required: true,
                                message: pleaseEnter(intl.get('Common.VerificationCode').d('请输入验证码')),
                            },
                            {
                                len: 6,
                                message: pleaseEnter(intl.get('Common.SixVerificationCode').d('请输入6位长度的验证码')),
                            },
                        ]}
                    >
                        <Input
                            placeholder={pleaseEnter(intl.get('Common.VerificationCode').d('请输入验证码'))}
                            maxLength={6}
                            type="number"
                            suffix={<AuthCode validate={validateMobile} />}
                        />
                    </Form.Item>
                )}
                {verifyType === 'password' && (
                    <Form.Item
                        style={{ marginBottom: 40 }}
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: pleaseEnter(intl.get('BindAccount.Password').d('请输入密码')),
                            },
                        ]}
                    >
                        <Password
                            iconRender={(iconVisible) =>
                                iconVisible ? (
                                    <NextImage
                                        src="/img/login/pwd_active.webp"
                                        width={22}
                                        style={{ cursor: 'pointer' }}
                                        height={22}
                                        alt="pwd_active"
                                    />
                                ) : (
                                    <NextImage
                                        style={{ cursor: 'pointer' }}
                                        src="/img/login/pwd.webp"
                                        width={22}
                                        height={22}
                                        alt="pwd"
                                    />
                                )
                            }
                            autoComplete="new-password"
                            className={styles.pwd}
                            placeholder={pleaseEnter(intl.get('BindAccount.Password').d('请输入密码'))}
                        />
                    </Form.Item>
                )}

                {['bind'].includes(formType) && <BindAccount />}

                <Form.Item style={{ marginBottom: 16 }} shouldUpdate>
                    {({ getFieldsValue }) => {
                        return (
                            <Button
                                className={styles.btn}
                                type="primary"
                                htmlType="submit"
                                block
                                size="large"
                                disabled={btnDisabel(getFieldsValue())}
                                loading={loading}
                                aria-label="Registration Or Login"
                            >
                                {loginBtnText}
                            </Button>
                        );
                    }}
                </Form.Item>
            </Form>
        </div>
    );
};

export default Email;
