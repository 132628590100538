import { useAppSelector } from '@/store/hook';
import { formatMts } from '@/utils';
import c from 'classnames';
import styles from './index.module.less';
import type { NormalTitlePros } from '../types';

const NormalTitle: React.FC<NormalTitlePros> = (props) => {
    const { title, titleMts, customClass } = props;
    const { showBarkly } = useAppSelector((state: any) => state.config);
    return (
        <div className={c(styles.wrapper, { [styles.barkly_container]: showBarkly })}>
            <span className={c(styles.top_title, customClass)}>{formatMts(titleMts || {}) || title}</span>
        </div>
    );
};

export default NormalTitle;
