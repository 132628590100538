/**
 * 计算日期天数差
 * @param startTime 开始时间
 * @param endTime 结束时间
 * @returns {number} 天数差
 */
export const calcDay = (startTime: any, endTime: any) => {
    let date1 = new Date(startTime);
    let date2 = new Date(endTime);
    let calcd = date2.getTime() - date1.getTime();
    if (calcd <= 0) {
        return 0;
    }
    const days = Math.ceil(calcd / (1000 * 3600 * 24));
    return days;
}
