import request from '@/utils/axios'

/**
 * 获取反馈列表
 * @param params 
 * @returns 
 */
export const getFeedbackList = (params: {
    page?: number
    pageSize?: number
}, extra?: any) => {
    return request.get('/japi/feedback/list', params, extra)
};

/**
 * 获取反馈列表未读数目
 * @returns 
 */
export const getFeedbackUnread = () => {
    return request.get('/japi/feedback/unread/num', {})
};
