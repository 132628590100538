import React, { useEffect, useRef, useState } from 'react';
import { Drawer } from 'antd';
import c from 'classnames';
import { useRouter } from 'next/router';

import { langItems } from '@/constants/mts';

import styles from './index.module.less';
import type { MtsLangProps } from '../types';
import { NextImage } from '@/components';
import intl from 'react-intl-universal';

const MtsLang: React.FC<MtsLangProps> = (props) => {
    const { locale } = props;
    const router = useRouter();
    const langContent = useRef<any>(null);
    // 选择项
    const [selectedObjs, setSelectedObjs] = useState<Record<string, string>>({
        key: locale || 'zh-CN',
        label: langItems.find((item: Record<string, string>) => item.key === (locale || 'zh-CN'))?.label || '简体中文',
    });

    const [open, setOpen] = useState<boolean>(false);
    // 是否登录
    const [isLogin, setIsLogin] = useState<boolean>(true);

    useEffect(() => {
        setIsLogin(!!localStorage?.getItem('Authorization'));
    }, []);

    // pc 语言
    const handleButtonClick = (item: any) => {
        router.replace(router.asPath, router.asPath, { locale: item.key });
        setSelectedObjs({ ...item });
        setOpen(false);
    };

    const toLogin = () => {
        (window as any)?.onLogin?.();
    };

    return (
        <>
            <div onClick={() => setOpen(true)} className={c({ [styles.content]: true })} ref={langContent}>
                <div className={styles.prefix}>
                    <NextImage src="/icon/header/lang-icon-m.webp" needPrefix layout="fill" alt="lang-icon" />
                </div>
            </div>
            <Drawer
                // className={styles.drawer}
                className={styles.drawer}
                closable={false}
                title={false}
                onClose={() => setOpen(false)}
                open={open}
                width="69%"
            >
                <div className={styles.header}>
                    <span onClick={() => setOpen(false)} className={c('iconfont', 'icon-guanbi')}></span>
                </div>
                {!isLogin && (
                    <div className={styles.go_login} onClick={toLogin}>
                        <span>{intl.get('Header.LogToSeeAll').d('现在登录，畅想全部内容')}</span>
                        <span className={c('iconfont', 'icon-jiantou', styles.icon)} />
                    </div>
                )}
                <div className={styles.list}>
                    {langItems.map((item) => (
                        <div
                            key={item.key}
                            className={c(styles.list__item, item?.key === selectedObjs.key && styles.active)}
                            onClick={() => handleButtonClick(item)}
                        >
                            {item.label}
                        </div>
                    ))}
                </div>
            </Drawer>
        </>
    );
};

export default MtsLang;
