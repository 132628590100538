import React, { useMemo } from 'react';
import styles from './index.module.less';
import LoadMore from '@/components/public/LoadMore';
import { currentLocaleUrl } from '@/utils';

interface Props {
    list: any[];
}
const RenderList: React.FC<Props> = ({ list }) => {
    const newList = useMemo(() => {
        return list.map((item, index) => ({ ...item, key: index }));
    }, [list]);
    return (
        <React.Fragment>
            {newList.length > 0 ? (
                newList.map(({ cover_url = '', name = '', flag, key }) => (
                    <a href={currentLocaleUrl(`/article/${flag}`)} target="_blank" key={key} title={name}>
                        <div className={styles.information__list__item}>
                            {!!cover_url && (
                                <em
                                    className={styles.information__img}
                                    style={{ backgroundImage: `url('${cover_url}')` }}
                                />
                            )}
                            <div className={styles.information__content}>
                                <p>{name}</p>
                            </div>
                        </div>
                    </a>
                ))
            ) : (
                <LoadMore empty={true} />
            )}
        </React.Fragment>
    );
};

export default RenderList;
