import React from 'react'
import styles from './index.module.less'

interface UnderLineTitleProp {
  title: React.ReactNode,
}

const UnderLineTitle: React.FC<UnderLineTitleProp> = (props) => {

  const { title } = props

  return (
    <div className={styles.title}>
      <div>{title}</div>
    </div>
  )
}

export default UnderLineTitle
