import React, { useRef } from 'react';
import RenderItem from './renderItem';
import styles from './index.module.less';
import type { ArticlesListPros } from '../type';
import NoContent from '@/components/public/NoContent';
import { NoMore } from '@/reconstruction/components/public';

const PCArticlesList: React.FC<ArticlesListPros> = (props) => {
    const { options = [] } = props;

    const domRef = useRef<any>(null);
    return (
        <div className={styles.wrapper} ref={domRef}>
            {options.length > 0 && (
                <div className={styles.wrapper__list}>
                    {options.map((item: any, i) => {
                        return <RenderItem item={item} key={`${item.code || item.union_id || item._id}_${i}`} />;
                    })}
                </div>
            )}
            {options.length === 0 && <NoContent />}
            {options.length !== 0 && options.length < 4 && <NoMore top={80} />}
        </div>
    );
};

export default PCArticlesList;
