import React, { useState, useEffect, useMemo, useCallback } from 'react';
import dayjs from 'dayjs';
import { Share } from '@/components';
import { formaTimerLetter, getHtmlPlainText, getTagsLen, filterSpace, currentLocaleUrl } from '@/utils';
// import dynamic from 'next/dynamic'
import styles from './index.module.less';
import intl from 'react-intl-universal';

interface Props {
    type?: string;
    keyword?: string;
    options: any[];
}

const SearchFlashList: React.FC<Props> = (props) => {
    const { options = [], type, keyword } = props;
    const [origin, setOrigin] = useState<string>('');

    useEffect(() => {
        setOrigin(window?.location?.origin || '');
    }, []);

    const newOptions = useMemo(() => {
        return formaTimerLetter([...options]) || {};
    }, [options]);

    // 搜索替换
    const replaceTitle = (tit: string, val: string) => {
        const regex = new RegExp(val.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'g');
        return (tit || '').replace(regex, `<span>${val}</span>`);
    };

    // 搜索时 用到
    const formatTitle = useCallback(
        (tit: string) => {
            switch (type) {
                case 'tag':
                    return tit;
                case 'title':
                    return keyword ? replaceTitle(tit, keyword) : tit;
                default:
                    return tit;
            }
        },
        [type, keyword],
    );

    return (
        <div className={styles.flashNewsContain}>
            {Object.keys(newOptions).map((date: any) => {
                const dateTimer = Number(date);
                const yearDate = dayjs(dateTimer).format('YYYY.MM.DD');

                const newlist = newOptions[date] || [];

                return (
                    <div key={date} className={styles.flashNewsList}>
                        {[...newlist].map((item: any, i: number) => {
                            const {
                                pk,
                                code,
                                seo_url,
                                title = '',
                                datetime,
                                source_url,
                                introduction = '',
                                tag_info = [],
                                thumbnail_pics = [],
                            } = item;

                            const tags = getTagsLen(tag_info, 3, keyword);
                            const href = `/article/${seo_url || code}`;
                            const timer = datetime ? dayjs(datetime).format('HH:mm') : '';
                            const cover = thumbnail_pics[0]?.url;
                            const introdu = filterSpace(getHtmlPlainText(introduction));

                            return (
                                <div key={pk}>
                                    {i === 0 ? <div className={styles.flashNewsDate}>{yearDate}</div> : null}

                                    <div key={pk} className={styles.flashNewsItem}>
                                        <div className={styles.flashNewsTimer}>{timer}</div>
                                        <div className={styles.flashNewsCon}>
                                            <a
                                                className={styles.flashNewsTitle}
                                                href={currentLocaleUrl(href)}
                                                target="_blank"
                                                dangerouslySetInnerHTML={{ __html: formatTitle(title) }}
                                            />
                                            {introdu ? (
                                                <div
                                                    className={styles.flashNewsIntrod}
                                                    dangerouslySetInnerHTML={{ __html: introdu }}
                                                />
                                            ) : null}
                                            {source_url ? (
                                                <div className={styles.sourceUrl}>
                                                    <a target="_blank" href={currentLocaleUrl(source_url)}>
                                                        {intl.get('PersonalCenter.OriginalLink').d('[原文链接]')}
                                                    </a>
                                                </div>
                                            ) : null}

                                            <div className={styles.flashNewsMsg}>
                                                <div className={styles.flashNewsTag}>
                                                    {tags.map((tag: any) => {
                                                        return (
                                                            <a
                                                                key={tag}
                                                                target="_blank"
                                                                href={currentLocaleUrl(`/keyword?tags=${tag}`)}
                                                                className={
                                                                    keyword !== '原创' &&
                                                                    keyword === tag &&
                                                                    type === 'tag'
                                                                        ? styles.searchOriginal
                                                                        : undefined
                                                                }
                                                            >
                                                                {tag}
                                                            </a>
                                                        );
                                                    })}
                                                </div>
                                                <div className={styles.flashNewsIcon}>
                                                    <Share
                                                        type="twitter"
                                                        size={20}
                                                        title={title}
                                                        url={href}
                                                        cover={cover}
                                                        style={{ marginRight: 20 }}
                                                    />
                                                    <Share
                                                        type="facebook"
                                                        size={20}
                                                        title={title}
                                                        url={href}
                                                        cover={cover}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                );
            })}
        </div>
    );
};

export default SearchFlashList;
