import React from 'react';

import TransferCom from '@/components/public/TransferCom';

import PcSearchArticleList from './pc';
import MSearchArticleList from './m';
import { IProps } from './type';

/**
 * 搜索的文章列表
 * @param rcProps
 * @returns
 */
const SearchArticleList: React.FC<IProps> = (rcProps) => {
    return <TransferCom pc={<PcSearchArticleList {...rcProps} />} m={<MSearchArticleList {...rcProps} />} />;
};

export default SearchArticleList;
