import { Cookies } from 'react-cookie';
import intl from 'react-intl-universal';

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getAppUserApi } from '@/services/center';
import { getIpPhoneCode } from '@/services/auth';
import { getPhoneCodeList } from '@/services/cms';
import { getFeedbackUnread } from '@/services/message';
import { langCityKeys } from '@/constants/mts';

export interface ConfigState {
    rootId: number;
    userInfo: any;
    searchValue: string;
    /** 头部整体高度 */
    topHeaderHeight: number;
    /** 头部header box高度 */
    topHeaderBoxHeight: number;
    /** 头部二级导航高度 */
    topChildHeight: number;
    phoneCode: string;
    phoneCodeList: any[];
    phoneCodeItem: any;
    canScroll: boolean;
    unreadNum: number;
    imgPrefix: string;
    /** 标题底部与头部的距离 */
    titleBottomOffsetHeader: number;
    /** 文章标题 */
    articleTitle: string;
    /** 分析师筛选 */
    analystKey: string;
    /** 左侧菜单展开/收起 */
    menuOpen: boolean;
    /** 当前导航Id */
    activeNavId: string;
    /** 二级导航 */
    activeNavKeys: string[];
    /** 侧边快讯高度 Height */
    flashNewsHeight: number;
    /** 侧边广告高度 Height */
    slideAdHeight: number;
    /** 侧边广告 数据 */
    slideAd: {
        showAdvertising?: boolean;
        leftAdvertisingCode?: string;
        rightAdvertisingCode?: string;
    };
    /** 暗黑模式 */
    showBarkly?: boolean;
    /** 交易商工具 */
    showBrokerTool?: boolean;
    /** 是否发布交易商荣誉 */
    showBrokerReputation?: boolean;
    /** 视频稿件需要展示暗黑模式 */
    showVideoBarkly?: boolean;
}

export const getUserInfoAsync = createAsyncThunk('config/getUserInfo', async () => {
    const res = await getAppUserApi();
    const { state, data } = res || {};
    const cookies = new Cookies();
    if (state === 1) {
        cookies?.set('AUTH_UID', res?.data?.id);
        const userInfo = JSON.parse(localStorage.getItem('userInfo') || '{}');
        const { commentToken, commentAvatar } = userInfo;
        const { nickname, email, icon } = data || {};
        const userKey = 'WALINE_USER';
        const walineInfo = JSON.parse(localStorage.getItem(userKey) || '{}');

        const storage = {
            ...walineInfo,
            display_name: nickname,
            avatar: icon || commentAvatar,
        };

        localStorage.setItem(userKey, JSON.stringify(storage));
        return {
            ...data,
            display_name: nickname,
            token: commentToken,
            avatar: icon || commentAvatar,
            email,
        };
        // 此处的返回结果会在 .fulfilled中作为payload的值
    } else {
        cookies?.remove('AUTH_UID');
        cookies?.remove('AUTH_TOKEN', { path: '/' });
        localStorage.clear();
        return {};
    }
});

// 根据Ip 获取phoneCode
export const getIpPhoneCodeAsync = createAsyncThunk('config/getIpPhoneCode', async () => {
    const res = await getIpPhoneCode();
    const { state, data } = res || {};
    if (state === 1) {
        const { phoneCode } = data || {};
        return phoneCode; // 此处的返回结果会在 .fulfilled中作为payload的值
    } else {
        return '65';
    }
});

// 根据Ip 获取phoneCode
export const getIpPhoneCodeItemAsync = createAsyncThunk('config/getIpPhoneCodeItem', async () => {
    const res = await getIpPhoneCode();
    const { state, data } = res || {};
    if (state === 1) {
        const { country, phoneCode } = data || {};
        return { country, phoneCode }; // 此处的返回结果会在 .fulfilled中作为payload的值
    } else {
        return { country: '新加坡', phoneCode: '65' };
    }
});

export const getPhoneCodeListAsync = createAsyncThunk('config/getPhoneCodeList', async () => {
    const lang = intl.getInitOptions().currentLocale || 'zh-CN';
    const langKey: string = langCityKeys[lang];

    const res = await getPhoneCodeList();
    return res.data
        .filter((item: any) => item.phone_code !== '86')
        .map((item: any) => ({
            id: item.id,
            oLabel: `+${item.phone_code}`,
            oValue: `${item.chinese_name}.${item.phone_code}`,
            label: `${item[langKey]} (+${item.phone_code})`,
            value: item.phone_code,
        }));
});

export const getFeedbackUnreadAsync = createAsyncThunk('config/getFeedbackUnread', async () => {
    const res = await getFeedbackUnread();
    const { state, data } = res || {};
    if (state === 1) {
        const { num = 0 } = data || {};
        return num; // 此处的返回结果会在 .fulfilled中作为payload的值
    } else {
        return 0;
    }
});

// Initial state
const initialState: ConfigState = {
    rootId: 0,
    userInfo: {},
    searchValue: '',
    topHeaderHeight: 60,
    topHeaderBoxHeight: 60,
    topChildHeight: 0,
    phoneCode: '65',
    phoneCodeList: [],
    phoneCodeItem: { country: '新加坡', phoneCode: '65' },
    canScroll: true,
    unreadNum: 0,
    imgPrefix: '',
    titleBottomOffsetHeader: 0,
    articleTitle: '',
    analystKey: '',
    menuOpen: false,
    activeNavId: '',
    activeNavKeys: [],
    flashNewsHeight: 620,
    slideAdHeight: 0,
    showBarkly: false,
    showBrokerTool: false,
    showBrokerReputation: false,
    showVideoBarkly: false,
    slideAd: {
        showAdvertising: false,
        leftAdvertisingCode: '',
        rightAdvertisingCode: '',
    },
};

// Actual Slice
export const config = createSlice({
    name: 'config',
    initialState,
    reducers: {
        setSearchValue(state, action) {
            state.searchValue = action.payload;
        },
        setTopHeaderHeight(state, action) {
            state.topHeaderHeight = action.payload;
        },
        setTopHeaderBoxHeight(state, action) {
            state.topHeaderBoxHeight = action.payload;
        },
        setTopChildHeight(state, action) {
            state.topChildHeight = action.payload;
        },
        resetUserInfo(state) {
            state.userInfo = {};
        },
        setRootId(state, action) {
            state.rootId = action.payload;
        },
        setCanScroll(state, action) {
            state.canScroll = action.payload;
        },
        setImgPrefix(state, action) {
            state.imgPrefix = action.payload;
        },
        setTitleBottomOffsetHeader(state, action) {
            state.titleBottomOffsetHeader = action.payload;
        },
        setArticleTitle(state, action) {
            state.articleTitle = action.payload;
        },
        setAnalystKey(state, action) {
            state.analystKey = action.payload;
        },
        setMenuOpen(state, action) {
            state.menuOpen = action.payload;
        },
        setActiveNavId(state, action) {
            state.activeNavId = action.payload;
        },
        setActiveNavKeys(state, action) {
            state.activeNavKeys = action.payload;
        },
        setFlashNewsHeight(state, action) {
            state.flashNewsHeight = action.payload;
        },
        setSlideAdHeight(state, action) {
            state.slideAdHeight = action.payload;
        },
        setSlideAd(state, action) {
            state.slideAd = action.payload;
        },
        setShowBarkly(state, action) {
            state.showBarkly = action.payload;
        },
        setShowBrokerTool(state, action) {
            state.showBrokerTool = action.payload;
        },
        setShowBrokerReputation(state, action) {
            state.showBrokerReputation = action.payload;
        },
        setShowVideoBarkly(state, action) {
            state.showVideoBarkly = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getUserInfoAsync.fulfilled, (state, action) => {
                state.userInfo = action.payload;
            })
            .addCase(getIpPhoneCodeAsync.fulfilled, (state, action) => {
                state.phoneCode = action.payload;
            })
            .addCase(getIpPhoneCodeItemAsync.fulfilled, (state, action) => {
                state.phoneCodeItem = action.payload;
            })
            .addCase(getPhoneCodeListAsync.fulfilled, (state, action) => {
                state.phoneCodeList = action.payload;
            })
            .addCase(getFeedbackUnreadAsync.fulfilled, (state, action) => {
                state.unreadNum = action.payload;
            });
    },
});

export const {
    setSearchValue,
    setTopHeaderHeight,
    setTopHeaderBoxHeight,
    setTopChildHeight,
    resetUserInfo,
    setRootId,
    setCanScroll,
    setImgPrefix,
    setTitleBottomOffsetHeader,
    setArticleTitle,
    setAnalystKey,
    setMenuOpen,
    setActiveNavId,
    setActiveNavKeys,
    setFlashNewsHeight,
    setSlideAdHeight,
    setSlideAd,
    setShowBarkly,
    setShowBrokerTool,
    setShowBrokerReputation,
    setShowVideoBarkly,
} = config.actions;

export default config.reducer;
