/**
 * 获取当前页面ID
 * @param firstId
 * @param secondId
 * @param firstNav
 * @returns
 */
export const getNavId = (firstId: string, secondId: string, firstNav: any) => {
    const { children = [] } = firstNav || {};

    // 存在二级导航的情况下，会返回二级导航的ID
    if (children.length !== 0) {
        return secondId || firstId;
    } else {
        return firstId;
    }
};

/**
 * 根据ID查找 选中导航
 * @param l
 * @param key
 * @returns
 */
export const getSelectKeyItem: any = (l: any[], key: string) => {
    let selectItem: any = {};
    l.forEach((item) => {
        const { id, inventedUrl, children = [] } = item || {};

        if (key && [inventedUrl, id].includes(key)) {
            selectItem = item;
            return;
        }

        if (children.length !== 0 && !selectItem?.id) {
            selectItem = getSelectKeyItem(children, key);
        }
    });

    return selectItem;
};

/**
 * 根据ID数组查找 导航
 * @param l
 * @param keyPath
 * @param i
 * @returns
 */
export const getSelectKeysItem: any = (l: any[], keyPath: string[], i: number = 1) => {
    let selectItem: any = {};
    l.forEach((item, j: number) => {
        if (keyPath[i] && [item.inventedUrl, item.id].includes(keyPath[i])) {
            selectItem = { ...item, isIndex: i === keyPath.length - 1 && j === 0 };
        }
    });

    const { id: selId, children = [] } = selectItem;
    if (selId && children.length === 0) {
        return selectItem;
    }

    return getSelectKeysItem(children || [], keyPath, i - 1);
};

/**
 * 根据ID查找 二级导航
 * @param l
 * @param key
 * @returns
 */
export const getChildList: any = (l: any[], key: string) => {
    const { children = [] } = l.find((item) => key && [item.inventedUrl, item.id].includes(key)) || {};
    return children || [];
};

/**
 * 根据ID查找 seourl: inventedUrl || id
 * @param l
 * @returns
 */
export const getRootItemId: any = (l: any[]) => {
    const firstItem = l[0] || {};
    const { id, inventedUrl, children = [] } = firstItem || {};
    if ((children || []).length !== 0) {
        return getRootItemId(children || []);
    }

    return inventedUrl || id;
};
