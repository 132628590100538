import React from 'react';
import TransferCom from '@/components/public/TransferCom';
import MMtsLang from './m';
import PcMtsLang from './pc';

import type { MtsLangProps } from './types';

const Header: React.FC<MtsLangProps> = (props) => {
    return <TransferCom m={<MMtsLang {...props} />} ipad={<PcMtsLang {...props} />} pc={<PcMtsLang {...props} />} />;
};

export default Header;
