import services from '@/services';
import { filters } from './util';

const { imgPrefix }: any = process.env.NEXT_PUBLIC_SETTING;

export const getUnllKey = (d: any) => {
    const { type, navPageID, blockId } = d;
    switch (type) {
        case 'page':
            return {
                navId: (navPageID || '').toString(),
            };
        case 'block':
            return {
                navId: (blockId || '').toString(),
            };
        default:
            return {};
    }
};

export const formatNavList: any = (l: any[]) => {
    return (l || []).map((e: any) => {
        const { id, url, navigationName, navigationNameMts, navigationParent, navigationIcon, children = [] } = e;

        const normal = filters({
            ...e,
            id: (id || '').toString(),
            name: navigationName,
            nameMts: navigationNameMts,
            icon: navigationIcon,
            parentId: (navigationParent || '').toString(),
            children: formatNavList(children),
            navigation_name: undefined,
            navigation_parent: undefined,
            navigation_icon: undefined,
            navigationNameMts: undefined,
        });

        try {
            const jsonData = JSON.parse(url || '{}');

            return filters({
                ...normal,
                ...getUnllKey(jsonData),
                ...(jsonData || {}),
                navPageID: undefined,
                url: undefined,
            });
        } catch {
            return { ...normal, navId: id };
        }
    });
};

// 获取全局数据
export const withGlobalData = (getPageData: (context: any, globalData: any) => any) => {
    return async (context: any) => {
        const [baseConfig = {}, menus = {}, nacos = {}] = await Promise.all([
            services.manage.getBaseConfig(),
            services.japi.getMenuApi(),
            services.base.getNacosConfig(),
        ]);

        const { image_root_host = {} } = nacos?.data || {};

        const globalData = {
            imageRootHost: {
                ...image_root_host,
                imgPrefix: `${image_root_host?.newDomain}/${imgPrefix}`,
            },
            baseConfig: baseConfig.data,
            env_tag: process.env.ENV_TAG || '',
            menus: formatNavList(menus.data),
        };

        const { props, ...resParams } = await getPageData(context, globalData);

        return {
            ...resParams,
            props: {
                ...props,
                globalData,
            },
        };
    };
};

// 获取全局图片数据
export const withImgData = (getPageData: (context: any, globalData: any) => any) => {
    return async (context: any) => {
        const [nacos = {}] = await Promise.all([services.base.getNacosConfig()]);
        const { image_root_host = {} } = nacos?.data || {};
        const globalData = {
            imageRootHost: {
                ...image_root_host,
                imgPrefix: `${image_root_host?.newDomain}/${imgPrefix}`,
            },
        };

        const { props, ...resParams } = await getPageData(context, globalData);

        return {
            ...resParams,
            props: {
                ...props,
                globalData,
            },
        };
    };
};
