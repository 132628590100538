import React from 'react';
import { NextImage } from '@/components';
import { currentLocaleUrl } from '@/utils';
import intl from 'react-intl-universal';
import type { BrokerItemProps } from '../../type';
import styles from './index.module.less';

const BrokerItem: React.FC<BrokerItemProps> = ({ size = 58, record }) => {
    const { id, logo, name_abbreviation, score, license = [], seo_url } = record;

    return (
        <a href={currentLocaleUrl(`/broker/${seo_url || id}`)} target="_blank" className={styles.li}>
            <div className={styles.item_top}>
                <div className={styles.logo} style={{ width: size, height: size }}>
                    <NextImage src={logo} alt={name_abbreviation} place="logo_a_new" width={size} height={size} />
                </div>
                <div className={styles.info} style={{ width: `calc(100% - ${size + 12}px)`, height: size }}>
                    <p className={styles.name}>{name_abbreviation}</p>
                    <div className={styles.score}>
                        <span>
                            <strong>{Number(score || 0).toFixed(1)}</strong>
                        </span>
                        <span>{intl.get('Broker.Score').d('评分')}</span>
                    </div>
                </div>
            </div>
            <div className={styles.tag_list}>{license?.join?.('  |  ') || ''}</div>
        </a>
    );
};

export default BrokerItem;
