import React from 'react';
import styles from './index.module.less';
import intl from 'react-intl-universal';
import c from 'classnames';
import type { BusinessTagProps } from '../type';

const BusinessTag: React.FC<BusinessTagProps> = ({ name = '', site = 'leftTop', className = '' }) => {
    const currentLocale = intl.getInitOptions().currentLocale;
    const num = currentLocale !== 'zh-CN' ? 8 : 4;

    return name ? (
        <div className={c(styles.wrapper, styles[site], className)} style={{}}>
            {name.substring(0, num)}
        </div>
    ) : (
        <React.Fragment />
    );
};

export default BusinessTag;
