import request from '@/utils/axios';

/** 分析  */

/**
 * 获取顶部品种列表
 * @returns
 */
export const getMenuApi = (locale?: string) => {
    return request.get('/japi/analyst/symbol/list', {}, { locale });
};

/**
 * 分析管理列表
 * @param params
 * @returns
 */
export const getAnalystList = (
    params: {
        page: number;
        page_size: number;
        page_number?: number;
        start_number?: number;
        end_number?: number;
        opinion_intraday?: string;
        opinion_mt?: string;
        opinion_st?: string;
        start_time?: string;
        end_time?: string;
        symbol_type?: string[];
        time_frame?: string;
    },
    locale?: string,
) => {
    return request.post('/japi/analyst/list', params, { locale });
};

/**
 * 分析管理详情
 * @param params
 * @returns
 */
export const getAnalystDetail = (params: { _id: string | number }, locale?: string) => {
    return request.get('/japi/analyst/details', params, { locale });
};
