import intl from 'react-intl-universal';
import { message } from 'antd';
import { cloneDeep } from 'lodash';
import { useState } from 'react';

import useCustomModal from '@/reconstruction/components/public/CustomModal';
import { focusAddPost, focusDelete } from '@/services/focus';

interface Props {
    ugcData: any;
}

function Index({ ugcData }: Props) {
    const [dataSource, setDataSource] = useState(ugcData);

    const [customModal, CustomModal] = useCustomModal();

    /** 处理关注 */
    const handleFocus = (ugc: any) => {
        const { id: ugcId, is_focus } = ugc;

        const fun = is_focus === 1 ? focusDelete : focusAddPost;

        fun(ugcId)
            .then((res: any) => {
                if (res?.state === 1) {
                    message.success(
                        is_focus === 1
                            ? intl.get('PersonalCenter.UnfollowSuc').d('取消关注成功！')
                            : intl.get('Article.FollowedSuc').d('关注成功！'),
                    );
                    const newData = cloneDeep(dataSource);
                    newData.is_focus = 1 - is_focus;
                    setDataSource(newData);
                }
            })
            .finally(() => {
                customModal.close();
            });
    };

    /**
     * 关注/取消关注
     */
    const onFocusUgc = (event: any, ugc: any) => {
        event?.preventDefault();
        const { id: ugcId, is_focus, name } = ugc;
        if (ugcId) {
            if (is_focus === 1) {
                customModal.confirm({
                    open: true,
                    title: intl
                        .get('Article.WantToUnfollow', { field: `"${name}"` })
                        .d(`确定要取消对"${name}"的关注吗？`),
                    onOk: () => {
                        handleFocus(ugc);
                    },
                });
            } else {
                handleFocus(ugc);
            }
        }
    };

    return {
        CustomModal,
        onFocusUgc,
        ugcData: dataSource,
    };
}

export default Index;
