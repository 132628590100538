import React from 'react';
import InfiniteScroll from '../../../../public/InfiniteScroll';
import FlashNewsList from './list';
import styles from './index.module.less';

import { IProps } from '../../type';

interface Props extends IProps {}

const NewsFlash: React.FC<Props> = (props) => {
    const { serviceData, navsId = [] } = props;

    return (
        <div className={styles.tabsCon}>
            <InfiniteScroll
                initialLoad={false}
                serviceData={serviceData}
                api="/japi/article/quick/list"
                extraParams={{ ids: navsId.join(',') }}
                renderList={(list) => <FlashNewsList options={list} />}
            />
        </div>
    );
};

export default NewsFlash;
