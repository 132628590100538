import React, { useMemo, useEffect } from 'react';
import dayjs from 'dayjs';
import { useAppSelector, useAppDispatch } from '@/store/hook';
import Header from '../components/Header';
import NavBar from '../components/NavBar';
import Main from '../components/Main';
import Footer from '../components/Footer';
import c from 'classnames';
import styles from './index.module.less';
import { setShowBarkly } from '@/store/config';
import { useLogin } from '@/reconstruction/components/hook';

const Layout: React.FC<any> = (props: any) => {
    const {
        globalData = {},
        showAdvertising = false,
        showTopTW = false,
        leftAdvertisingCode,
        rightAdvertisingCode,
        topTWCode,
        pageData,
        showBarkly: showBarklyInit,
    } = props?.children?.props || {};
    const dispatch = useAppDispatch();
    const { baseConfig = {}, menus = [] } = globalData;
    const { topHeaderHeight: topHeight, showVideoBarkly, showBarkly } = useAppSelector((state: any) => state.config);

    // ------------------ 登录 ------------------
    const { onLogin, LoginModal } = useLogin();

    useEffect(() => {
        if (!window) {
            return;
        }
        (window as any).onLogin = onLogin;
    }, []);
    // ------------------ 登录 ------------------

    const topBannerData = useMemo(() => {
        const { starttime, endtime, top_pc_banner } = baseConfig;
        const currentDay = dayjs();
        return {
            visible: currentDay.isBefore(dayjs(endtime)) && currentDay.isAfter(dayjs(starttime)),
            url: top_pc_banner,
        };
    }, [baseConfig]);
    useEffect(() => {
        // 设置暗黑模式
        dispatch(setShowBarkly(showBarklyInit || showVideoBarkly));
    }, [dispatch, showBarklyInit, showVideoBarkly]);

    return (
        <div
            className={c({ [styles.wrapper]: true, [styles.barkly_container]: showBarkly })}
            style={{ paddingTop: topHeight }}
        >
            <Header
                menus={menus}
                logo={baseConfig.logo}
                download={baseConfig.download}
                topBanner={topBannerData}
                showTopTW={showTopTW}
                topTWCode={topTWCode}
                showBarkly={showBarkly}
            />

            <Main
                child={props?.children}
                showAdvertising={showAdvertising}
                leftAdvertisingCode={leftAdvertisingCode}
                rightAdvertisingCode={rightAdvertisingCode}
                pageData={pageData}
            />

            <NavBar menus={menus} />

            <Footer config={baseConfig} menus={menus} />
            {LoginModal}
        </div>
    );
};

export default Layout;
