import React from 'react';
import c from 'classnames';

import { useAppSelector } from '@/store/hook';

import styles from './index.module.less';
import { RollArticleTitleProps } from './type';
import { useGetShowTitle } from './hook';

const RollArticleTitle: React.FC<RollArticleTitleProps> = (props) => {
    const { defaultShow } = props;
    const { articleTitle, titleBottomOffsetHeader, showBarkly } = useAppSelector((state: any) => state.config);

    /** 展示标题 */
    const showTitle = useGetShowTitle({ rollDistance: titleBottomOffsetHeader });

    return (
        <div
            className={c(styles.wrapper__container, {
                [styles.roll]: showTitle,
                [styles.barkly_container]: showBarkly,
            })}
        >
            <div className={styles.wrapper__default}>{defaultShow}</div>
            {articleTitle && <div className={styles.wrapper__title}>{articleTitle}</div>}
        </div>
    );
};

RollArticleTitle.defaultProps = {
    defaultShow: null,
};

export default RollArticleTitle;
