import React from 'react';

import c from 'classnames';

import styles from './index.module.less';
import { NextImage } from '@/components';

interface Props {
    /** 文案，根据顺序展示 */
    text: [string, string];
    /** 类名 */
    classsName?: string;
    /** icon类型 hot-热门 ugc-创作者 quick-快讯 */
    iconType: 'hot' | 'ugc' | 'quick';
}

const TagTitle = (props: Props) => {
    const { iconType, text = [], classsName } = props;

    return (
        <div className={c(styles.tagTitle, classsName)}>
            <span className={styles.text}>{text.join('')}</span>
            <NextImage
                src={`/icon/home/right-${iconType}.webp`}
                needPrefix
                width={22}
                height={22}
                objectFit="contain"
            />
        </div>
    );
};

export default TagTitle;
