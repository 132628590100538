const theme = {
    token: {
        colorPrimary: '#2c36de',
        colorBorder: '#e5e5e5',
        controlHeightLG: 48,
        colorText: '#262f2d',
        colorTextPlaceholder: '#666',
        borderRadius: 4,
        fontFamily:
            "PingFangSC,-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol','Noto Color Emoji'",
    },
    components: {
        Select: {
            colorTextPlaceholder: '#666',
        },
        DatePicker: {
            colorTextPlaceholder: '#666',
        },
    },
};

export default theme;
