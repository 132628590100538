export const tawkInfoObj: Record<string, string[]> = {
    // 测试用数据
    // en: ['666a5c9a9a809f19fb3d130c', '1i1ro79he'],
    // zh: ['666a5c9a9a809f19fb3d130c', '1i07njfe5'],
    // ja: ['666a5c9a9a809f19fb3d130c', '1i2vvrmg1'],
    // 线上账号id
    en: ['6684f44d9d7f358570d66c4c', '1i1rlvalr'],
    zh: ['6684f44d9d7f358570d66c4c', '1i1rlkkfh'],
    ja: ['6684f44d9d7f358570d66c4c', '1i302qg0g'],
};
