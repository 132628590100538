import React from 'react';
import intl from 'react-intl-universal';
import c from 'classnames';

import styles from './index.module.less';

interface NoMoreProps {
    top?: number | string;
    className?: string;
    text?: string;
}

const NoMore: React.FC<NoMoreProps> = ({ top, text, className }) => {
    return (
        <div className={c(styles.no_more, className || '')} style={{ marginTop: top || 42 }}>
            <span />
            <span>{text || intl.get('Common.MoreLostContent').d('没有更多内容')}</span>
            <span />
        </div>
    );
};

export default NoMore;
