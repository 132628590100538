import React from 'react';

import TransferCom from '@/components/public/TransferCom';

import PCFirstAdvertise from './pc';
import MFirstAdvertise from './m';
import SideFirstAdvertise from './side';
import { AdvertiseProps } from './type';

const FirstAdvertise: React.FC<AdvertiseProps> = (props) => {
    const { serviceData } = props;
    const { isRight } = serviceData || {};
    const pcDom = isRight ? (
        <SideFirstAdvertise {...props} moreStyle={{ width: 332, minHeight: 298 }} />
    ) : (
        <PCFirstAdvertise {...props} />
    );

    return <TransferCom pc={pcDom} ipad={pcDom} m={<MFirstAdvertise {...props} />} />;
};

export default FirstAdvertise;
