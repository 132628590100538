import React from 'react';

import BlockNewsFlash from './BlockNewsFlash';
import RcRightNewsFlash from './RightNewsFlash';

import { IProps } from '../type';
interface Props extends IProps {}

const NewsFlash: React.FC<Props> = (props) => {
    const { isRightNewsFlash } = props;
    return (
        <div style={{ marginTop: 12, marginBottom: 12 }}>
            {isRightNewsFlash ? <RcRightNewsFlash {...props} /> : <BlockNewsFlash {...props} />}
        </div>
    );
};

export default NewsFlash;
