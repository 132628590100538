import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { setRootId, setActiveNavId, setActiveNavKeys, setMenuOpen } from '@/store/config';
import { useAppDispatch, useAppSelector } from '@/store/hook';
import {
    // addHrefEventListener,
    currentLocaleUrl,
    formatMts,
} from '@/utils';
import { getRootItemId, getSelectKeysItem } from '@/utils/home';
import { Scrollbar } from '@/components';
import c from 'classnames';
import { useRouter } from 'next/router';
import type { NavBarProps } from '../types';
import intl from 'react-intl-universal';
import { Menu } from 'antd';
import { cloneDeep } from 'lodash';
import type { MenuProps } from 'antd';
import { menuExtraList } from '../config';
import styles from './index.module.less';

const NavBar: React.FC<NavBarProps> = (props) => {
    const { menus = [] } = props;
    const router = useRouter();
    const dispatch = useAppDispatch();
    const currentLocale = intl.getInitOptions().currentLocale;

    const {
        topHeaderHeight: topHeight,
        menuOpen,
        activeNavId,
        activeNavKeys,
    } = useAppSelector((state: any) => state.config);

    // 默认展开菜单
    const [openKeys, setOpenKeys] = useState<string[]>(activeNavKeys);

    // 是否登录
    const [isLogin, setIsLogin] = useState<boolean>(true);

    useEffect(() => {
        setIsLogin(!!localStorage?.getItem('Authorization'));
    }, []);

    // 除了菜单页 首页默认取菜单的第一个id 其余页面不设置id
    useEffect(() => {
        dispatch(setRootId(getRootItemId(menus)));
    }, [dispatch, menus]);

    useEffect(() => {
        setOpenKeys(activeNavKeys);
    }, [activeNavKeys]);

    const toLogin = () => {
        (window as any)?.onLogin?.();
    };

    /**
     * 渲染一级菜单每项
     */
    const toKeyPath = useCallback((item: any, isIndex?: boolean) => {
        const { id, parentId, web, type, inventedUrl } = item;
        const index = (menus || []).findIndex((m: any) => [m?.inventedUrl || m.id, m.id].includes(parentId));

        switch (type) {
            case 'page':
                dispatch(setActiveNavId(inventedUrl || id));
                const parentItem = menus.find((pItem: any) => pItem.id === parentId) || {};
                const pathKeys = [inventedUrl || id];
                if (parentItem?.id) {
                    pathKeys.unshift(parentItem?.inventedUrl || parentItem?.id);
                }
                dispatch(setActiveNavKeys(pathKeys));
                router.push(currentLocaleUrl(isIndex && index === 0 ? '/' : `/${pathKeys.join('/')}`));
                dispatch(setMenuOpen(false));
                break;
            case 'web':
                const { url } = web;
                window.open(currentLocaleUrl(url));
                break;
            default:
                break;
        }
    }, []);

    // 菜单点击
    const onMenuItemClick: MenuProps['onClick'] = (menuProps) => {
        const { keyPath = [] } = menuProps;
        const selectItem = getSelectKeysItem(menus, keyPath, keyPath.length - 1);

        if (selectItem?.type !== 'web') {
            const pathKeys = (keyPath || []).reverse();
            dispatch(setActiveNavKeys(pathKeys));
            dispatch(setActiveNavId(selectItem?.id));
        }

        toKeyPath(selectItem, selectItem?.isIndex);
    };

    // sub点击
    const onTitleClick: MenuProps['onClick'] = useCallback(
        (menuProps: { key: any }) => {
            const { key } = menuProps;
            const cloneList = cloneDeep(openKeys);
            if (cloneList.includes(key)) {
                setOpenKeys([]);
            } else {
                setOpenKeys([key]);
            }
        },
        [openKeys],
    );

    const formatMenus: any = useCallback(
        (l: any[]) => {
            if (l.length !== 0) {
                return l.map((item: any) => {
                    const { id, inventedUrl, name, nameMts, children = [] } = item || {};
                    const navMtsName = formatMts(nameMts) || name;

                    return {
                        key: inventedUrl || id,
                        label: navMtsName,
                        children: children.length !== 0 ? formatMenus(children || []) : undefined,
                        // 有二级导航，暂时不能点击前往一级导航
                        // onTitleClick: (menuProps: any) => {
                        //     const { key } = menuProps;
                        //     onMenuItemClick({ ...menuProps, keyPath: [key] });
                        // },

                        onTitleClick,
                    } as any;
                });
            } else {
                return [];
            }
        },
        [onTitleClick, openKeys],
    );

    // 自定义menus
    const newMenus: MenuProps['items'] = useMemo(() => {
        return formatMenus(menus, true);
    }, [currentLocale, menus, openKeys]);

    // 根据二级导航重新计算topheight
    const newTopHeight = useMemo(() => {
        return activeNavKeys.length === 2 && topHeight > 60 ? topHeight - 60 : topHeight;
    }, [activeNavKeys, topHeight]);

    useEffect(() => {
        document.addEventListener(
            'click',
            () => {
                dispatch(setMenuOpen(false));
            },
            false,
        );
    }, []);

    return (
        <aside
            onClick={(e: any) => {
                e.stopPropagation();
            }}
            className={c({ [styles.menu_box]: true, [styles.menu_open]: menuOpen, [styles.menu_login]: isLogin })}
            style={{ top: newTopHeight, height: `calc(100vh - ${newTopHeight}px)` }}
        >
            <Scrollbar noScrollX trackYClassName={styles.recScrollTrackY} thumbYClassName={styles.recScrollThumbY}>
                {!isLogin && (
                    <div className={styles.item_login} key="login">
                        <div className={styles.go_login} onClick={toLogin}>
                            <span>{intl.get('Header.LogToSeeAll').d('现在登录，畅想全部内容')}</span>
                            <span className={c('iconfont', 'icon-jiantou', styles.icon)} />
                        </div>
                    </div>
                )}

                <menu className={c({ [styles.menu]: true })}>
                    <Menu
                        onSelect={onMenuItemClick}
                        mode="inline"
                        items={newMenus}
                        className={styles.menus}
                        selectedKeys={[activeNavId]}
                        openKeys={openKeys}
                        expandIcon={(props) => {
                            const { isOpen } = props;
                            return (
                                <span
                                    className={c('iconfont', 'icon-jiantou', styles.icon, {
                                        [styles.open]: isOpen,
                                    })}
                                />
                            );
                        }}
                    />
                </menu>

                <div className={styles.list_box}>
                    <ul className={styles.list}>
                        {menuExtraList.map((item) => {
                            return (
                                <li className={styles.item} key={item.link}>
                                    <a href={currentLocaleUrl(item.link)} target="_blank">
                                        <span>{intl.get(item.name).d(item.alias)}</span>
                                    </a>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </Scrollbar>
        </aside>
    );
};

export default NavBar;
