import TransferCom from '@/components/public/TransferCom';

import MSwitchBtn from './m';
import PCSwitchBtn from './pc';
import type { SwitchBtnProps } from './types';

const SwitchBtn: React.FC<SwitchBtnProps> = (props) => {
    return (
        <TransferCom m={<MSwitchBtn {...props} />} ipad={<PCSwitchBtn {...props} />} pc={<PCSwitchBtn {...props} />} />
    );
};

export default SwitchBtn;
