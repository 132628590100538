import React from 'react';
import { formatMts } from '@/utils/mts';
import type { FooterProps } from '../types';
import styles from './index.module.less';

const Footer: React.FC<FooterProps> = ({ config }) => {
    const { footer, footer_mts } = config || {};
    return (
        <footer className={styles.footer}>
            <div
                className={styles.info}
                id="copyright"
                dangerouslySetInnerHTML={{ __html: formatMts(footer_mts) || footer }}
            />
        </footer>
    );
};

export default Footer;
