import React, { useCallback } from 'react';
import c from 'classnames';
import { NextImage } from '@/components';
import { getArticleViewNum, formatTimer, getTagsLen, getHtmlPlainText, filterSpace, currentLocaleUrl } from '@/utils';
import styles from './index.module.less';
import intl from 'react-intl-universal';

interface Props {
    type?: string;
    options: any[];
    keyword?: string;
    /**
     * 是否展示创作者名，默认展示
     */
    isShowUgc?: boolean;
    /** @name - 标题是否展示原创标签，默认展示 */
    isShowOrigin?: boolean;
}
const SearchArticleList: React.FC<Props> = (props) => {
    const { type, options = [], keyword, isShowUgc = true, isShowOrigin = true } = props;

    // 搜索替换
    const replaceTitle = (tit: string, val: string) => {
        const regex = new RegExp(val.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'g');
        return (tit || '').replace(regex, `<span>${val}</span>`);
    };

    const formatTitle = useCallback(
        (tit: string) => {
            switch (type) {
                case 'tag':
                    return tit;
                case 'title':
                    return keyword ? replaceTitle(tit, keyword) : tit;
                default:
                    return tit;
            }
        },
        [type, keyword],
    );

    return (
        <>
            {[...options].map((item: any) => {
                const {
                    title,
                    code,
                    seo_url,
                    is_original,
                    category,
                    introduction,
                    rule_name,
                    ugc,
                    tag_info,
                    datetime,
                    thumbnail_pics = [],
                } = item;
                const tags = getTagsLen(tag_info, 3, keyword);
                const introdu = filterSpace(getHtmlPlainText(introduction || ''));
                const newTags = tags.map((tag, index) => ({ tag, key: index }));

                return (
                    <div className={styles.searchWrapper} key={code}>
                        {thumbnail_pics[0]?.url && (
                            <a target="_blank" href={currentLocaleUrl(`/article/${seo_url || code}`)}>
                                <div className={c(styles.searchImage, category == 'video' && styles.searchVideoImg)}>
                                    <NextImage src={thumbnail_pics[0]?.url} layout="fill" alt={title} />
                                    {category === 'video' && (
                                        <span className={c(styles.videoPlay, 'iconfont', 'icon-shipin')} />
                                    )}
                                </div>
                            </a>
                        )}
                        <div className={styles.searchMain}>
                            <a
                                target="_blank"
                                href={currentLocaleUrl(`/article/${seo_url || code}`)}
                                className={styles.linkBox}
                            >
                                <div className={styles.titleWrapper}>
                                    {isShowOrigin && is_original === 1 ? (
                                        <span
                                            className={c(
                                                styles.origin,
                                                intl.getInitOptions().currentLocale === 'en' ? styles['en_origin'] : '',
                                            )}
                                        >
                                            {intl.get('Common.Original').d('原创')}
                                        </span>
                                    ) : null}
                                    <h2
                                        className={styles.searchTitle}
                                        dangerouslySetInnerHTML={{ __html: formatTitle(title || '') }}
                                    />
                                </div>
                                {(introdu || thumbnail_pics[0]?.url) && (
                                    <div className={styles.searchContent}>{introdu || ''}</div>
                                )}
                            </a>
                            <div className={styles.searchBottom}>
                                <div className={styles.searchBottomLeft}>
                                    {isShowUgc && (
                                        <span className={styles.searchRuleName}>{ugc?.name || rule_name || ''}</span>
                                    )}
                                    <span className={styles.searchTime}>{formatTimer(datetime)}</span>
                                    <span className={styles.searchViewNum}>
                                        <i
                                            className="iconfont icon-liulanliang"
                                            style={{ fontSize: '14px', marginRight: 3 }}
                                        />
                                        {getArticleViewNum(item.view_num)}
                                    </span>
                                </div>
                                <div className={styles.searchBottomRight}>
                                    {newTags.map((tagItem) => (
                                        /** 原创显示所有原创稿件 并非关键词=原创 */
                                        <a
                                            key={tagItem.key}
                                            href={currentLocaleUrl(`/keyword?tags=${tagItem.tag}`)}
                                            target="_blank"
                                            className={
                                                keyword !== '原创' && keyword === tagItem.tag && type === 'tag'
                                                    ? styles.searchOriginal
                                                    : ''
                                            }
                                        >
                                            {tagItem.tag}
                                        </a>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                );
            })}
        </>
    );
};

export default SearchArticleList;
