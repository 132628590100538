import React from 'react';
import { NextImage } from '@/components';
import SwiperCore, { Pagination, Autoplay, Controller, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { formatMts } from '@/utils/mts';
import 'swiper/swiper-bundle.css';
import c from 'classnames';
import styles from './index.module.less';
import { currentLocaleUrl } from '@/utils';

interface SwiperProps {
    elementId: string | number;
    isSwitch: boolean;
    interval?: number;
    customStyle: any;
    imgWidth: number;
    swiperList: any[];
}

SwiperCore.use([Pagination, Autoplay, Controller, Navigation]);

const FirstSwiper: React.FC<SwiperProps> = ({ elementId, customStyle, interval = 3, swiperList = [] }) => {
    const { width, widthUnit, height, heightUnit, borderRadius, borderRadiusUnit } = customStyle || {};

    return (
        <div
            className={styles.swiperContain}
            style={{
                width: `${width}${widthUnit}`,
                height: `${height}${heightUnit}`,
                borderRadius: borderRadius ? `${borderRadius}${borderRadiusUnit}` : 0,
            }}
        >
            <Swiper
                loop={swiperList.length > 1}
                observer
                resizeObserver
                // autoplay={{
                //     delay: (interval || 3) * 1000,
                //     disableOnInteraction: false,
                // }}
                grabCursor
                className={styles.swiperCon}
                pagination={
                    swiperList.length < 2
                        ? false
                        : {
                              clickable: true,
                              bulletClass: styles.swiperBullet,
                              bulletActiveClass: styles.swiperBulletActive,
                          }
                }
                navigation={{
                    nextEl: `.swiper-${elementId} .swiper-button-next`,
                    prevEl: `.swiper-${elementId} .swiper-button-prev`,
                }}
            >
                {swiperList.map((item: any, i: number) => {
                    const swiperItem = (
                        <div className={styles.swiperSlideBox} title={item?.title || ''}>
                            <NextImage src={item?.url} layout="fill" alt={item?.title} priority={i === 0} />
                            {item.title ? (
                                <div className={styles.swiperSlideTitle}>
                                    <div
                                        className={c(
                                            styles.swiperSlideTitleText,
                                            swiperList.length < 2 ? styles.swiperSlideTitleOne : null,
                                        )}
                                    >
                                        {formatMts(item.titleMts) || item.title}
                                    </div>
                                </div>
                            ) : null}
                        </div>
                    );
                    return (
                        <SwiperSlide className={c(styles.swiperSlide, styles.typeOne)} key={item.key}>
                            {item.openUrl ? (
                                <a href={currentLocaleUrl(item.openUrl)} target="_blank">
                                    {swiperItem}
                                </a>
                            ) : (
                                swiperItem
                            )}
                        </SwiperSlide>
                    );
                })}

                <div style={{ opacity: swiperList.length > 1 ? 1 : 0 }} className={`swiper-${elementId}`}>
                    <div className={c(styles.swiperBtnNext, 'swiper-button-next')} />
                    <div className={c(styles.swiperBtnPrev, 'swiper-button-prev')} />
                </div>
            </Swiper>
        </div>
    );
};

export default FirstSwiper;
