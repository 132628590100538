import React, { useState, useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';
import { setRootId, setActiveNavId, setActiveNavKeys } from '@/store/config';
import { useAppDispatch, useAppSelector } from '@/store/hook';
import { currentLocaleUrl, formatMts } from '@/utils';
import { getRootItemId, getSelectKeyItem, getNavId } from '@/utils/home';
import SwiperCore, { Mousewheel } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import MenuHover from '../../MenuHover';
import c from 'classnames';
import styles from './index.module.less';
import type { HeaderNavProps } from '../types';

SwiperCore.use([Mousewheel]);
const HeaderNav: React.FC<HeaderNavProps> = ({ menus = [], hideNav }) => {
    const router = useRouter();
    const dispatch = useAppDispatch();
    const { activeNavKeys, showBarkly } = useAppSelector((state: any) => state.config);

    // ------------- childList
    const [isIndex, setIsIndex] = useState<boolean>(false);
    const [parentItem, setParentItem] = useState<any>({});
    const [childList, setChildList] = useState<any>([]);

    // const [parentItem, setParentItem] = useState<any>(menus[0]);
    // const [childList, setChildList] = useState<any>(menus[0].children);

    useEffect(() => {
        dispatch(setRootId(getRootItemId(menus)));
    }, [dispatch, menus]);

    // 格式navId
    const formatNavKeys = (key: string, children: any[], parentId?: string) => {
        if (children.length !== 0) {
            dispatch(setActiveNavId(children[0]?.inventedUrl || children[0]?.id || ''));
        } else {
            dispatch(setActiveNavId(key || ''));
        }

        const pathKeys = [key];

        if (parentId) {
            const parentItem = menus.find((pItem: any) => pItem.id === parentId) || {};

            if (parentItem?.id) {
                pathKeys.unshift(parentItem?.inventedUrl || parentItem?.id);
            }
        }

        if (children.length !== 0) {
            pathKeys.push(children[0]?.inventedUrl || children[0]?.id || '');
        }

        dispatch(setActiveNavKeys(pathKeys));
    };

    useEffect(() => {
        // 除了菜单页 首页默认取菜单的第一个id 其余页面不设置id
        const { firstId, secondId }: { firstId?: string; secondId?: string } = router.query || {};
        const firstNav = menus.find((item: any) => [item.inventedUrl, item.id].includes(firstId));
        const id = getNavId(firstId || '', secondId || '', firstNav);

        if (id) {
            const { parentId, children = [] } = getSelectKeyItem(menus, id);
            formatNavKeys(id as string, children, parentId);
            return;
        }

        if (router.route === '/') {
            const { id: firstId, inventedUrl, children = [] } = menus[0];
            formatNavKeys(inventedUrl || firstId, children);
            return;
        }

        dispatch(setActiveNavId(''));
        dispatch(setActiveNavKeys([]));
    }, [router.query, menus]);

    // 跳转
    const toKeyPath = useCallback((item: any, isIndex: boolean) => {
        const { id, parentId, web, type, inventedUrl } = item;

        switch (type) {
            case 'page':
                dispatch(setActiveNavId(inventedUrl || id));
                const parentItem = menus.find((pItem: any) => pItem.id === parentId) || {};
                const pathKeys = [inventedUrl || id];
                if (parentItem?.id) {
                    pathKeys.unshift(parentItem?.inventedUrl || parentItem?.id);
                }
                dispatch(setActiveNavKeys(pathKeys));
                router.push(currentLocaleUrl(isIndex ? '/' : `/${pathKeys.join('/')}`));
                onMouseLeave();
                break;
            case 'web':
                const { url } = web;
                window.open(currentLocaleUrl(url));
                break;
            default:
                break;
        }
    }, []);

    /**
     * 渲染nav每一项
     */
    const navItemRender = useCallback(
        (item: any, isIndex: boolean) => {
            const { name, nameMts, children = [] } = item;
            const navMtsName = formatMts(nameMts) || name;

            return (
                <div
                    className={styles.aHref}
                    onClick={() => {
                        let activeItem = item;

                        if ((children || []).length !== 0) {
                            activeItem = (children || [])[0];
                        }
                        toKeyPath(activeItem, isIndex);
                    }}
                >
                    <span>{navMtsName}</span>
                </div>
            );
        },
        [toKeyPath],
    );

    // 移入
    const onMouseEnter = (item: any, isIndex: boolean) => {
        setIsIndex(isIndex);
        setParentItem?.(item);
        setChildList?.(item?.children || []);
    };

    // 移出
    const onMouseLeave = () => {
        setParentItem?.({});
        setChildList?.([]);
    };

    return (
        <nav
            className={c({ [styles.nav]: true, [styles.nav_hide]: hideNav, [styles.barkly_container]: showBarkly })}
            onMouseLeave={onMouseLeave}
        >
            <Swiper
                observer
                observeParents
                mousewheel
                resizeObserver
                grabCursor
                slidesPerView="auto"
                className={c(styles.list)}
            >
                {menus.map((item: any, i: number) => {
                    const { id, inventedUrl, children = [] } = item;

                    return (
                        <SwiperSlide
                            className={c(styles.list_item, {
                                [styles.active]:
                                    activeNavKeys[0] &&
                                    [inventedUrl, id].includes(activeNavKeys[0]) &&
                                    children.length === 0,
                                [styles.active_bg]:
                                    activeNavKeys[0] &&
                                    [inventedUrl, id].includes(activeNavKeys[0]) &&
                                    children.length !== 0,
                            })}
                            key={`${id}_${inventedUrl}`}
                            onMouseEnter={() => onMouseEnter(item, i === 0)}
                        >
                            <>
                                {navItemRender(item, i === 0)}
                                <span className={c(styles.j, styles.j_l)} />
                                <span className={c(styles.j, styles.j_r)} />
                            </>
                        </SwiperSlide>
                    );
                })}
            </Swiper>

            <MenuHover
                isIndex={isIndex}
                menus={menus}
                parentItem={parentItem}
                childList={childList}
                onClickItem={onMouseLeave}
            />
        </nav>
    );
};

export default HeaderNav;
