import React, { useMemo } from 'react';
import { Popover } from 'antd';
import c from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import intl from 'react-intl-universal';
import { NextImage } from '@/components';
import { Cookies } from 'react-cookie';
import { useAppDispatch, useAppSelector } from '@/store/hook';
import { resetUserInfo } from '@/store/config';
import styles from './index.module.less';

interface LoginBtnProps {
    locale?: string;
}

const LoginBtn: React.FC<LoginBtnProps> = ({ locale }) => {
    const router = useRouter();
    const dispatch = useAppDispatch();

    const { userInfo, showBarkly } = useAppSelector((state: any) => state.config);

    const { icon, nickname } = userInfo || {};

    const extendsPlans = useMemo(() => {
        return [
            {
                name: intl.get('Setting.AccountSettings').d('账号设置'),
                href: '/setting',
            },
            {
                name: intl.get('Message.MessageCenter').d('消息中心'),
                href: '/message',
            },
            {
                name: intl.get('PersonalCenter.Tab10').d('浏览历史'),
                href: '/center?type=history',
            },
            {
                name: intl.get('PersonalCenter.Tab2').d('我的关注'),
                href: '/center?type=focus',
            },
            {
                name: intl.get('PersonalCenter.Tab3').d('我的收藏'),
                href: '/center?type=collect',
            },
        ];
    }, [locale]);

    /**
     * 判断字节长度
     * @param str
     * @param num
     * @returns
     */
    const formatNickName = (str: string, num: number = 6) => {
        // 获取字符串的字节数
        let count = 0; // 初始化字节数递加变量并获取字符串参数的字符个数
        let temp = false;
        const nameList: any = [];

        if (str) {
            // 如果存在字符串，则执行
            const strList = str.split('');
            const newList = strList.map((value: any) => {
                return { value, len: value.charCodeAt(0) > 255 ? 2 : 1 };
            });
            newList.forEach((item: any) => {
                count += item.len;
                if (count <= num) {
                    nameList.push(item.value);
                } else {
                    if (!temp) {
                        temp = true;
                        nameList.push('...');
                    }
                }
            });
            return nameList.join(''); // 返回字节数
        } else {
            return ''; // 如果参数为空，则返回0个
        }
    };

    /**
     * 登录
     */
    const loginIn = () => {
        (window as any)?.onLogin?.();
    };

    /**
     * 退出登录
     */
    const logUp = () => {
        router.reload();
        const cookies = new Cookies();
        cookies?.remove('AUTH_UID');
        cookies?.remove('AUTH_TOKEN', { path: '/' });
        localStorage.clear();
        dispatch(resetUserInfo());
    };

    return (
        <>
            {nickname ? (
                <Popover
                    arrow={{
                        pointAtCenter: true,
                    }}
                    overlayClassName={styles.ant_popover}
                    placement="bottom"
                    content={
                        <div className={styles.extends_plan}>
                            <Link href="/center/modify">
                                <div className={c(styles.avatar, styles.avatar_s)}>
                                    <div className={styles.icon}>
                                        <NextImage src={icon} width={22} height={22} alt="user" place="user" />
                                    </div>
                                    <span className={styles.name}>{nickname}</span>
                                </div>
                            </Link>

                            <ul className={styles.extends_plan_list}>
                                {extendsPlans.map((item) => {
                                    const { name, href } = item;
                                    return (
                                        <li key={href}>
                                            <Link href={href}>
                                                <span>{name}</span>
                                            </Link>
                                        </li>
                                    );
                                })}
                            </ul>

                            <div onClick={logUp} className={styles.log_up}>
                                {intl.get('Header.SignOut').d('退出登录')}
                            </div>
                        </div>
                    }
                >
                    <div
                        className={c(styles.avatar, styles.avatar_l, {
                            [styles.avatar__barkly]: showBarkly,
                        })}
                    >
                        <div className={styles.icon}>
                            <NextImage src={icon} width={36} height={36} alt="user" place="user" />
                        </div>
                        {/* <span className={styles.name}>{formatNickName(nickname)}</span> */}
                    </div>
                </Popover>
            ) : (
                <div
                    onClick={loginIn}
                    aria-label="Login in or up"
                    className={c({
                        [styles.login_btn]: true,
                        [styles.en_reg_log_btn]: locale === 'en',
                        [styles.barkly_container]: showBarkly,
                    })}
                >
                    {intl.get('Login.LoginInOrUp').d('登录/注册')}
                </div>
            )}
        </>
    );
};

export default LoginBtn;
