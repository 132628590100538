import React, { useRef, useState, useEffect, useContext, useMemo } from 'react';
import { Button, Form, Input, App } from 'antd';
import type { FormInstance } from 'antd/es/form';
import intl from 'react-intl-universal';

import { updataPassword } from '@/services/auth';
import formUtils from '@/utils/form';

import styles from './index.module.less';
import { LoginContext } from '../../content';
import { NextImage } from '@/components';

const { pleaseEnter, pleaseAgainEnter } = formUtils;

const { Password } = Input;

const ResetPwd = () => {
    const form = useRef<FormInstance>(null);
    const { verify, setFormType } = useContext(LoginContext);

    const [loading, setLoading] = useState<boolean>(false);

    const { message } = App.useApp();

    const account = useMemo(() => {
        const { mobile, phoneCode, mail, email } = JSON.parse(localStorage.getItem('userInfo') || '{}');
        const val = mobile
            ? `${intl.get('Setting.PhoneNum').d('手机号码')}: +${phoneCode} ${mobile}`
            : `${intl.get('Setting.Mail').d('邮箱')}: ${mail || email}`;

        return val;
    }, []);

    const onFinish = (values: any) => {
        setLoading(true);

        // 没走忘记密码，就使用登录后的缓存
        const { verify: storeVerify } = JSON.parse(localStorage.getItem('userInfo') || '{}');

        const params = { password: values.password, verify: verify || storeVerify };
        updataPassword(params)
            .then((res: any) => {
                const { state } = res;
                setLoading(false);
                if (Number(state) === 1) {
                    message.success(intl.get('Setting.ChangedPwdSuc').d('密码修改成功，请重新登录'));
                    setFormType('normal');
                }
            })
            .catch(() => {
                setLoading(false);
            });
    };

    // 禁用提交按钮
    const btnDisabel = (value: any) => {
        const { confirm, password } = value;
        return !confirm || !password;
    };

    return (
        <div className={styles.mobile}>
            <Form ref={form} size="large" autoComplete="off" className={styles.form} onFinish={onFinish}>
                <Form.Item className={styles.val}>{account}</Form.Item>
                <Form.Item
                    name="password"
                    style={{ marginBottom: 40 }}
                    rules={[
                        { required: true, message: pleaseEnter(intl.get('BindAccount.Password').d('请输入密码')) },
                        { type: 'string', max: 16, message: intl.get('Setting.PassSixteen').d('密码长度最多16位字符') },
                        { type: 'string', min: 8, message: intl.get('Setting.PassEight').d('密码长度最少8位字符') },
                    ]}
                >
                    <Password
                        autoComplete="new-password"
                        iconRender={(iconVisible) =>
                            iconVisible ? (
                                <NextImage
                                    src="/img/login/pwd_active.webp"
                                    width={22}
                                    style={{ cursor: 'pointer' }}
                                    height={22}
                                    alt="pwd_active"
                                />
                            ) : (
                                <NextImage
                                    style={{ cursor: 'pointer' }}
                                    src="/img/login/pwd.webp"
                                    width={22}
                                    height={22}
                                    alt="pwd"
                                />
                            )
                        }
                        placeholder={pleaseEnter(intl.get('BindAccount.Password').d('请输入密码'))}
                    />
                </Form.Item>
                <Form.Item
                    name="confirm"
                    className={styles.botMargin}
                    style={{ marginBottom: 40 }}
                    rules={[
                        {
                            required: true,
                            message: pleaseAgainEnter(intl.get('BindAccount.Password').d('请再次输入密码')),
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(
                                    new Error(intl.get('BindAccount.PswInconsistent').d('两次密码不一致')),
                                );
                            },
                        }),
                    ]}
                >
                    <Password
                        autoComplete="new-password"
                        iconRender={(iconVisible) =>
                            iconVisible ? (
                                <NextImage
                                    src="/img/login/pwd_active.webp"
                                    width={22}
                                    style={{ cursor: 'pointer' }}
                                    height={22}
                                    alt="pwd_active"
                                />
                            ) : (
                                <NextImage
                                    style={{ cursor: 'pointer' }}
                                    src="/img/login/pwd.webp"
                                    width={22}
                                    height={22}
                                    alt="pwd"
                                />
                            )
                        }
                        placeholder={pleaseAgainEnter(intl.get('BindAccount.Password').d('请再次输入密码'))}
                    />
                </Form.Item>

                <Form.Item shouldUpdate>
                    {({ getFieldsValue }) => {
                        return (
                            <Button
                                className={styles.btn}
                                type="primary"
                                htmlType="submit"
                                block
                                size="large"
                                disabled={btnDisabel(getFieldsValue())}
                                loading={loading}
                                aria-label="Confirm"
                            >
                                {intl.get('Common.Confirm').d('确认')}
                            </Button>
                        );
                    }}
                </Form.Item>
            </Form>
        </div>
    );
};

export default ResetPwd;
