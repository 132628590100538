import TransferCom from '@/components/public/TransferCom';
import MBrokerItem from '../MBrokerItem';
import PCBrokerItem from './pc';
import type { BrokerItemProps } from '../type';

/**
 * 小图样式
 * @param props
 * @returns
 */
const BrokerItem: React.FC<BrokerItemProps> = (props) => {
    return (
        <TransferCom
            m={<MBrokerItem {...props} />}
            ipad={<PCBrokerItem {...props} />}
            pc={<PCBrokerItem {...props} />}
        />
    );
};

export default BrokerItem;
