import TransferCom from '@/components/public/TransferCom';

import MArticleItem from './h5';
import PCArticleItem from './pc';
import type { Iprops } from './type';

const ArticleItem = (props: Iprops) => {
    return (
        <TransferCom
            m={<MArticleItem {...props} />}
            ipad={<PCArticleItem {...props} />}
            pc={<PCArticleItem {...props} />}
        />
    );
};

export default ArticleItem;
