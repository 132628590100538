import React from 'react';
import TransferCom from '@/components/public/TransferCom';
import MBrokerItem from './m';
import PcBrokerItem from './pc';
import type { BrokerItemProps } from '../type';

/**
 * 收藏列表样式
 * @param props
 * @returns
 */
const BrokerItem: React.FC<BrokerItemProps> = (props) => {
    return (
        <TransferCom
            m={<MBrokerItem {...props} />}
            ipad={<PcBrokerItem {...props} />}
            pc={<PcBrokerItem {...props} />}
        />
    );
};

export default BrokerItem;
