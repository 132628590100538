import React, { useState } from 'react';
import c from 'classnames';
import { useAppSelector } from '@/store/hook';
import styles from './index.module.less';
import RenderList from './RenderList';
import intl from 'react-intl-universal';

interface Props {
    dataSource: any;
}

const Information: React.FC<Props> = ({ dataSource }) => {
    const { imgPrefix } = useAppSelector((state: any) => {
        return state.config;
    });

    const { slogan = '', top_information = [] } = dataSource || {};
    const [showInformation, setShowInformation] = useState<boolean>(false);

    const showSwitchHandler = () => {
        setShowInformation(!showInformation);
    };

    return (
        (top_information?.length > 0 || slogan) && (
            <div className={styles.information__wrapper}>
                <div className={styles.information__hander}>
                    <div className={styles.information__header__text}>{slogan}</div>
                    {top_information?.length > 0 && (
                        <div onClick={showSwitchHandler} className={styles.information__header__switch}>
                            {showInformation
                                ? intl.get('Search.Close').d('收起')
                                : intl.get('Search.ViewInformation').d('查看资讯')}
                            <em
                                className={c(styles.switch__show__switch, { [styles.show]: showInformation })}
                                style={{
                                    backgroundImage: `url('${imgPrefix}/broker/icon/${
                                        showInformation ? 'up_more' : 'down_more'
                                    }.webp')`,
                                }}
                            />
                        </div>
                    )}
                </div>
                {top_information?.length > 0 && showInformation && (
                    <React.Fragment>
                        <RenderList list={top_information} />
                    </React.Fragment>
                )}
            </div>
        )
    );
};

export default Information;
