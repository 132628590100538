import React, { useState, useCallback, useEffect, useRef } from 'react';
import { scriptRegex } from '@/constants/regex';
import clone from 'lodash/clone';
import { formatDomUnit, resizeObserverFun } from '@/utils';
import c from 'classnames';
import styles from './index.module.less';
import type { HeaderMarketProps } from '../types';

const Market: React.FC<HeaderMarketProps> = (params) => {
    const { elementId, customStyle, code } = params;
    const wrapRef = useRef<any>(null);

    // ------------- 高度
    const [marketHeight, setMarketHeight] = useState<number>(78);
    useEffect(() => {
        resizeObserverFun(wrapRef.current, setMarketHeight, 'clientHeight');
    }, []);

    // 加载中
    const [twJsLoading, setTwJsLoading] = useState<boolean>(true);
    // 加载成功
    const [twJsIsLoad, setTwJsIsLoad] = useState<boolean>(false);
    // 加载失败
    const [twJsError, setTwJsError] = useState<boolean>(false);
    const [jsMap, setJsMap] = useState<any[]>([]); // 已加载过的js

    /** 去除code里的script代码 */
    const delScript = useCallback((str: string) => {
        return str.replace(scriptRegex.scriptTagRegex, '');
    }, []);

    /** 加载js */
    const loadScript = useCallback(
        (src: string, async: boolean, content: string, wrapRef?: any) => {
            setTwJsLoading(true);
            setTwJsError(false);
            const iframeElements = wrapRef?.current?.getElementsByTagName('iframe');
            // 删除所有的 iframe 标签
            while (iframeElements?.length > 0) {
                const iframeElement = iframeElements[0];
                iframeElement.parentNode.removeChild(iframeElement);
            }
            const script = document.createElement('script');
            script.src = src;
            script.async = async;
            script.innerHTML = content;
            script.onload = () => {
                setJsMap([...jsMap, src]);
                setTwJsLoading(false);
                setTwJsIsLoad(true);
            };
            script.onerror = () => {
                setJsMap([...jsMap, src]);
                setTwJsLoading(false);
                setTwJsError(true);
            };
            wrapRef?.current?.insertBefore?.(script, wrapRef.current?.childNodes?.[0]);
        },
        [jsMap],
    );
    /** 执行script代码 */
    const execScript = useCallback(
        (content: string, src: string) => {
            try {
                const script = document.querySelector(`script[src='${src}']`);
                if (!script) {
                    const cloneArr = clone(jsMap);
                    setJsMap(
                        cloneArr.filter((item) => {
                            return item !== src;
                        }),
                    );
                }
                window?.eval?.(content);
            } catch (e) {}
        },
        [jsMap],
    );

    //----------- 小工具script执行
    useEffect(() => {
        // 执行小工具代码
        if (twJsIsLoad || !code) return;
        const match = code.match(scriptRegex.scriptTagRegex) || [];
        if (match.length === 0) return;
        for (const srcStr of match) {
            const src = srcStr.match(scriptRegex.srcRegex)?.[1] || ''; // src内容
            const async = scriptRegex.asyncRegex.test(srcStr); // async 内容
            const content = srcStr.match(scriptRegex.scriptContentRegex)?.[1] || ''; // srcipt内容
            const isLoad = src && jsMap?.includes(src); // 是否已加载
            if (isLoad) {
                execScript?.(content, src);
            } else {
                loadScript?.(src, async, content, wrapRef);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [code, wrapRef, twJsIsLoad]);

    return (
        <div
            id={elementId}
            className={c(styles.market, { [styles.market_loading]: twJsLoading || twJsError })}
            style={{ height: marketHeight - 32, ...formatDomUnit(customStyle || {}) }}
        >
            <div ref={wrapRef} className={styles.wrapper} dangerouslySetInnerHTML={{ __html: delScript(code || '') }} />
        </div>
    );
};

export default Market;
