import React from 'react';
import c from 'classnames';

import { NextImage } from '@/components';
import { currentLocaleUrl, newFormatTimer } from '@/utils';

import styles from './index.module.less';

const ArticleItem = ({ item, hasNewsFlash }: any) => {
    const { title, article_add_time, rule_name, originate, ugc, cover, seo_url, code } = item || {};
    const timer = newFormatTimer(article_add_time);
    return (
        <a
            href={currentLocaleUrl(`/article/${seo_url || code}`)}
            target="_blank"
            className={c(styles.wrapper__article, { [styles.wrapper__noNewsFlash]: !hasNewsFlash })}
        >
            <div className={styles.wrapper__cover}>
                {/* 资讯图片 */}
                <NextImage
                    src={cover ? cover.url : ''}
                    place="new_normal"
                    layout="fill"
                    objectFit="cover"
                    alt={title}
                />
            </div>

            {/* 文章标题 */}
            <div className={styles.wrapper__title}>
                <h2>{title}</h2>
            </div>

            {/* 底部作者时间 */}
            <div className={styles.wrapper__info}>
                {ugc?.name || rule_name || originate ? (
                    <span className={styles.wrapper__ugc}>{ugc?.name || rule_name || originate}</span>
                ) : null}
                {timer && (
                    <>
                        <em className={styles.wrapper__line} />
                        <span className={styles.wrapper__timer}>{timer}</span>
                    </>
                )}
            </div>
        </a>
    );
};

export default ArticleItem;
