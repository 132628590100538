import { useMemo } from 'react';

function useGetThirdPartyLogin() {
    /** 第三方列表 */
    const loginTypeList = useMemo(() => {
        return [
            // { value: 'facebook', title: 'Facebook' },
            // { value: 'google', title: 'Google' },
            { value: 'twitter', title: 'Twitter' },
            { value: 'apple', title: 'Apple' },
        ];
    }, []);

    /** 第三方登录 */
    const onThirdPartyLogin = async (type: string) => {
        sessionStorage.setItem('loginType', type);
        const { facebookAppId, appleAppId, twitterAppId, googleAppId }: any = process.env.NEXT_PUBLIC_SETTING;
        if (type === 'apple') {
            location.href = `https://appleid.apple.com/auth/authorize?client_id=${appleAppId}&redirect_uri=${location.origin}/login/callback&response_type=code`;
        } else if (['google', 'twitter', 'facebook'].includes(type)) {
            // https://adodson.com/hello.js/#helloinit //
            // 配置地址 https://auth-server.herokuapp.com/#manage-apps   用twitter账号登录 使用github登录
            const hello = require('hellojs/dist/hello.all.js');
            hello.init(
                {
                    twitter: twitterAppId,
                    facebook: facebookAppId,
                    google: googleAppId,
                },
                {
                    oauth_proxy: 'https://auth-server.herokuapp.com/proxy',
                    // redirect_uri: `/login/callback`
                },
            );
            hello.login(type, {
                display: 'page',
                redirect_uri: '/login/callback',
                page_uri: false,
                force: false,
            });
        } else {
            console.log(type);
        }
    };

    return {
        /** 第三方列表 */
        loginTypeList,
        /** 第三方登录 */
        onThirdPartyLogin,
    };
}

export default useGetThirdPartyLogin;
