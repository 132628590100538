import React from 'react';
import { NextImage } from '@/components';
import styles from './index.module.less';
import intl from 'react-intl-universal';
import { currentLocaleUrl } from '@/utils';

interface Props {
    list: any[];
    morePage: any;
}

const Activity: React.FC<Props> = ({ list = [], morePage }) => {
    return (
        <div className={styles.activity}>
            <span className={styles.watermark} />
            <div className={styles.activityCon}>
                <h2 className={styles.activityTit}>
                    <div>
                        <span>{intl.get('Activity.LastedAct').d('最新活动')}</span>
                        <NextImage src="/icon/new.webp" width={28} height={28} needPrefix alt="activity" />
                    </div>
                    <a className={styles.activityMore} href={currentLocaleUrl(`/${morePage}`)} target="_blank">
                        {intl.get('Common.SeeMore').d('查看更多')}
                    </a>
                </h2>
                <ul className={styles.activityList}>
                    {[...list].map((item: any) => {
                        const { id, title, picture } = item;
                        return (
                            <li key={id}>
                                <a href={currentLocaleUrl(`/activity/${item.id}`)} target="_blank">
                                    <NextImage src={picture} layout="fill" alt={title} />
                                    <div className={styles.itemTitle}>{title}</div>
                                </a>
                            </li>
                        );
                    })}
                </ul>
            </div>
        </div>
    );
};

export default Activity;
