import React, { useRef } from 'react';
import { NextImage } from '@/components';
import { getArticleViewNum, formatTimer, formatMts, currentLocaleUrl } from '@/utils';
import c from 'classnames';
import styles from './index.module.less';
import intl from 'react-intl-universal';

interface Props {
    item: any;
}

const VideoItem: React.FC<Props> = ({ item = {} }: any) => {
    const picRef = useRef<any>(null);

    const {
        code,
        title,
        titleMts,
        datetime,
        is_original,
        is_topping,
        block_id,
        blockId,
        ugc,
        rule_name,
        view_num,
        total,
        seo_url,
        thumbnail_pics = [],
    } = item || {};

    const type = !!blockId;

    const bId = !type ? block_id : blockId;

    const thumbnail = thumbnail_pics[0] || {};
    const timer = formatTimer(datetime);

    const href = !type ? `/article/${seo_url || code}` : `/tv/${bId}/${seo_url || code}`;

    const tit = formatMts(titleMts) || title;

    return (
        <div className={styles.videoItem}>
            <a href={currentLocaleUrl(href)} target="_blank">
                <div className={styles.videoCover} ref={picRef}>
                    <NextImage src={thumbnail.url} layout="fill" alt={tit} />
                    <span className={c(styles.videoPlay, 'iconfont', 'icon-shipin')} />
                </div>
                <div className={styles.videoContain}>
                    <div className={styles.videoTitle}>
                        {is_topping === 1 ? (
                            <span className={c(styles.top, is_original !== 1 ? styles.margin : null)}>
                                {intl.get('Broker.Top').d('置顶')}
                            </span>
                        ) : null}
                        {is_original === 1 ? (
                            <span
                                className={c(
                                    styles.origin,
                                    intl.getInitOptions().currentLocale === 'en' ? styles['en_origin'] : '',
                                )}
                            >
                                {intl.get('Common.Original').d('原创')}
                            </span>
                        ) : null}
                        <h2>{tit}</h2>
                    </div>
                    <div className={styles.videoMsg}>
                        {(ugc?.name || rule_name) && !type ? (
                            <span className={styles.msga}>{ugc?.name || rule_name}</span>
                        ) : null}
                        <div>
                            <span className={styles.msgb}>{timer}</span>
                            {!type ? (
                                <span className={styles.msgc}>
                                    <span className={c(styles.icon, 'iconfont', 'icon-liulanliang')} />
                                    {getArticleViewNum(view_num || 0)}
                                </span>
                            ) : (
                                <span className={styles.msgc}>
                                    {intl.get('Common.UpdateSet', { field: total || 0 }).d(`更新到${total || 0}集`)}
                                </span>
                            )}
                        </div>
                    </div>
                </div>
            </a>
        </div>
    );
};

export default VideoItem;
