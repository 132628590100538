import React, { useContext } from 'react';
import intl from 'react-intl-universal';
import { Divider } from 'antd';
import { useRouter } from 'next/router';

import styles from './index.module.less';
import { LoginContext } from '../../content';
import useGetThirdPartyLogin from '../../hook/useGetThirdPartyLogin';
import { NextImage } from '@/components';

function index() {
    const { setFormType, verifyType, formType, setVerifyType, resetPassword } = useContext(LoginContext);
    const router = useRouter();

    // ----------------------- 第三方登录 -----------------------
    const { loginTypeList, onThirdPartyLogin } = useGetThirdPartyLogin();
    // ----------------------- 第三方登录 -----------------------

    /** 跳转忘记密码 */
    const goFindPwd = () => {
        setVerifyType('code');
        setFormType('forget');
        resetPassword();
    };

    /** 切换验证类型 */
    const toggleLoginType = () => {
        setVerifyType(verifyType === 'code' ? 'password' : 'code');

        resetPassword();
    };

    /** 返回登录 */
    const goBackLogin = () => {
        setFormType('normal');
        resetPassword();
    };

    return (
        <>
            {/* 是否忘记密码 */}
            {['normal'].includes(formType) ? (
                <>
                    {verifyType === 'code' ? (
                        <div className={styles.pwdBtns}>
                            <div className={styles.pwdLogin} onClick={toggleLoginType}>
                                {intl.get('Login.PassLogin').d('密码登录')}
                            </div>
                        </div>
                    ) : (
                        <div className={styles.pwdBtns}>
                            <span className={styles.pwdLogin} onClick={goFindPwd}>
                                {intl.get('Login.ForgetPass').d('忘记密码')}
                            </span>
                            <span style={{ flex: 1 }} />
                            <span className={styles.pwdLogin} onClick={toggleLoginType}>
                                {intl.get('Login.VerifyCodeLogin').d('验证码登录')}
                            </span>
                        </div>
                    )}
                    <div className={styles.divider}>
                        <Divider style={{ color: '#999', fontSize: 12 }}>
                            {intl.get('Login.OtherLogin').d('其他登录方式')}
                        </Divider>
                    </div>

                    {/* 第三方登录 */}
                    <div className={styles.thirdParties}>
                        {loginTypeList.map((item: any) => {
                            const { value, title } = item;
                            return (
                                <div
                                    className={styles.thirdLogo}
                                    key={value}
                                    onClick={() => {
                                        onThirdPartyLogin(value);
                                    }}
                                    title={title}
                                >
                                    <NextImage
                                        src={`/bind/${value}.webp`}
                                        needPrefix
                                        width={24}
                                        height={24}
                                        alt={title}
                                    />
                                </div>
                            );
                        })}
                    </div>
                    <p className={styles.tip}>
                        {intl
                            .get('Login.NotSupportedYet')
                            .d('* 暂不支持中国大陆手机号码登录/注册，未注册手机验证后将自动登陆～')}
                    </p>
                </>
            ) : null}
            {['set', 'forget'].includes(formType) && !router.asPath.includes('/login/callback') && (
                <div className={styles.pwdBtns}>
                    <div className={styles.pwdLogin} style={{ color: '#999' }} onClick={goBackLogin}>
                        {intl.get('SetPwd.ReturnLogin').d('返回登录')}
                    </div>
                </div>
            )}
        </>
    );
}

export default index;
