import React from 'react';
import c from 'classnames';
import { NextImage } from '@/components';
import styles from './index.module.less';
import intl from 'react-intl-universal';
import { currentLocaleUrl } from '@/utils';

interface Props {
    list: any[];
}

const UgcRec: React.FC<Props> = ({ list = [] }) => {
    return (
        <div className={styles.ugcRec}>
            <span className={styles.watermark} />
            <div className={styles.ugcRecCon}>
                <h2 className={styles.ugcRecTit}>
                    <span style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>
                        {intl.get('Home.FeaturedRecommended').d('精选推荐')}
                    </span>
                    <NextImage src="/icon/creator.webp" needPrefix width={28} height={28} alt="creator" />
                </h2>
                <ul className={styles.ugcRecList}>
                    {[...list].map((item: any) => {
                        const { id, avatar, name, news_number } = item;
                        return (
                            <li key={id}>
                                <a href={currentLocaleUrl(`/ugc/${id}`)} className={styles.itemCon} target="_blank">
                                    <div className={styles.itemCover}>
                                        <NextImage
                                            src={avatar}
                                            alt={name}
                                            place="ugc"
                                            className={styles.avatar}
                                            width={48}
                                            height={48}
                                        />
                                    </div>
                                    <div className={styles.itemContain}>
                                        <div className={styles.itemTitle}>{name}</div>
                                        <div className={styles.itemMsg}>
                                            {intl
                                                .get('Ugc.CoPublishedArticles', { field: news_number })
                                                .d(`共发表${news_number}篇文章`)}
                                        </div>
                                        <span className={c(styles.itemArrow, 'iconfont', 'icon-jiantou')} />
                                    </div>
                                </a>
                            </li>
                        );
                    })}
                </ul>
                <a className={styles.loadMore} href={currentLocaleUrl('/ugc')} target="_blank">
                    {intl.get('Home.ViewAllBtn').d('查看全部')}
                </a>
            </div>
        </div>
    );
};

export default UgcRec;
