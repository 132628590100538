import React, { useCallback, useEffect, useRef, useState, useImperativeHandle } from 'react';
import { Select } from 'antd';
import c from 'classnames';
import { NextImage } from '@/components';
import SwiperCore, { Mousewheel } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import intl from 'react-intl-universal';
import { debounce } from 'lodash';
import { getBusinessList, getBrokerList } from '@/services/broker';
import type { BrokerSelectProps, BrokerSearchProps } from '../../types';
import styles from './index.module.less';

const { Option } = Select;
SwiperCore.use([Mousewheel]);

const BrokerSelect: React.FC<BrokerSelectProps> = ({ onRef, parentRef, contrastList = [], onAddItem, setErrorMsg }) => {
    const selRef = useRef<any>(null);
    // loading
    const [loading, setLoading] = useState<boolean>(false);
    // select 下拉弹窗
    const [open, setOpen] = useState<boolean>(false);
    // 业务列表
    const [businessList, setBusinessList] = useState<any>([]);
    // 交易商列表
    const [brokerList, setBrokerList] = useState<any>([]);
    const [brokerTotal, setBrokerTotal] = useState<number>(0);
    const [selValue, setSelValue] = useState<string | undefined>(undefined);
    // 当前选择交易商的业务类型
    const [businessId, setBusinessId] = useState<number | undefined>(undefined);
    // 业务类型选中id
    const [activeTabId, setActiveTabId] = useState<number | undefined>(undefined);
    // 下拉列表
    const { current: pagination } = useRef<BrokerSearchProps>({
        page: 1,
        pageSize: 20,
        sort_field: 'score',
        sort_type: -1,
    }); // 分页信息
    const { current: selCurrent } = useRef<{ canClose: boolean }>({ canClose: true });

    /**
     * 初始化获取交易商类型
     */
    useEffect(() => {
        getBusinessList().then((res: any) => {
            const { state, data = [] } = res || {};
            if (state === 1) {
                setBusinessList(data || []);
            }
        });
    }, []);

    /**
     * 设置当前选择的交易商业务类型
     */
    useEffect(() => {
        if (contrastList.length !== 0) {
            const { type: firstType }: { type: number } = contrastList[0];
            setBusinessId(firstType);
            setActiveTabId(firstType);
        } else {
            const { id: firstId }: { id: number } = businessList[0] || {};
            setBusinessId(undefined);
            setActiveTabId(firstId);
        }
    }, [contrastList, businessList]);

    /**
     * 获取交易商下拉列表
     */
    const getBrokerListFun = useCallback(
        (parmas: BrokerSearchProps) => {
            if (!loading && parmas?.type) {
                setLoading(true);
                const searchValue = { ...pagination, ...parmas };

                getBrokerList(searchValue)
                    .then((res: any) => {
                        const { state, data } = res || {};
                        if (state === 1) {
                            const { list = [], total }: { list: any; total: number } = data || {};
                            const newList = list.map((item: any) => ({
                                ...item,
                                value: item.id,
                                label: item.name_abbreviation,
                            }));
                            setBrokerList(parmas.page === 1 ? newList : brokerList.concat(newList));
                            setBrokerTotal(total);
                        }
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }
        },
        [brokerList, loading, pagination],
    );

    useEffect(() => {
        if (activeTabId) {
            pagination.type = activeTabId;
            getBrokerListFun({ page: 1, type: activeTabId });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeTabId]);

    /**
     * 业务列表 选择
     * @param item
     */
    const onBusinessTabClick = (item: any) => {
        const { id }: { id: number } = item;
        pagination.type = activeTabId;
        setActiveTabId(id);
        setOpen(true);
    };

    /**
     * 搜索下拉列表
     * @param v
     */
    const onSelectSearch = debounce((v: string) => {
        setBrokerList([]);
        pagination.page = 1;
        pagination.name_abbreviation = v;

        getBrokerListFun(pagination);
    }, 500);

    /**
     * 加载更多
     * @param event
     */
    const onPopupScroll = (event: any) => {
        const { target } = event;
        // 滚动 触底 看接口是否还有剩余的值没传过来

        if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
            if (brokerList.length < brokerTotal && !loading) {
                // 每滚动一次，多加载20条数据
                pagination.page = pagination.page + 1;
                getBrokerListFun({ page: pagination.page, type: activeTabId });
            }
        }
    };

    /**
     * 选择 broker
     * @param value
     */
    const onBrokerSelect = (value: string) => {
        selCurrent.canClose = true;
        const newItem = brokerList.find((item: any) => item.id === value);
        setSelValue('');
        setOpen(false);
        pagination.name_abbreviation = undefined;
        onAddItem(
            newItem,
            (err: string) => {
                setErrorMsg(err);
                setTimeout(() => {
                    setSelValue(undefined);
                }, 50);
            },
            () => {
                setErrorMsg('');
                setTimeout(() => {
                    setSelValue(undefined);
                }, 50);
            },
        );
    };

    // 为空时
    const notFoundContent = (
        <div className={styles.nullData}>
            <NextImage width={150} height={120} src="/img/place/null_data.webp" alt="empty" />
            <p className={styles.nullTxt}>{intl.get('Common.NoData').d('暂无数据')}～</p>
        </div>
    );

    /**
     * select 下拉框 开启关闭回调
     * @param v
     */
    const onDropdownVisibleChange = (v: boolean) => {
        if (v) {
            setOpen(v);
        } else {
            if (selCurrent.canClose) {
                setOpen(v);
            } else {
                setOpen(true);
            }
        }
    };

    /**
     * 阻止默认事件和冒泡
     * @param event
     */
    const nativeEventFun = (event: any) => {
        selCurrent.canClose = false;
        event?.stopPropagation();
        event?.nativeEvent?.stopImmediatePropagation();
        event?.preventDefault();
        setTimeout(() => {
            selCurrent.canClose = true;
        }, 1200);
    };

    // 暴露方法给父级组件
    useImperativeHandle(onRef, () => {
        return {
            // 添加对比
            onOpen: setOpen,
        };
    });

    return (
        <Select
            open={open}
            showSearch
            allowClear
            value={selValue}
            className={styles.brokerSelect}
            popupClassName={styles.popupClass}
            getPopupContainer={() => parentRef?.current}
            placeholder={intl.get('Broker.AddTrader').d('添加交易商')}
            suffixIcon={<span className={c(styles.suffix, 'iconfont', 'icon-jiantou')} />}
            onSearch={onSelectSearch}
            onSelect={onBrokerSelect}
            onPopupScroll={onPopupScroll}
            onDropdownVisibleChange={onDropdownVisibleChange}
            notFoundContent={notFoundContent}
            filterOption={false}
            ref={selRef}
            dropdownRender={(menu) => {
                return (
                    <div className={styles.selectDropdown} onMouseDown={nativeEventFun}>
                        {!businessId && (
                            <div className={styles.swiperBox} onMouseDown={nativeEventFun}>
                                <Swiper
                                    observer
                                    resizeObserver
                                    grabCursor
                                    slidesPerView="auto"
                                    spaceBetween={16}
                                    mousewheel
                                    onMouseDown={nativeEventFun}
                                    onTouchMove={(_s, e) => nativeEventFun(e)}
                                    onTouchStart={(_s, e) => nativeEventFun(e)}
                                    onTouchEnd={(_s, e) => nativeEventFun(e)}
                                >
                                    {businessList.map((item: any) => (
                                        <SwiperSlide
                                            className={c(styles.tabsItem, {
                                                [styles.active]: activeTabId === item.id,
                                            })}
                                            key={item.id}
                                            onClick={() => onBusinessTabClick(item)}
                                        >
                                            {item.business}
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            </div>
                        )}
                        {menu}
                    </div>
                );
            }}
        >
            {brokerList.map((item: any) => {
                const { id, logo, name_abbreviation } = item || {};
                return (
                    <Option
                        value={id}
                        label={name_abbreviation}
                        key={id}
                        className={styles.selOption}
                        title={name_abbreviation}
                    >
                        <div className={styles.logo}>
                            <NextImage
                                className={styles.img}
                                src={logo}
                                place="logo_a"
                                alt={name_abbreviation}
                                width={28}
                                height={28}
                            />
                        </div>
                        <span className={styles.name}>{name_abbreviation}</span>
                    </Option>
                );
            })}

            {/* {brokerList.length === brokerTotal && (
                <Option disabled key="more" className={styles.noMore}>
                    <p className={styles.noMoreText} key="more">
                        {intl.get('Common.NoMore').d('没有更多了哦～')}
                    </p>
                </Option>
            )} */}
        </Select>
    );
};

export default BrokerSelect;
