import cloneDeep from 'lodash/cloneDeep';
import { getFileType } from './util';
import { getBrokerSwitchList, getBusinessList } from '@/services/broker';

export const defaultFilterValues = {
    text: [
        // "type", // 业务
        'supervise_state', // 监管状态
        'broker_datum.supervisory_licence.regulator', // 监管机构
        'broker_type.trading_software', // 交易软件
        // ----------- 证券
        'broker_other_info.data_upload.supervisory_licence.regulator', // 监管机构
        'broker_other_info.option.instrument_exchange', // 交易工具
    ],
    select: [
        'broker_industry.compensatory_balance', // 最低存款
        'broker_industry.maximum_lever', // 最大杠杆
        'broker_industry.minimum_position', // 最小头寸
        'broker_industry.warehouse_blowout', // 爆仓比例
        'broker_type.account_type', // 账户类型
        'broker_industry.promotion_word', // 点差类型
        'broker_type.order_type', // 订单执行类型
        // ----------- 证券
        'broker_other_info.option.investment_market', // 投资市场
        'broker_other_info.option.investment_type', // 投资种类
        'broker_other_info.option.open_account_method', // 开户门槛及方法
        'broker_other_info.option.account_type', // 账户类型
        'broker_other_info.option.hk_order_type', // 港股订单类型
        'broker_other_info.option.us_order_type', // 美股订单类型
    ],
    switch: [
        'broker_type.state', // 锁仓
        'broker_type.ea', // ea交易
        'broker_type.scalp', // 超短线
        'broker_type.micro_account', // 微型账户
        'broker_type.mock_account', // 模拟账户
        // ----------- 证券
        'broker_other_info.option.swap', // 换汇
        'broker_other_info.option.leverage', // 杠杆
        'broker_other_info.option.warrant', // 新股认购
        'broker_other_info.option.mock_account', // 模拟账户
    ],
};

// 默认
export const anchorNavsForNormal = [
    {
        key: 'part-1',
        title: '公司概况',
        title_mts: 'Broker.CompanyOverview',
        id: 1,
        columns: [
            {
                dict_name: '交易商名称',
                dict_name_mts: 'Broker.TraderName',
                dict_type: 'name_abbreviation',
            },
            {
                dict_name: '公司地址',
                dict_name_mts: 'Broker.CompanyAddress',
                dict_type: 'company_address',
            },
            {
                dict_name: '公司荣誉',
                dict_name_mts: 'Broker.NormalNavs2',
                dict_type: 'broker_datum.company_honor',
            },
            {
                dict_name: '公司实景',
                dict_name_mts: 'Broker.CompanyScene',
                dict_type: 'broker_datum.company_scene',
            },
            {
                dict_name: '评分',
                dict_name_mts: 'Broker.Score',
                dict_type: 'score',
            },
            {
                dict_name: '评级',
                dict_name_mts: 'Broker.Rating',
                dict_type: 'rate',
            },
            {
                dict_name: '注册时间',
                dict_name_mts: 'Broker.RegistrationTime',
                dict_type: 'year',
            },
            {
                dict_name: '注册地（国家/地区）',
                dict_name_mts: 'Broker.PlaceRegistration',
                dict_type: 'country',
            },
            {
                dict_name: '监管状态',
                dict_name_mts: 'Broker.RegulatoryStatus',
                dict_type: 'supervise_state',
            },
            {
                dict_name: '监管牌照',
                dict_name_mts: 'Broker.RegulatoryLicense',
                dict_type: 'broker_datum.supervisory_licence',
            },
            {
                dict_name: '联系方式',
                dict_name_mts: 'Broker.ContactInfo',
                dict_type: 'contact_way',
            },
        ],
    },
];

// 外汇
export const anchorNavsForA = [
    {
        key: 'part-1',
        title: '公司概况',
        title_mts: 'Broker.CompanyOverview',
        id: 1,
        columns: [
            'name_abbreviation',
            'company_address',
            'broker_datum.company_honor',
            'broker_datum.company_scene',
            'score',
            'rate',
            'year',
            'country',
            'supervise_state',
            'broker_datum.supervisory_licence',
            'broker_describe.education',
            'broker_describe.customer_support',
            'broker_describe.fund_security',
            'contact_way',
        ],
    },
    {
        key: 'part-2',
        title: '交易相关',
        title_mts: 'Broker.TransactionRelated',
        id: 2,
        columns: [
            'broker_describe.bonus_amount',
            'broker_industry.promotion_word',
            'broker_industry.promotion_type',
            'broker_industry.overnight_charge',
            'broker_industry.brokerage',
            'broker_industry.bail',
            'broker_industry.exchange_fee',
            'broker_industry.account_charges',
            'broker_industry.into_money',
            'broker_industry.out_money',
            'broker_industry.compensatory_balance',
            'broker_industry.maximum_lever',
            'broker_industry.minimum_position',
            'broker_industry.warehouse_blowout',
            'broker_type.state',
            'broker_type.ea',
            'broker_type.scalp',
            'broker_type.order_type',
            'broker_industry.trade_category',
        ],
    },
    {
        key: 'part-3',
        title: '账户相关',
        title_mts: 'Broker.AccountRelated',
        id: 3,
        columns: [
            'broker_type.account_type',
            'broker_type.mock_account',
            'broker_type.micro_account',
            'broker_type.trading_software',
            'broker_describe.tools_functions',
        ],
    },
];

// 证券
export const anchorNavsForB = [
    {
        key: 'part-1',
        title: '公司概况',
        title_mts: 'Broker.CompanyOverview',
        id: 1,
        columns: [
            'name_abbreviation',
            'company_address',
            'broker_other_info.data_upload.company_honor',
            'broker_other_info.data_upload.company_scene',
            'score',
            'rate',
            'popularity_ranking',
            'year',
            'country',
            'supervise_state',
            'broker_other_info.data_upload.supervisory_licence',
            'broker_other_info.description.company_profile',
            'broker_other_info.description.serve',
            'broker_other_info.description.software_fun',
            'is_listed',
            'contact_way',
        ],
    },
    {
        key: 'broker_hong_kong_stocks_info',
        title: '港股',
        title_mts: 'Broker.TabLabel1',
        id: 5,
        children: [
            {
                title: '基础信息',
                title_mts: 'Broker.TabLabel11',
                key: 'basic_info',
            },
            {
                title: '期权',
                title_mts: 'Broker.TabLabel12',
                key: 'share_option',
            },
            {
                title: '股票期行使权或被行使权',
                title_mts: 'Broker.TabLabel13',
                key: 'stake_exercise',
            },
            {
                title: '其它服务费',
                title_mts: 'Broker.TabLabel14',
                key: 'other_service_fee',
            },
            {
                title: '申购新股费用',
                title_mts: 'Broker.TabLabel15',
                key: 'stake_fee',
            },
            {
                title: '暗盘交易费用',
                title_mts: 'Broker.TabLabel16',
                key: 'dark_trading_fee',
            },
            {
                title: '港股收费',
                title_mts: 'Broker.HKStockCharges',
                key: 'stocks_charge',
            },
        ],
    },
    {
        key: 'broker_american_stocks_info',
        title: '美股',
        title_mts: 'Broker.TabLabel2',
        id: 6,
        children: [
            {
                title: '基础信息',
                title_mts: 'Broker.TabLabel11',
                key: 'basic_info',
            },
            {
                title: '美股期权',
                title_mts: 'Broker.USStockOptions',
                key: 'share_option',
            },
            {
                title: '其它服务费',
                title_mts: 'Broker.TabLabel14',
                key: 'other_service_fee',
            },
            {
                title: '美国存托凭证（ADR）代收费用',
                title_mts: 'Broker.TabLabel24',
                key: 'adr_charges',
            },
            {
                title: '美股SPAC费用',
                title_mts: 'Broker.USSPACFees',
                key: 'spac_charges',
            },
            {
                title: '美股收费（交易所或政府）',
                title_mts: 'Broker.StocksCharge',
                key: 'stocks_charge',
            },
        ],
    },
    {
        key: 'part-4',
        title: '其它信息',
        title_mts: 'Broker.TabLabel3',
        id: 7,
    },
];

// ------------------------------- 对比数据格式化 ----start
const getResultList = (l: any[], clonecolumn: any[]) => {
    return (l || []).map((item: any) => {
        const { columns = [] } = item || [];
        const nList = (clonecolumn || []).filter(
            (cloneItem: any) => cloneItem.top_type === item.id && cloneItem.status === 1,
        );
        const newList: any = [];
        const moreList = nList.filter((item: any) => !columns.includes(item.dict_type));
        columns.forEach((key: any) => {
            const column = nList.find((nItem: any) => nItem?.dict_type === key);
            if (column) {
                newList.push(column);
            }
        });
        return {
            ...item,
            columns: [...newList, ...moreList],
        };
    });
};

export const formatContrastData = async (data: any, locale: string) => {
    const { brokerOne, brokerTwo, brokerThree } = data || {};
    const hasItem = [brokerOne, brokerTwo, brokerThree].find((item: any) => item?.data?.state === 1);
    const { type, business_type = 1 } = hasItem?.data || {};

    const businessRes = (await getBusinessList(locale)) || {}; // 获取业务
    const { data: businessList = [] } = businessRes || {};

    let bType = type; // 业务类型
    if (!type) {
        const { type: bT } = businessList.find((item: any) => item.type === business_type && item.state === 1) || {};
        bType = bT;
    }

    const columnRes = await getBrokerSwitchList(bType, locale);
    const columnData = columnRes?.data || [];
    const cloneColumn = cloneDeep(columnData || []);

    let firstNavs: any = [];
    let result: any = [];

    switch (business_type) {
        case 0:
            firstNavs = anchorNavsForNormal;
            result = getResultList(anchorNavsForNormal, cloneColumn);
            break;
        case 1:
            firstNavs = anchorNavsForA;
            result = getResultList(anchorNavsForA, cloneColumn);
            break;
        case 2:
            anchorNavsForB.forEach((item: any) => {
                const { title, title_mts, key, id, columns = [], children = [] } = item || [];
                const nList = cloneColumn.filter(
                    (cloneItem: any) => cloneItem.top_type === item.id && cloneItem.status === 1,
                );
                const newList: any = [];
                const moreList = nList.filter((item: any) => !columns.includes(item.dict_type));
                columns.forEach((key: any) => {
                    const column = nList.find((nItem: any) => nItem?.dict_type === key);
                    if (column) {
                        newList.push(column);
                    }
                });

                const newColumns = [...newList, ...moreList];
                if (children.length !== 0) {
                    const newChild: any = [];
                    const navChild: any = [];
                    children.forEach((child: any) => {
                        const { key: cKey } = child || {};
                        const moreItem = newColumns.filter((nItem: any) => nItem?.dict_type.includes(`${key}.${cKey}`));

                        if (moreItem.length !== 0) {
                            newChild.push({
                                ...child,
                                id,
                                key: `${key}.${cKey}`,
                                columns: moreItem,
                                parent: title,
                                parent_mts: title_mts,
                            });

                            navChild.push({
                                ...child,
                                id,
                                parent: title,
                                parent_mts: title_mts,
                            });
                        }
                    });

                    result.push({
                        ...item,
                        children: newChild,
                        columns: [...newList, ...moreList],
                    });

                    firstNavs.push({
                        ...item,
                        children: navChild,
                    });
                } else {
                    result.push({
                        ...item,
                        columns: [...newList, ...moreList],
                    });

                    firstNavs.push({ ...item });
                }
            });
            break;
        default:
            firstNavs = anchorNavsForA;
            result = getResultList(anchorNavsForA, cloneColumn);
            break;
    }

    const newList = result.filter((item: any) => (item?.columns || []).length !== 0);
    const bannerObj = cloneColumn.find((item: any) => item?.dict_type == 'contrast_head_diagram' && item?.status === 1);
    const { value } = bannerObj || {};
    const bannerData = JSON.parse(value || '{}');

    return {
        brokerList: [
            { key: 1, ...(brokerOne?.data?.state !== 1 ? {} : brokerOne?.data || {}) },
            { key: 2, ...(brokerTwo?.data?.state !== 1 ? {} : brokerTwo?.data || {}) },
            { key: 3, ...(brokerThree?.data?.state !== 1 ? {} : brokerThree?.data || {}) },
        ],
        dataList: newList,
        navList: firstNavs,
        bannerData,
        businessList,
    };
};

// ------------------------------- 对比数据格式化 ----end

/** 设置图片链接 */
const formatImgUrl = (item: any, brokerId: number | string, prop: string, index: number) => {
    const { flag } = item || {};
    const type = getFileType(flag);
    if (type !== 'pdf') {
        return `/broker/${brokerId}/${prop}_${index}`;
    } else {
        return item?.flag || '';
    }
};

/** 转换资料信息数据结构 */
export const formatFileItem = (item: any, brokerId: number | string, prop: string, index: number = 0) => {
    const { type, url = [], third_url, manuscript_address } = item || {};
    switch (type) {
        case 0:
            if (!manuscript_address) {
                return {};
            }
            return {
                ...item,
                type,
                url: manuscript_address?.flag ? `/article/${manuscript_address?.flag}` : '',
            };
        case 1:
            if (!third_url) {
                return {};
            }
            return { ...item, type, url: third_url?.flag || '' };
        case 2:
            const urlItem = (url || [])[0] || {};
            if ((url || []).length === 0) {
                return {};
            }
            return { ...item, type, url: formatImgUrl(urlItem, brokerId, prop, index) };
        default:
            return {};
    }
};

/** 转换资料信息数据结构 */
export const formatNewFileItem = (item: any) => {
    const { type, url = [], third_url, manuscript_address } = item || {};

    switch (type) {
        case 0: // 内部稿件
            if (!manuscript_address?.flag) {
                return {};
            }
            return {
                ...item,
                url: manuscript_address?.flag ? `/article/${manuscript_address?.flag}` : '',
                show: true,
            };
        case 1: // 第三方链接
            if (!third_url?.flag) {
                return {};
            }

            return { ...item, url: third_url?.flag || '', show: true };
        case 2: // 图片 或 pdf
            if ((url || []).length === 0) {
                return {};
            }

            const urlItem = (url || [])[0] || {};
            const { flag } = urlItem || {};
            const fileType = getFileType(flag);
            if (fileType === 'pdf') {
                return { ...item, url: flag, show: true };
            } else {
                return { ...item, type: 3, files: url, show: true };
            }
        default:
            return {};
    }
};

/**
 * 判断对象各字段是否为空
 * @param obj
 * @returns
 */
export const formatObjectIsNull = (obj: any) => {
    let newList: any = [];
    Object.keys(obj || {}).forEach((key: any) => {
        if (key.includes('_mts')) {
            return;
        }
        if (Object.prototype.toString.call(obj[key]) === '[object Object]') {
            newList = newList.concat(formatObjectIsNull(obj[key]));
            return;
        }
        if (obj[key] instanceof Array) {
            if ((obj[key] || []).length !== 0) {
                newList.push(true);
            }
            return;
        }
        if (obj[key] || obj[key] === 0) {
            newList.push(true);
        }
    });

    return newList.filter((item: boolean) => item).length !== 0;
};
