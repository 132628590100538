import request from '@/utils/axios';

interface GetLanguageParams {
    lang?: string;
    url?: string;
}

/** 获取mts多语言 */
export const getMtsLanguage = (params: GetLanguageParams): any => {
    const { lang, url = 'hawk_website' } = params;

    return request.get(`/multilingual-translation-system/${url}/${lang}.json`);
};
