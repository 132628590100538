import React from 'react';
import c from 'classnames';
import styles from './index.module.less';
import type { SwitchBtnProps } from '../types';

const SwitchBtn: React.FC<SwitchBtnProps> = ({ activeIndex = 0, noShadow, max = 1, onClickBtn }) => {
    return (
        <div className={styles.switch}>
            <div
                className={c(styles.switch_btn, noShadow ? styles.noShadow : '', {
                    [styles.disabled]: activeIndex === 0,
                })}
                onClick={() => onClickBtn('l')}
            >
                <span className={c('iconfont', 'icon-jiantou', styles.icon, styles.left)} />
            </div>
            <div
                className={c(styles.switch_btn, noShadow ? styles.noShadow : '', {
                    [styles.disabled]: activeIndex === max,
                })}
                onClick={() => onClickBtn('r')}
            >
                <span className={c('iconfont', 'icon-jiantou', styles.icon, styles.right)} />
            </div>
        </div>
    );
};

export default SwitchBtn;
