import React, { useEffect } from 'react';
import intl from 'react-intl-universal';
import { cloneDeep } from 'lodash';
import { App } from 'antd';

import TransferCom from '@/components/public/TransferCom';
import useCustomModal from '@/reconstruction/components/public/CustomModal';
import { topicFocusAddPost, topicFocusDelete } from '@/services/focus';
import type { TopicDataType } from '@/type/topic';

import MColumnListItem from './m';
import PCColumnListItem from './pc';
import type { ColumnListItemType } from './type';

const ColumnListItem = (props: ColumnListItemType) => {
    const { dataSource } = props;
    const { message } = App.useApp();
    const [columnData, setColumnData] = React.useState<TopicDataType>(dataSource);

    const [customModal, CustomModal] = useCustomModal(); // 弹窗

    useEffect(() => {
        setColumnData(dataSource);
    }, [dataSource]);
    const { is_focus, _id } = columnData || {};

    /** 取消/关注回调 */
    const handleFocus = () => {
        const fun = is_focus === 1 ? topicFocusDelete : topicFocusAddPost;
        fun(_id).then((res: any) => {
            if (res?.state === 1) {
                message.success(
                    is_focus === 1
                        ? intl.get('Topic.UnfollowSuc').d('取消订阅成功！')
                        : intl.get('Topic.FollowedSuc').d('订阅成功！'),
                );
                const data = cloneDeep(columnData);
                data.is_focus = is_focus === 1 ? 0 : 1;

                setColumnData(data);
            }
        });
    };

    /**
     * 关注/取消关注
     */
    const onFocusColumn = (event: any) => {
        event?.preventDefault();
        if (_id) {
            if (is_focus === 1) {
                customModal.confirm({
                    open: true,
                    title: intl.get('Topic.WantToUnfollow').d(`确定要取消订阅吗？`),
                    onOk: () => {
                        handleFocus();
                        customModal.close();
                    },
                });
            } else {
                handleFocus();
            }
        }
    };

    const normalProps = {
        columnData,
        onFocusColumn,
    };

    return (
        <>
            <TransferCom
                m={<MColumnListItem {...props} {...normalProps} />}
                pc={<PCColumnListItem {...props} {...normalProps} />}
            />
            {CustomModal}
        </>
    );
};

export default ColumnListItem;
