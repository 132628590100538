import request from '@/utils/axios';

//   个人中心修改头像
export const postFileUpload = (data: any) => {
    return request.post('/file', data, { headers: { 'Content-Type': 'multipart/form-data' } })
};

// 举报反馈上传图片
export const postImgsUpload = (data: any) => {
    return request.post('/japi/upload_token/file_name', data, { headers: { 'Content-Type': 'multipart/form-data' } })
};

// 获取上传至阿里云的参数
export async function getAliYunToken(params: {
    type: string,
    fileName: string
}) {
    return request.get('/japi/upload_token/security/token', params);
};


// 获取文件信息
export const getFileInfo = (parmas: {
    fileName: string
    type: string
}) => {
    return request.get('/japi/upload_token/upload/info', parmas)
};
