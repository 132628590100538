import React, { useEffect } from 'react';
import { Tabs } from 'antd';
import intl from 'react-intl-universal';

import Email from './components/email';
import Footer from './components/footer';
import Phone from './components/phone';
import ResetPwd from './components/ResetPwd';
import { LoginContext, type LoginFormType, type LoginFromType } from './content';
import styles from './index.module.less';
import useLoginForm from './hook/useLoginForm';

function index({ onCancel, type }: { onCancel: () => void; type?: LoginFormType }) {
    // ----------------------- 表单相关 -----------------------
    const LoginFromParams = useLoginForm(onCancel);
    // ----------------------- 表单相关 -----------------------

    const { formType, setFormType } = LoginFromParams;

    useEffect(() => {
        if (!type) {
            return;
        }
        setFormType(type);
    }, [type]);

    return (
        <LoginContext.Provider value={LoginFromParams}>
            {['set'].includes(formType) ? (
                <>
                    <div className={styles.formType}>{intl.get('BindAccount.SetPassword').d('设置密码')}</div>
                    <ResetPwd />
                </>
            ) : (
                <Tabs
                    defaultActiveKey="email"
                    onChange={(key) => LoginFromParams.setCurrentFormType(key as LoginFromType)}
                    className={styles.tabs}
                    items={[
                        { label: intl.get('BindAccount.Email').d('电子邮箱'), key: 'email', children: <Email /> },
                        { label: intl.get('BindAccount.Phone').d('手机号'), key: 'phone', children: <Phone /> },
                    ]}
                ></Tabs>
            )}
            <Footer />
        </LoginContext.Provider>
    );
}

export default index;
