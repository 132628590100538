import React from 'react';
import { Form, Input } from 'antd';
import c from 'classnames';
import intl from 'react-intl-universal';
import type { SearchInputProps } from '../types';
import styles from './index.module.less';

const SearchInput: React.FC<SearchInputProps> = (props) => {
    const { form, search, inpVal, isFocus, inputRef, onFocus, onPressEnter, onInputChange } = props || {};

    const onClearInput = () => {
        form.setFieldsValue({ searchVal: undefined });
        onInputChange({ target: { value: undefined } });
    };

    return (
        <div className={c(styles.search_contain, search || isFocus ? styles.search_focus : null)}>
            <Form form={form} className={styles.form}>
                <Form.Item noStyle name="searchVal">
                    <Input
                        ref={inputRef}
                        autoComplete="off"
                        prefix={<span className={c(styles.input_icon, styles.sh_icon, 'iconfont', 'icon-sousuo')} />}
                        suffix={
                            <div className={styles.icon_group}>
                                {isFocus ? (
                                    <span
                                        onClick={onClearInput}
                                        className={c(styles.input_icon, 'iconfont', 'icon-shanchu')}
                                    />
                                ) : null}
                            </div>
                        }
                        className={c(
                            styles.search_input,
                            inpVal ? styles.search_has : null,
                            isFocus && !inpVal ? styles.search_color : null,
                        )}
                        placeholder={intl.get('Header.HeaderSearch').d('请输入')}
                        onChange={onInputChange}
                        onPressEnter={onPressEnter}
                        onFocus={onFocus}
                    />
                </Form.Item>
            </Form>
        </div>
    );
};

export default SearchInput;
