import React from 'react';
import { Divider } from 'antd';
import c from 'classnames';
import { NextImage } from '@/components';
import { currentLocaleUrl, newFormatTimer } from '@/utils';
import styles from './index.module.less';
import { ArticleType } from '@/type/article';
import { useAppSelector } from '@/store/hook';

const MArticleItem = ({
    item,
    rightLayout,
    className = '',
}: {
    item: ArticleType;
    rightLayout?: boolean;
    className?: string;
}) => {
    const { code, title, datetime, rule_name, ugc, thumbnail_pics = [], seo_url } = item || {};

    const thumbnail = thumbnail_pics[0] || {};
    const timer = newFormatTimer(datetime);
    const { showBarkly } = useAppSelector((state: any) => {
        return state.config;
    });

    return (
        <div
            className={c(
                styles.articleItem,
                thumbnail.url ? styles.typeOne : null,
                {
                    [styles.barkly_container]: showBarkly,
                },
                className,
            )}
        >
            <a
                href={currentLocaleUrl(`/article/${seo_url || code}`)}
                target="_blank"
                className={c(rightLayout && styles.articleItem_a_box)}
            >
                {thumbnail.url ? (
                    <div className={c(styles.articleCover, rightLayout && styles.articleCoverRight)}>
                        {item.category === 'audio' && (
                            <span className={styles.audio__btn}>
                                <NextImage src="/img/audio/article-cover.webp" width={12} height={12} alt="audio" />
                            </span>
                        )}
                        {item.category === 'video' && (
                            <div className={styles.video_play_btn}>
                                <NextImage src="/img/video/play.webp" width={18} height={18} alt="play" />
                            </div>
                        )}
                        {/* 资讯图片 */}
                        <NextImage src={thumbnail.url} layout="fill" alt={title} />
                        <span className={c(styles.videoPlay, 'iconfont', 'icon-shipin')} />
                    </div>
                ) : null}

                <div className={c(styles.articleCon, rightLayout && styles.articleConRight)}>
                    {/* 文章标题 */}
                    <div className={styles.articleTitle}>
                        <h2>{title}</h2>
                    </div>

                    {/* 底部作者时间 */}
                    <div className={styles.articleMsg}>
                        {ugc?.name || rule_name ? <span className={styles.msga}>{ugc?.name || rule_name}</span> : null}
                        {timer && (
                            <div>
                                <Divider type="vertical" />
                                <span className={styles.msgb}>{timer}</span>
                            </div>
                        )}
                    </div>
                </div>
            </a>
        </div>
    );
};

export default MArticleItem;
