import React, { useCallback, useEffect, useRef, useState, useImperativeHandle } from 'react';
import { Select } from 'antd';
import c from 'classnames';
import { NextImage } from '@/components';
import SwiperCore, { Mousewheel } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import intl from 'react-intl-universal';
import { debounce } from 'lodash';
import { getBrokerList } from '@/services/broker';
import NoContent from '@/components/public/NoContent';

import type { BrokerSelectProps, BrokerSearchProps } from './types';
import formUtils from '@/utils/form';

import styles from './index.module.less';

SwiperCore.use([Mousewheel]);

const { pleaseEnter } = formUtils;

const BrokerSelect: React.FC<BrokerSelectProps> = ({
    size = 'middle',
    max,
    onRef,
    parentRef,
    activeType,
    activeIndex,
    businessList,
    alignCenter,
    placeholder,
    onSearch,
    onSelect,
}) => {
    const selRef = useRef<any>(null);
    // loading
    const [loading, setLoading] = useState<boolean>(false);
    // select 下拉弹窗
    const [open, setOpen] = useState<boolean>(false);

    // 交易商列表
    const [brokerList, setBrokerList] = useState<any>([]);
    const [brokerTotal, setBrokerTotal] = useState<number>(0);
    // 选择的数据
    const [selValue, setSelValue] = useState<string | undefined>(undefined);

    // 控制搜索文本
    const [searchValue, setSearchValue] = useState<string | undefined>(undefined);
    // 当前选择交易商的业务类型
    const [businessId, setBusinessId] = useState<number | undefined>(undefined);
    // 业务类型选中id
    const [activeTabId, setActiveTabId] = useState<number | undefined>(undefined);
    // 下拉列表
    const { current: pagination } = useRef<BrokerSearchProps>({
        page: 1,
        page_size: max || 20,
        sort_field: 'score',
        sort_type: -1,
    }); // 分页信息
    const { current: selCurrent } = useRef<{ canClose: boolean }>({ canClose: true });

    /**
     * 设置当前选择的交易商业务类型
     */
    useEffect(() => {
        if (activeType) {
            setBusinessId(activeType);
            setActiveTabId(activeType);
        } else {
            const { id: firstId }: { id: number } = businessList[0] || {};
            setBusinessId(undefined);
            setActiveTabId(firstId);
        }
    }, [activeType, businessList]);

    /**
     * 获取交易商下拉列表
     */
    const getBrokerListFun = useCallback(
        (parmas: BrokerSearchProps) => {
            if (!loading && parmas?.type) {
                setLoading(true);
                const shValue = { ...pagination, ...parmas };

                getBrokerList(shValue)
                    .then((res: any) => {
                        const { state, data } = res || {};
                        if (state === 1) {
                            const { list = [], total }: { list: any; total: number } = data || {};

                            const newList = list.map((item: any) => {
                                const { id, logo, name_abbreviation } = item || {};
                                return {
                                    ...item,
                                    value: id,
                                    label: (
                                        <div className={styles.selItem}>
                                            <div className={styles.logo}>
                                                <NextImage
                                                    className={styles.img}
                                                    src={logo}
                                                    place="logo_a_new"
                                                    alt={name_abbreviation}
                                                    width={28}
                                                    height={28}
                                                />
                                            </div>
                                            <span className={styles.name}>{name_abbreviation}</span>
                                        </div>
                                    ),
                                };
                            });
                            setBrokerList(parmas.page === 1 ? newList : brokerList.concat(newList));
                            setBrokerTotal(total);
                        }
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }
        },
        [brokerList, loading, pagination],
    );

    useEffect(() => {
        if (activeTabId && open) {
            pagination.type = activeTabId;
            getBrokerListFun({ page: 1, type: activeTabId });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeTabId, open]);

    /**
     * 业务列表 选择
     * @param item
     */
    const onBusinessTabClick = (item: any) => {
        const { id }: { id: number } = item;
        pagination.type = activeTabId;
        setActiveTabId(id);
        setOpen(true);
    };

    /**
     * 搜索文本框值变化时回调
     * @param v
     */
    const onSearchChange = debounce((v: string) => {
        setBrokerList([]);
        pagination.page = 1;
        pagination.name_abbreviation = v;
        getBrokerListFun(pagination);
        onSearch?.(v, setSearchValue);
    }, 500);

    /**
     * 加载更多
     * @param event
     */
    const onPopupScroll = (event: any) => {
        const { target } = event;
        // 滚动 触底 看接口是否还有剩余的值没传过来
        if (max) {
            return;
        }

        if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
            if (brokerList.length < brokerTotal && !loading) {
                // 每滚动一次，多加载20条数据
                pagination.page = pagination.page + 1;
                getBrokerListFun({ page: pagination.page, type: activeTabId });
            }
        }
    };

    /**
     * 下拉选择 broker
     * @param value
     */
    const onBrokerSelect = (value: string) => {
        selCurrent.canClose = true;
        const newItem = brokerList.find((item: any) => item.id === value);
        setSelValue('');
        setOpen(false);
        pagination.name_abbreviation = undefined;
        onSelect?.(
            newItem,
            () => {
                setTimeout(() => {
                    setSelValue(undefined);
                }, 50);
            },
            activeIndex,
        );
    };

    // 为空时
    const notFoundContent = (
        <div className={styles.nullData}>
            <NoContent />
        </div>
    );

    /**
     * select 下拉框 开启关闭回调
     * @param v
     */
    const onDropdownVisibleChange = (v: boolean) => {
        if (v) {
            setOpen(v);
        } else {
            if (selCurrent.canClose) {
                setOpen(v);
            } else {
                setOpen(true);
            }
        }
    };

    /**
     * 阻止默认事件和冒泡
     * @param event
     */
    const nativeEventFun = (event: any) => {
        selCurrent.canClose = false;
        event?.stopPropagation();
        event?.nativeEvent?.stopImmediatePropagation();
        event?.preventDefault();
        setTimeout(() => {
            selCurrent.canClose = true;
        }, 1200);
    };

    // 暴露方法给父级组件
    useImperativeHandle(onRef, () => {
        return {
            // 添加对比
            onOpen: setOpen,
        };
    });

    return (
        <div
            className={c(styles.brokerSelectBox, {
                [styles.brokerSelectCenter]: alignCenter,
                [styles.brokerSelectLarge]: size === 'large',
            })}
        >
            <Select
                open={open}
                showSearch
                size={size}
                ref={selRef}
                value={selValue}
                searchValue={searchValue}
                className={styles.brokerSelect}
                popupClassName={c(styles.popupClass, { [styles.popupCenterClass]: alignCenter })}
                getPopupContainer={() => parentRef?.current}
                options={brokerList}
                placeholder={
                    Boolean(placeholder) ? (
                        placeholder
                    ) : (
                        <div className={styles.place}>
                            {alignCenter && <span className={c('iconfont', 'icon-sousuo', styles.icon)} />}
                            <span>{pleaseEnter(intl.get('Search.Tab3').d('交易商'))}</span>
                        </div>
                    )
                }
                loading={loading}
                suffixIcon={null}
                filterOption={false}
                onSearch={onSearchChange}
                onSelect={onBrokerSelect}
                onPopupScroll={onPopupScroll}
                onDropdownVisibleChange={onDropdownVisibleChange}
                notFoundContent={notFoundContent}
                dropdownRender={(menu) => {
                    return (
                        <div className={styles.selectDropdown} onMouseDown={nativeEventFun}>
                            {!businessId && (
                                <div className={styles.swiperBox} onMouseDown={nativeEventFun}>
                                    <Swiper
                                        observer
                                        resizeObserver
                                        grabCursor
                                        slidesPerView="auto"
                                        spaceBetween={16}
                                        mousewheel
                                        onMouseDown={nativeEventFun}
                                        onTouchMove={(_s, e) => nativeEventFun(e)}
                                        onTouchStart={(_s, e) => nativeEventFun(e)}
                                        onTouchEnd={(_s, e) => nativeEventFun(e)}
                                    >
                                        {businessList.map((item: any) => (
                                            <SwiperSlide
                                                className={c(styles.tabsItem, {
                                                    [styles.active]: activeTabId === item.id,
                                                })}
                                                key={item.id}
                                                onClick={() => onBusinessTabClick(item)}
                                            >
                                                {item.business}
                                            </SwiperSlide>
                                        ))}
                                    </Swiper>
                                </div>
                            )}
                            {menu}
                        </div>
                    );
                }}
            />

            {!alignCenter && <span className={c('iconfont', 'icon-sousuo', styles.prefix)} />}
        </div>
    );
};

export default BrokerSelect;
