import React from 'react';
import { useAppDispatch, useAppSelector } from '@/store/hook';
import { setMenuOpen } from '@/store/config';
import styles from './index.module.less';

const HeaderMenu: React.FC = () => {
    const dispatch = useAppDispatch();
    // 菜单展开收起
    const { menuOpen } = useAppSelector((state: any) => state.config);

    const onOpen = () => {
        dispatch(setMenuOpen(!menuOpen));
    };

    return (
        <div className={styles.menu}>
            <ul onClick={onOpen}>
                <li />
                <li />
                <li />
            </ul>
        </div>
    );
};

export default HeaderMenu;
