/** 页面多语言路径 */
export const mtsTypeList = ['zh-CN', 'en', 'ja'];

/** 语言类型对应的长英文 */
export const getMultiInputLang: Record<string, string> = {
    'zh-CN': 'zh_cns',
    en: 'en_us',
    ja: 'ja_jpn',
};

/** 语言类型对应的MTS key */
export const mtsLangMap: Record<string, string> = {
    'zh-CN': 'zh_cns',
    en: 'en_us',
    ja: 'ja_jp',
};

/** 语言类型对应的短英文 */
export const apiLangHeaderMap: Record<string, string> = {
    'zh-CN': 'zh',
    en: 'en',
    ja: 'ja',
};

/** 页面语言切换配置 */
export const langItems = [
    {
        label: '中文',
        key: 'zh-CN',
    },
    {
        label: 'English',
        key: 'en',
    },
    {
        label: '日本語',
        key: 'ja',
    },
];

/** 多语言图片前缀标记 */
export const langImgPrefix: any = {
    'zh-CN': '',
    en: 'en_',
    ja: 'ja_',
};

/** 极验多语言 */
export const geeLanguage: Record<string, string> = {
    'zh-CN': 'zho',
    en: 'eng',
    ja: 'jpn',
};

/** 多语言地区key */
export const langCityKeys: Record<string, string> = {
    'zh-CN': 'chinese_name',
    en: 'english_name',
    ja: 'japan_name',
};

/** 分析师页面数据key */
export const analystLangKeys: any = {
    en: 'value',
    ja: 'value',
    'zh-CN': 'title',
};
