import request from '@/utils/axios'

// 个人中心信息查询
export const getAppUserApi = () => {
    return request.get('/japi/appUser')
};

// 个人中心信息更改
export const putAppUserApi = (data: any) => {
    return request.put('/japi/appUser', data)
};

export const getUploadConfig = () => {
    return request.get('/manage/config/upload_image')
}