import React from 'react';
import { NextImage } from '@/components';
import intl from 'react-intl-universal';
import { useAppSelector } from '@/store/hook';
import { currentLocaleUrl, formatBrokerBusiness } from '@/utils';
import type { BrokerItemProps } from '../../type';
import styles from './index.module.less';

const BrokerItem: React.FC<BrokerItemProps> = ({ index, rank, record, locale }) => {
    const currentLocale = locale || intl.getInitOptions().currentLocale;
    const { imgPrefix } = useAppSelector((state: any) => state.config);

    const { id, logo, name_abbreviation, age, score, business_name, license = [], seo_url } = record;

    return (
        <a href={currentLocaleUrl(`/broker/${seo_url || id}`)} target="_blank" className={styles.li}>
            <div className={styles.logo_box}>
                <div className={styles.logo}>
                    <NextImage src={logo} layout="fill" alt={name_abbreviation} place="new_normal" />
                </div>
                {rank && (index || 0) < 3 && (
                    <div className={styles.rank}>
                        <NextImage
                            src={`${imgPrefix}/broker/icon/billboard_${(index || 0) + 1}.webp`}
                            width={20}
                            height={20}
                            alt="expand"
                        />
                    </div>
                )}
            </div>
            <div className={styles.info}>
                <div className={styles.info_top}>
                    <p className={styles.name}>{name_abbreviation}</p>
                    <p className={styles.score}>
                        <span>
                            <strong>{Number(score || 0).toFixed(1)}</strong>
                        </span>
                        <span>{intl.get('Broker.Score').d('评分')}</span>
                    </p>
                </div>

                <div className={styles.tag_list}>
                    <span>{formatBrokerBusiness(business_name, currentLocale)}</span>
                    <span>{age}</span>
                    <span className={styles.license}>{license?.join?.('  |  ') || ''}</span>
                </div>
            </div>
        </a>
    );
};

export default BrokerItem;
