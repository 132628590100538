import React, { useEffect, useMemo, useState } from 'react';
import { NextImage } from '@/components';
import { WeiboShareButton, FacebookShareButton, TwitterShareButton } from 'next-share';

interface Props {
    type: 'weibo' | 'facebook' | 'twitter';
    style?: any;
    size?: number; // 图标大小
    title?: string; // 标题
    url?: any; // 地址
    cover?: string; // 封面
    // isCheck?: boolean; // 是否选中样式
    defaultImg?: string; // 推特默认图片传入
    activeImg?: string; // 推特高亮图片展示
}

const Share: React.FC<Props> = ({ size, title, url, cover, type, style = {}, defaultImg, activeImg }) => {
    const [shareUrl, setShareUrl] = useState<string>('');
    const [isCheckFB, setIsCheckFB] = useState<boolean>(false);
    const [isCheckTS, setIsCheckTS] = useState<boolean>(false);

    useEffect(() => {
        setShareUrl(url || location.href);
    }, [url]);

    const srcImageHtml = (src: string, type: 'facebook' | 'twitter') => {
        return <NextImage src={src} width={size} height={size} needPrefix alt={type} />;
    };

    const flash_facebook_check = useMemo(() => srcImageHtml(`/bind/flash_facebook_check.webp`, 'facebook'), []);
    const flash_facebook = useMemo(() => srcImageHtml(`/bind/flash_facebook.webp`, 'facebook'), []);

    const defaultTwitterImgHtml = useMemo(() => srcImageHtml(defaultImg || '', 'twitter'), [defaultImg]);
    const activeTwitterImgHtml = useMemo(() => srcImageHtml(activeImg || '', 'twitter'), [activeImg]);

    const defaultFaceBookImgHtml = useMemo(() => srcImageHtml(defaultImg || '', 'facebook'), [defaultImg]);
    const activeFaceBookImgHtml = useMemo(() => srcImageHtml(activeImg || '', 'facebook'), [activeImg]);

    const flash_twitter_check = useMemo(() => srcImageHtml(`/bind/flash_twitter_check.webp`, 'twitter'), []);
    const flash_twitter = useMemo(() => srcImageHtml(`/bind/flash_twitter.webp`, 'twitter'), []);

    switch (type) {
        case 'weibo':
            return (
                <WeiboShareButton
                    url={shareUrl}
                    title={`【${title}】`}
                    image={cover}
                    style={{ width: size, height: size, ...style }}
                >
                    <NextImage src="/bind/wb.webp" width={size} height={size} needPrefix alt="wb" />
                </WeiboShareButton>
            );
        case 'facebook':
            return (
                <FacebookShareButton url={shareUrl} quote={title} style={{ width: size, height: size, ...style }}>
                    <div
                        onMouseEnter={() => {
                            setIsCheckFB(true);
                        }}
                        onMouseLeave={() => {
                            setIsCheckFB(false);
                        }}
                    >
                        {defaultImg && activeImg
                            ? isCheckFB
                                ? activeFaceBookImgHtml
                                : defaultFaceBookImgHtml
                            : isCheckFB
                              ? flash_facebook_check
                              : flash_facebook}
                    </div>
                </FacebookShareButton>
            );
        case 'twitter':
            return (
                <TwitterShareButton url={shareUrl} title={title} style={{ width: size, height: size, ...style }}>
                    <div
                        onMouseEnter={() => {
                            setIsCheckTS(true);
                        }}
                        onMouseLeave={() => {
                            setIsCheckTS(false);
                        }}
                    >
                        {defaultImg && activeImg
                            ? isCheckTS
                                ? activeTwitterImgHtml
                                : defaultTwitterImgHtml
                            : isCheckTS
                              ? flash_twitter_check
                              : flash_twitter}
                    </div>
                </TwitterShareButton>
            );
        default:
            return null;
    }
};

export default React.memo(Share);
