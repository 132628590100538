import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { scriptRegex } from '@/constants/regex';
import { formatDomUnit, resizeObserverFun } from '@/utils';
import c from 'classnames';
import { useAppDispatch } from '@/store/hook';
import { setSlideAdHeight } from '@/store/config';
import styles from './index.module.less';
import type { AdvertiseProps } from '../type';

const FirstAdvertise: React.FC<AdvertiseProps> = ({ elementId, adCode, adStyle, moreStyle, serviceData }) => {
    const wrapRef = useRef<any>(null);
    const [showAd, setShowAd] = useState<boolean>(true);
    const dispatch = useAppDispatch();

    const [domHeight, setDomHeight] = useState<number>(290);

    useEffect(() => {
        resizeObserverFun(wrapRef.current, setDomHeight, 'clientHeight');
    }, []);

    // 获取侧边广告高度
    useEffect(() => {
        if (domHeight !== 0) {
            dispatch(setSlideAdHeight(domHeight));
        }
    }, [dispatch, domHeight]);

    // 自定义宽高
    const { width: normalWidth, height: normalHeight, minHeight } = moreStyle || {};

    // 设置谷歌广告代码
    const { adsenseUrl, adsenseClient }: any = process.env.NEXT_PUBLIC_SETTING;

    // 初始化广告代码 push
    useEffect(() => {
        const scriptElement: any = document.querySelector(`script[src="${adsenseUrl}?client=${adsenseClient}"]`);
        handleScriptLoad(scriptElement);
    }, [adCode]);

    // width
    const domWidth = useMemo(() => {
        const { paddingLeft = 0, paddingRight = 0 } = adStyle || {};
        const left: number = (paddingLeft || 0) as number;
        const right: number = (paddingRight || 0) as number;
        const _w: number = (normalWidth || 0) as number;

        return _w - left - right;
    }, [adStyle, normalWidth]);

    // script
    const handleScriptLoad = (scriptElement: any) => {
        try {
            if (window?.adsbygoogle) {
                console.log('pushing ads ');
                window.adsbygoogle.push({});
            } else {
                scriptElement.addEventListener('load', handleScriptLoad);
                console.log('waiting until adsense lib is loaded...This prevents adsbygoogle is not defined error');
            }
        } catch (err) {
            console.log('error in adsense - This prevents ad already exists error', err);
        }
    };

    /**
     * 去除 script
     */
    const delScript = useCallback((str: string) => {
        return str.replace(scriptRegex.scriptTagRegex, '');
    }, []);

    const observerAds = useCallback(() => {
        try {
            const insEle = wrapRef?.current?.querySelector('.adsbygoogle');

            if (insEle) {
                insEle.style.width = normalWidth || domWidth ? `${normalWidth || domWidth}px` : 'auto';
                // insEle.style.height = normalHeight || domHeight ? `${normalHeight || domHeight}px` : 'auto';

                const timer = Date.now();
                insEle.id = `${elementId}_${timer}`;

                const insObserver = new MutationObserver((mutationList, observer) => {
                    for (let mutation of mutationList) {
                        if (mutation.type === 'attributes' && mutation.attributeName === 'data-ad-status') {
                            const status = insEle.getAttribute('data-ad-status');

                            if (status && status !== 'unfilled') {
                                setShowAd(true);
                            } else {
                                setShowAd(false);
                            }
                            observer.disconnect();
                        }
                    }
                });
                insObserver.observe(insEle, { attributes: true });
            }
        } catch (err) {
            console.log('...', err);
        }
    }, [domWidth, normalWidth, normalHeight]);

    useEffect(() => {
        observerAds();
    }, [observerAds, adCode]);

    /**
     * --------------------------------------------------- 格式化样式 start
     */
    const style = formatDomUnit(adStyle || {});
    /**
     * --------------------------------------------------- 格式化样式 end
     */

    return (
        <div
            ref={wrapRef}
            className={c(styles.container, { [styles.containerHide]: !showAd })}
            id={(elementId || '').toString()}
            style={{
                ...(showAd ? style : {}),
                ...(showAd ? moreStyle : {}),
            }}
        >
            <div
                dangerouslySetInnerHTML={{ __html: delScript(adCode) }}
                style={{ width: domWidth, height: normalHeight, minHeight }}
            />
        </div>
    );
};

export default FirstAdvertise;
