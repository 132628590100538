import request from '@/utils/axios';

// 查询区号
export const getPhoneCodeList = () => {
    return request.get('/cms/country/code/list');
};

// 查询国家
export const getAreaList = (locale?: string) => {
    return request.get('/cms/country/area/list', {}, { locale });
};
