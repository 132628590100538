import React from 'react';
import { formatMts } from '@/utils/mts';
import type { FooterProps } from '../types';
import styles from './index.module.less';
import { useAppSelector } from '@/store/hook';
import c from 'classnames';

const Footer: React.FC<FooterProps> = ({ config }) => {
    const { footer, footer_mts } = config || {};
    const { showBarkly } = useAppSelector((state: any) => state.config);
    return (
        <footer className={c(styles.footer, { [styles.barkly_container]: showBarkly })} id="footer">
            <div className={styles.contain}>
                <div
                    className={styles.risk_tip}
                    id="risk_tip"
                    dangerouslySetInnerHTML={{ __html: formatMts(footer_mts) || footer }}
                />

                {/* <div
                    className={styles.icp_msg}
                    id="copyright"
                    dangerouslySetInnerHTML={{ __html: formatMts(icp_msg_mts) || icp_msg }}
                /> */}
            </div>
        </footer>
    );
};

export default Footer;
