import React, { useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { NextImage } from '@/components';
import { getFeedbackUnreadAsync, getUserInfoAsync, setTopHeaderHeight } from '@/store/config';
import { useAppDispatch } from '@/store/hook';
import { currentLocaleUrl, resizeObserverFun } from '@/utils';
import intl from 'react-intl-universal';
import c from 'classnames';
import { SearchInput, MtsLang, WhatsAPP, LoginBtn, RollArticleTitle } from '../components';
import { codeList } from '../config';
import type { HeaderProps } from '../types';
import styles from './index.module.less';

const Header: React.FC<HeaderProps> = (props) => {
    const { logo = '', isApp = false, topBanner = {} } = props;
    const router = useRouter();
    const { pathname, locale } = router;

    const dispatch = useAppDispatch();

    // ------------- 头部高度
    const headerRef = useRef<any>(null);
    const [pcHeaderHeight, setPcHeaderHeight] = useState<number>(60);
    useEffect(() => {
        resizeObserverFun(headerRef.current, setPcHeaderHeight, 'clientHeight');
    }, []);

    // ------------- search
    const [inSearch, onInSearch] = useState<boolean>(pathname === '/search');

    useEffect(() => {
        dispatch(setTopHeaderHeight(pcHeaderHeight || 60));
    }, [pcHeaderHeight]);

    useEffect(() => {
        if (localStorage.getItem('Authorization')) {
            dispatch(getUserInfoAsync());
            dispatch(getFeedbackUnreadAsync());
        }
    }, [dispatch]);

    const routerGo = (path: string) => {
        router.push(path);
    };

    /**
     * 头图加载完成，设置顶部高度
     * @param e
     */
    const onTopBannerLoad = (e: any) => {
        dispatch(setTopHeaderHeight((pcHeaderHeight || 60) + e.target.clientHeight));
    };

    return (
        <header id="web-header" className={c(styles.header, { [styles.app_header]: isApp })} ref={headerRef}>
            {topBanner.visible && topBanner.url && (
                <div className={styles.top_banner}>
                    <NextImage src={topBanner.url} layout="fill" alt="banner" onLoad={onTopBannerLoad} />
                </div>
            )}

            <div className={styles.header_box}>
                <div className={styles.header_left}>
                    {logo && (
                        <h1 className={styles.logo}>
                            <a href={currentLocaleUrl('/')}>
                                <NextImage src={logo} alt="logo" width={142} height={20} />
                            </a>
                        </h1>
                    )}

                    {isApp ? (
                        <span
                            className={styles.return_home}
                            onClick={() => {
                                routerGo('/');
                            }}
                        >
                            {intl.get('Live.ReturnHome').d('返回首页')}
                        </span>
                    ) : null}
                </div>

                <div className={c(styles.header_right)}>
                    <div className={styles.nav_con}>
                        <RollArticleTitle
                            defaultShow={
                                // <>
                                //     <HeaderNav menus={menus} inSearch={inSearch} />
                                //     <SearchInput search={pathname === '/search'} onInSearch={onInSearch} />
                                // </>
                                <SearchInput search={pathname === '/search'} onInSearch={onInSearch} />
                            }
                        />

                        <ul className={styles.code_list}>
                            {[...codeList].map((item: any) => {
                                const { name, icon, list, link } = item;
                                return (
                                    <li key={name}>
                                        {list?.length ? (
                                            <WhatsAPP dataSource={item} />
                                        ) : (
                                            <Link href={link} target="_blank">
                                                <div className={styles.down}>
                                                    <span className={c(styles.icon, 'iconfont', icon)} />
                                                    <span>{name}</span>
                                                </div>
                                            </Link>
                                        )}
                                    </li>
                                );
                            })}

                            <li className={styles.extends}>
                                <LoginBtn locale={locale} />
                            </li>
                            <li className={styles.lang}>
                                <MtsLang locale={locale} />
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;
