import React, { useEffect } from 'react';
import intl from 'react-intl-universal';
import { App } from 'antd';
import { cloneDeep } from 'lodash';

import useCustomModal from '@/reconstruction/components/public/CustomModal';
import TransferCom from '@/components/public/TransferCom';
import { focusAddPost, focusDelete } from '@/services/focus';
import type { UgcListType } from '@/type/ugc';

import MUgcListItem from './m';
import PCUgcListItem from './pc';
import type { UgcListItemType } from './type';

const UgcListItem = (props: UgcListItemType) => {
    const { dataSource } = props;
    const { message } = App.useApp();
    const [ugcData, setUgcData] = React.useState<UgcListType>(dataSource);

    const { is_focus, id, name } = ugcData || {};

    const [customModal, CustomModal] = useCustomModal(); // 弹窗

    useEffect(() => {
        setUgcData(dataSource);
    }, [dataSource]);

    /** 取消/关注回调 */
    const handleFocus = () => {
        const fun = is_focus === 1 ? focusDelete : focusAddPost;
        fun(id).then((res: any) => {
            if (res?.state === 1) {
                message.success(
                    is_focus === 1
                        ? intl.get('PersonalCenter.UnfollowSuc').d('取消关注成功！')
                        : intl.get('Article.FollowedSuc').d('关注成功！'),
                );
                const data = cloneDeep(ugcData);
                data.is_focus = is_focus === 1 ? 0 : 1;

                setUgcData(data);
            }
        });
    };

    /**
     * 关注/取消关注
     */
    const onFocusUgc = (event: any) => {
        event?.preventDefault();
        if (id) {
            if (is_focus === 1) {
                customModal.confirm({
                    open: true,
                    title: intl.get('Article.WantToUnfollow', { field: name }).d(`确定要取消对 ${name} 的关注吗？`),
                    onOk: () => {
                        handleFocus();
                        customModal.close();
                    },
                });
            } else {
                handleFocus();
            }
        }
    };

    const normalProps = {
        ugcData,
        onFocusUgc,
    };
    return (
        <>
            <TransferCom
                m={<MUgcListItem {...props} {...normalProps} />}
                pc={<PCUgcListItem {...props} {...normalProps} />}
            />
            {CustomModal}
        </>
    );
};

export default UgcListItem;
