import React from 'react';
import TransferCom from '@/components/public/TransferCom';
import MChildNav from './m';
import PcChildNav from './pc';

import type { ChildNavProps } from './types';

const ChildNav: React.FC<ChildNavProps> = (props) => {
    return <TransferCom m={<MChildNav {...props} />} ipad={<PcChildNav {...props} />} pc={<PcChildNav {...props} />} />;
};

export default ChildNav;
