import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Col, Row } from 'antd';
import moment from 'dayjs';
import { NextImage } from '@/components';
import { resizeObserverFun, getColSpan, calcDay, currentLocaleUrl } from '@/utils';
import c from 'classnames';
import styles from './index.module.less';
import intl from 'react-intl-universal';

interface Props {
    list: any[];
}

const Activitys: React.FC<Props> = ({ list }) => {
    const domRef = useRef<any>(null);
    const [domWidth, setDomWidth] = useState<number>(0);
    useEffect(() => {
        resizeObserverFun(domRef.current, setDomWidth);
    }, []);

    const statusClassNames = [styles.before, styles.progressing, styles.end];

    const stateInfo = (startTime: any, endTime: any, city: string) => {
        let activityState = 0;
        const currentTime = Date.now();
        if (currentTime >= startTime) {
            activityState = startTime < currentTime && currentTime < endTime ? 1 : 2;
        }
        const region = city?.split?.('/')?.reverse()[0] || '';

        return (
            <React.Fragment>
                <span className={styles.city}>
                    <i className={c(styles.icon, 'iconfont', 'icon-dizhi')} />
                    {region}
                </span>
                {activityState !== 2 ? (
                    <div className={styles.countdown}>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: intl.get('Activity.ManyDayEvent', {
                                    field1:
                                        activityState === 0
                                            ? calcDay(currentTime, startTime)
                                            : calcDay(currentTime, endTime),
                                    field2:
                                        activityState === 0
                                            ? intl.get('Activity.Start').d('开始')
                                            : intl.get('Activity.Over1').d('结束'),
                                    field3: statusClassNames[activityState],
                                }),
                            }}
                        />
                    </div>
                ) : (
                    <span className={styles.countdown}>
                        <span className={statusClassNames[activityState]}>{intl.get('Activity.Over').d('已结束')}</span>
                    </span>
                )}
            </React.Fragment>
        );
    };

    /** 时间显示格式 */
    const activityTime = useCallback((start: string, end: string) => {
        const date1 = moment(start);
        const date2 = moment(end);
        const diffYear = date2.year() - date1.year();
        const diffMonth = date2.month() - date1.month();
        const diffDay = date2.day() - date1.day();
        let endTimeRule = '';
        if (diffYear > 0) {
            endTimeRule = 'YYYY-MM-DD';
        }
        if (diffYear <= 0 && diffMonth > 0) {
            endTimeRule = 'MM-DD';
        }
        if (diffYear <= 0 && diffMonth <= 0 && diffDay > 0) {
            endTimeRule = 'DD';
        }

        return `${moment(start).format('YYYY-MM-DD')}${endTimeRule ? ' ~ ' : ''}${
            endTimeRule ? moment(end).format(endTimeRule) : ''
        }`;
    }, []);

    return (
        <div ref={domRef} className={styles.list}>
            <Row gutter={24} className={styles.listRow}>
                {list.map((item) => {
                    return (
                        <Col key={item.id} span={getColSpan(domWidth, 4)} className={styles.listCol}>
                            <a href={currentLocaleUrl(`/activity/${item.id}`)} target="_blank">
                                <div className={styles.listItem}>
                                    <div className={styles.listItemCover}>
                                        <NextImage src={item?.picture} layout="fill" alt={item?.title} />
                                    </div>
                                    <div className={styles.listItemContent}>
                                        <div className={styles.title}>{item.title}</div>
                                        <div className={styles.time}>
                                            {intl.get('Activity.ActTime').d('活动时间')}：
                                            {activityTime(item.activityStartTimeMon, item.activityEndTimeMon)}
                                        </div>
                                        <div className={styles.btns}>
                                            {stateInfo(item.activityStartTimeMon, item.activityEndTimeMon, item.city)}
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </Col>
                    );
                })}
            </Row>
        </div>
    );
};

export default Activitys;
