import React, { useState, useMemo, useCallback } from 'react';
import { currentLocaleUrl } from '@/utils';
import { formatFileItem } from '@/utils/broker';
import TransferCom from '@/components/public/TransferCom';
import MBrokerItem from './m';
import PCBrokerItem from './pc';
import intl from 'react-intl-universal';
import type { BrokerItemProps } from '../type';

/**
 * 列表样式
 * @param props
 * @returns
 */
const BrokerItem: React.FC<BrokerItemProps> = (props) => {
    const { locale, record, showAccountBtn = true } = props || {};

    const { id, seo_url, broker_datum, broker_other_info } = record;

    /**
     * Hawkinsight专属活动
     */
    const [showAll, setShowAll] = useState<boolean>(false);

    /** 展开/收起文案 */
    const moreText = useMemo(() => {
        return showAll ? intl.get('Search.Close').d('收起') : intl.get('Common.SeeMore').d('查看更多');
    }, [showAll, locale]);

    /**
     * 开户信息
     */
    const accountUrl = useMemo(() => {
        if (!showAccountBtn) {
            return '';
        }
        const { data_upload } = broker_other_info || {};
        const openAccountItem = (broker_datum?.open_account || data_upload?.open_account || [])[0];
        const { url } = formatFileItem(openAccountItem, id, 'account') || {};
        return url;
    }, [id, broker_datum, broker_other_info, formatFileItem]);

    /**
     * 点击项
     */
    const onClickItem = useCallback(
        (event: any) => {
            event?.preventDefault();
            window.open(currentLocaleUrl(`/broker/${seo_url || id}`));
        },
        [seo_url, id],
    );

    /**
     * 去开户
     * @param event
     * @param url
     */
    const onOpenAccount = (event: any, url?: string) => {
        event?.preventDefault();
        event?.stopPropagation();
        event?.nativeEvent.stopImmediatePropagation();

        if (url) {
            window.open(currentLocaleUrl(url));
        }
    };

    const normalProps = {
        showAll,
        moreText,
        accountUrl,
        setShowAll,
        onClickItem,
        onOpenAccount,
    };

    return (
        <TransferCom
            m={<MBrokerItem {...props} {...normalProps} />}
            ipad={<PCBrokerItem {...props} {...normalProps} />}
            pc={<PCBrokerItem {...props} {...normalProps} />}
        />
    );
};

export default BrokerItem;
