import request from '@/utils/axios';

// 校验验证码
export const verificationAuthCode = (
    data: {
        mobile?: string;
        phoneCode?: string;
        verificationCode?: number;
        mail?: string;
    },
    moreConfig?: { noMsg: boolean },
) => {
    return request.post(`/japi/appUser/verification`, data, moreConfig);
};

// 个人中心修改手机
export const editPhone = (data: { mobile: string; phoneCode: string; verificationCode: string }) => {
    return request.put('/japi/appUser/editPhone', data, { noMsg: true });
};

// 个人中心修改邮箱
export const editEmail = (data: { mail: string; verificationCode: string }) => {
    return request.put('/japi/appUser/editEmail', data, { noMsg: true });
};

// 验证旧密码
export const checkPassword = (data: { oldPassword: string }) => {
    return request.post('/japi/appUser/editPasswordCheck', data, { noMsg: true });
};

// 个人中心修改密码
export const editPassword = (data: { newPassword: string; oldPassword?: string }) => {
    return request.put('/japi/appUser/editPassword', data, { noMsg: true });
};

// 个人中心第三方解绑
export const editThirdBind = (data: {
    thirdUniqueAcount: string;
    type: number;
    bindFlag: 1 | 2; // 1绑定 2解绑
}) => {
    return request.post('/japi/appUser/bingThird', data);
};

// 个人中心验证码验证
export const postForgetPhoneApi = (data: any) => {
    return request.post('/japi/appUser/forgetPhone', data);
};

// 个人中心修改手机号
export const putPhoneApi = (data: { mobile: string; phoneCode: string; verificationCode: string }) => {
    return request.put('/japi/appUser/editPhone', data, { noMsg: true });
};
