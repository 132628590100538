import React, { useEffect, useState, useRef } from 'react';
import { Form } from 'antd';
import type { InputRef } from 'antd';
import debounce from 'lodash/debounce';
import { useRouter } from 'next/router';
import { useAppDispatch } from '@/store/hook';
import { setSearchValue } from '@/store/config';

import TransferCom from '@/components/public/TransferCom';
import MSearchInput from './m';
import PcSearchInput from './pc';

import type { InputProps } from './types';

const SearchInput: React.FC<InputProps> = (props) => {
    const [form] = Form.useForm();
    const { search, defaultValue = '', onInSearch } = props;
    const router = useRouter();
    const dispatch = useAppDispatch();
    const [isFocus, setIsFocus] = useState<boolean>(false);
    const [inpVal, setInpVal] = useState<string>(defaultValue);
    const inputRef = useRef<InputRef>(null);

    useEffect(() => {
        if (!search) {
            setIsFocus(false);
            onInSearch?.(false);
            setInpVal('');
            form?.setFieldsValue({ searchVal: '' });
            dispatch(setSearchValue(''));
            inputRef.current!.blur();
        }
    }, [dispatch, search, inputRef, form]);

    // 输入框
    const onPressEnter = (e: any) => {
        const {
            target: { value },
        } = e;
        dispatch(setSearchValue(value));

        const { pathname, query } = router || {};
        const { type } = query || {};
        if (pathname === '/search') {
            router.replace({
                pathname: '/search',
                query: { keyword: value, type: type || 'common' },
            });
        } else {
            router.push({
                pathname: '/search',
                query: { keyword: value, type: type || 'common' },
            });
        }
    };

    /**
     * 输入框变化
     */
    const onInputChange = debounce((e: any) => {
        const {
            target: { value },
        } = e;
        setInpVal(value);
        dispatch(setSearchValue(value));

        const { pathname, query } = router || {};
        const { type } = query || {};
        if (pathname === '/search') {
            router.replace({
                pathname: '/search',
                query: { keyword: value, type: type || 'common' },
            });
        }
    }, 200);

    /**
     * 获得焦点
     */
    const onFocus = () => {
        setIsFocus(true);
        onInSearch?.(true);
    };

    /**
     * 返回
     * @param e
     */
    const onGoBack = (e: any) => {
        e?.stopPropagation();
        e?.nativeEvent.stopImmediatePropagation();

        if (search) {
            router.back();
        } else {
            setInpVal('');
            setIsFocus(false);
            onInSearch?.(false);
            form?.setFieldsValue({ searchVal: '' });
            dispatch(setSearchValue(''));
            inputRef.current!.blur();
        }
    };

    const shInputProps = {
        form,
        search,
        inpVal,
        isFocus,
        inputRef,
        onFocus,
        onGoBack,
        onPressEnter,
        onInputChange,
    };

    return (
        <TransferCom
            m={<MSearchInput {...shInputProps} />}
            ipad={<PcSearchInput {...shInputProps} />}
            pc={<PcSearchInput {...shInputProps} />}
        />
    );
};

export default SearchInput;
