import { useEffect, useState } from 'react';
import type { ReactElement, ReactNode } from 'react';
import { ConfigProvider, message, App as AntdApp } from 'antd';
import { Provider } from 'react-redux';
import { CookiesProvider } from 'react-cookie';
import zhCN from 'antd/locale/zh_CN';
import type { AppProps } from 'next/app';
import Router from 'next/router';
import NProgress from 'nprogress';
import type { NextPage } from 'next';
import { useRouter } from 'next/router';
import 'nprogress/nprogress.css';
import intl from 'react-intl-universal';

import store from '@/store/store';
import { Layout } from '@/components';
// import { addHrefEventListener } from '@/utils';
import theme from '@/utils/theme';
import { setImgPrefix } from '@/store/config';
import { getMtsLanguage } from '@/services/mts';
import { mtsLangMap } from '@/constants/mts';

import '../styles/globals.less';
import 'nprogress/nprogress.css';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
import isToday from 'dayjs/plugin/isToday';

dayjs.locale('zh-cn');
dayjs.extend(isToday);

type NextPageWithLayout = NextPage & {
    getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
    Component: NextPageWithLayout;
};

NProgress.configure({
    minimum: 0.3,
    easing: 'ease',
    speed: 500,
    showSpinner: false,
});

Router.events.on('routeChangeStart', () => {
    NProgress.start();
});
Router.events.on('routeChangeComplete', () => NProgress.done());
Router.events.on('routeChangeError', () => NProgress.done());

const App = ({ Component, pageProps }: AppPropsWithLayout) => {
    const { globalData = {}, ba }: any = pageProps || {};

    const { locale, query } = useRouter();
    const { imgPrefix } = globalData?.imageRootHost || {};
    const [iframePreview, setPageLayout] = useState<string>(''); // 布局

    intl.init({
        currentLocale: locale || 'zh-CN',
        locales: {
            [locale as string]: (pageProps as any).localeData,
        },
    });

    // imgPrefix
    useEffect(() => {
        if (imgPrefix) {
            window?.sessionStorage?.setItem('ImgPrefix', imgPrefix);
        }
        const prefix = window?.sessionStorage?.getItem('ImgPrefix');
        store.dispatch(setImgPrefix(imgPrefix || prefix));
    }, [imgPrefix]);

    const [messageApi, contextHolder] = message.useMessage();
    // messageApi
    useEffect(() => {
        window.antdMessage = messageApi;
    }, [messageApi]);

    // 拦截 a 标签跳转
    useEffect(() => {
        // const addAHref = setInterval(() => {
        //     addHrefEventListener();
        // }, 500);
        if (query?.iframePreview) {
            setPageLayout(query.iframePreview as string);
        }

        // return () => addAHref && clearInterval(addAHref);
    }, []);

    // 监听网络环境
    useEffect(() => {
        const msgKey = 'network';
        const online = () => {
            // 网络恢复
            messageApi.destroy(msgKey);
        };
        const offline = () => {
            messageApi.open({
                key: msgKey,
                type: 'error',
                content: intl.get('App.NetworkAbnormal').d('网络异常，请检查您的网络情况 . . .'),
                duration: 0,
            });
        };

        window.addEventListener('online', online);
        window.addEventListener('offline', offline);

        return () => {
            window.removeEventListener('online', online);
            window.removeEventListener('offline', offline);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getLayout =
        Component.getLayout ??
        ((page) => {
            return iframePreview === 'true' ? (
                <div style={{ background: '#F7F8FB', padding: '16px' }}>{page}</div>
            ) : (
                <Layout>{page}</Layout>
            );
        });

    return (
        <Provider store={store}>
            <ConfigProvider locale={zhCN} theme={theme} autoInsertSpaceInButton={false}>
                <CookiesProvider>
                    <AntdApp>
                        {contextHolder}
                        {getLayout(<Component {...pageProps} />)}
                    </AntdApp>
                </CookiesProvider>
            </ConfigProvider>
        </Provider>
    );
};

// 初始化多语言
App.getInitialProps = async ({ Component, ctx }: any) => {
    let pageProps: Record<string, any> = {};
    // 获取cookies中的语言
    const cookieLang = ctx.locale || 'zh-CN';
    try {
        const langJson = mtsLangMap[cookieLang];

        const localeData = (await getMtsLanguage({ lang: langJson })) || {};
        const commonData = (await getMtsLanguage({ lang: langJson, url: 'hawk_common' })) || {};

        pageProps = Component.getInitialProps ? await Component.getInitialProps(ctx) : {};
        pageProps = { ...pageProps, lang: cookieLang, localeData: { ...localeData, ...commonData } };
    } catch (error) {
        pageProps = { ...pageProps, lang: cookieLang, localeData: {} };
    }

    return {
        pageProps,
    };
};

export default App;
