import React from 'react';
import { Form, Input } from 'antd';
import c from 'classnames';
import intl from 'react-intl-universal';
import type { SearchInputProps } from '../types';
import styles from './index.module.less';

const SearchInput: React.FC<SearchInputProps> = (props) => {
    const { form, search, inpVal, isFocus, inputRef, onFocus, onGoBack, onPressEnter, onInputChange } = props || {};

    return (
        <div className={c(styles.search_contain, search || isFocus ? styles.search_focus : null)}>
            <Form form={form} className={styles.form}>
                <Form.Item noStyle name="searchVal">
                    <Input
                        ref={inputRef}
                        autoComplete="off"
                        prefix={<span className={c(styles.search_icon, 'iconfont', 'icon-sousuo')} />}
                        suffix={
                            isFocus ? (
                                <span onClick={onGoBack} className={c(styles.close_icon, 'iconfont', 'icon-shanchu')} />
                            ) : null
                        }
                        className={c(
                            styles.search_input,
                            inpVal ? styles.search_has : null,
                            isFocus && !inpVal ? styles.search_color : null,
                        )}
                        placeholder={intl.get('Header.HeaderSearch').d('请输入')}
                        onChange={onInputChange}
                        onPressEnter={onPressEnter}
                        onFocus={onFocus}
                    />
                </Form.Item>
            </Form>
        </div>
    );
};

export default SearchInput;
