import React, { useState, useEffect, useRef, useMemo } from 'react';
import c from 'classnames';
import Masonry from 'react-masonry-css';
import RenderItem from './renderItem';
import { resizeObserverFun, PAGE_CENTER_WA, PAGE_CENTER_WC, PAGE_CENTER_WB } from '@/utils';
import styles from './index.module.less';

interface Props {
    options: any[];
}

const ArticleWaterfall: React.FC<Props> = (props) => {
    const { options = [] } = props;

    const domRef = useRef<any>(null);
    const [domtWidth, setDomWidth] = useState<number>(PAGE_CENTER_WA);
    useEffect(() => {
        resizeObserverFun(domRef.current, setDomWidth);
    }, []);

    const masonryClass = useMemo(() => {
        let result;
        if (domtWidth > PAGE_CENTER_WA) {
            result = styles.listFour;
        } else if (domtWidth > PAGE_CENTER_WB) {
            result = styles.listThree;
        } else if (domtWidth > PAGE_CENTER_WC) {
            result = styles.listTwo;
        } else {
            result = styles.listOne;
        }
        return result;
    }, [domtWidth]);

    const breakpointCols = useMemo(() => {
        let result;
        if (domtWidth > PAGE_CENTER_WA) {
            result = 4;
        } else if (domtWidth > PAGE_CENTER_WB) {
            result = 3;
        } else if (domtWidth > PAGE_CENTER_WC) {
            result = 2;
        } else {
            result = 1;
        }
        return result;
    }, [domtWidth]);

    return (
        <div className={styles.articleContain} ref={domRef}>
            <Masonry
                className={c(styles.articleList, masonryClass, 'article-list')}
                breakpointCols={{
                    default: breakpointCols,
                }}
            >
                {options.map((item: any) => {
                    const { pk, category, thumbnail_pics = [] } = item || {};
                    const thumbnail = thumbnail_pics[0] || {};

                    return (
                        <div
                            key={pk}
                            className={c(
                                styles.articleItem,
                                thumbnail.url ? styles.typeOne : null,
                                category === 'video' ? styles.typeTwo : null,
                            )}
                        >
                            <RenderItem item={item} />
                        </div>
                    );
                })}
            </Masonry>
        </div>
    );
};

export default ArticleWaterfall;
