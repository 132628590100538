import React, { useState, useMemo, useEffect, useRef } from 'react';
import { resizeObserverFun } from '@/utils';
import { Button, ConfigProvider } from 'antd';
import { NextImage } from '@/components';
import { formatBrokerBusiness } from '@/utils';
import intl from 'react-intl-universal';
import c from 'classnames';
import type { BrokerItemCProps } from '../../type';
import styles from './index.module.less';

const BrokerItem: React.FC<BrokerItemCProps> = ({
    record,
    locale,
    moreText,
    accountUrl,
    showAll,
    setShowAll,
    onClickItem,
    onOpenAccount,
    onCompared,
    showComparedBtn = false,
    showSlogan = false,
    showExclusiveActivities = true,
}) => {
    const {
        logo,
        age,
        name_abbreviation,
        broker_other_info,
        business_name,
        score,
        license = [],
        exclusive_activities,
        security_slogan,
        broker_describe,
    } = record;

    const nromalHeight = 120;

    const [domHeight, setDomHeight] = useState<number>(0); // 基本信息容器高度
    const domRef = useRef<any>(null);

    useEffect(() => {
        // 获取容器高度
        resizeObserverFun(domRef.current, setDomHeight, 'clientHeight');
    }, []);

    /** 专属活动 */
    const exclusiveActivities = useMemo(() => {
        if (!showExclusiveActivities) {
            return '';
        }
        return (
            exclusive_activities || // 默认
            broker_describe?.exclusive_activities || // 外汇
            broker_other_info?.description?.exclusive_activities || // 证券
            ''
        );
    }, [exclusive_activities, broker_other_info]);

    /** slogan */
    const slogan = useMemo(() => {
        return security_slogan || broker_describe?.slogan || '';
    }, [exclusive_activities, broker_other_info]);

    /**
     * 展开收起 按钮
     */
    const showBtn = useMemo(() => {
        return (
            <div className={c(styles.wrapper_more, { [styles.show_all]: showAll })}>
                <div
                    className={styles.more}
                    onClick={() => {
                        setShowAll(!showAll);
                    }}
                >
                    <div className={styles.text_more}>{moreText}</div>
                    <span
                        className={c(styles.arrow_more, 'iconfont', 'icon-jiantou', {
                            [styles.arrow_more_t]: showAll,
                        })}
                    />
                </div>
            </div>
        );
    }, [moreText, showAll]);

    // 是否展示 展开按钮
    const isShowMoreBtn = useMemo(() => domHeight > nromalHeight, [domHeight]);
    const minHeight = domHeight > nromalHeight ? nromalHeight : domHeight;

    return (
        <div className={styles.li}>
            <div className={c(styles.li_top, { [styles.li_top_margin]: !!exclusiveActivities })} onClick={onClickItem}>
                <div className={styles.logo}>
                    <NextImage src={logo} layout="fill" alt={name_abbreviation} place="new_normal" />
                </div>
                <div className={styles.info}>
                    <div className={styles.info_top}>
                        <div className={styles.name_box}>
                            <span className={styles.name}>{name_abbreviation}</span>
                            {accountUrl && (
                                <ConfigProvider
                                    theme={{
                                        token: { colorPrimary: '#2C36DE' },
                                    }}
                                >
                                    <Button
                                        className={styles.open_account}
                                        onClick={(e: any) => onOpenAccount(e, accountUrl)}
                                    >
                                        {intl.get('Broker.OpenAnAccount').d('开户')}
                                    </Button>
                                </ConfigProvider>
                            )}
                            {showComparedBtn && (
                                <Button
                                    className={styles.compared}
                                    onClick={(e: any) => {
                                        e?.preventDefault();
                                        e?.stopPropagation();
                                        e?.nativeEvent.stopImmediatePropagation();
                                        onCompared?.(record);
                                    }}
                                >
                                    {intl.get('Broker.Compared1').d('对比')}
                                </Button>
                            )}
                        </div>

                        <p className={styles.score}>
                            <span>
                                <strong>{Number(score || 0).toFixed(1)}</strong>
                            </span>
                            <span>{intl.get('Broker.Score').d('评分')}</span>
                        </p>
                    </div>

                    <div className={styles.tag_list}>
                        <span>{formatBrokerBusiness(business_name, locale)}</span>
                        <span>{age}</span>
                        <span className={styles.license}>{license?.join?.('  |  ') || ''}</span>
                    </div>
                </div>
            </div>
            {showSlogan && slogan && <div className={styles.slogan}>{slogan}</div>}

            {exclusiveActivities && (
                <div className={styles.wrapper_main} style={{ height: showAll ? domHeight + 42 : minHeight }}>
                    <div
                        ref={domRef}
                        className={styles.main_con}
                        dangerouslySetInnerHTML={{ __html: exclusiveActivities || '' }}
                    />

                    {!showAll && isShowMoreBtn && <div className={styles.text_mask} />}

                    {showAll && showBtn}
                </div>
            )}

            {exclusiveActivities && !showAll && isShowMoreBtn && showBtn}
        </div>
    );
};

export default BrokerItem;
