import React from 'react';
import { formatMts } from '@/utils/mts';
import styles from './index.module.less';

interface Props {
    content: string;
    mtsContent?: {
        zh_cns?: string;
        en_us?: string;
    };
}

const IcpMsg: React.FC<Props> = ({ content, mtsContent = {} }) => {
    return <div className={styles.icpMsg} dangerouslySetInnerHTML={{ __html: formatMts(mtsContent) || content }} />;
};

export default IcpMsg;
