import React, { useState, useEffect } from 'react';
import { App } from 'antd';
import c from 'classnames';
import { NextImage } from '@/components';
import { focusAddPost, focusDelete } from '@/services/focus';
import cloneDeep from 'lodash/cloneDeep';
import styles from './index.module.less';
import intl from 'react-intl-universal';
import { currentLocaleUrl } from '@/utils';

interface Props {
    keyword?: string;
    options: any[];
}

const SearchUgcList: React.FC<Props> = (props) => {
    const { keyword, options = [] } = props;

    const [newList, setNewList] = useState<any[]>([]);

    useEffect(() => {
        setNewList(options);
    }, [options]);

    const { message } = App.useApp();
    /**
     * 关注/取消关注
     */
    const onFocusUgc = (event: any, ugc: any, index: number) => {
        event?.preventDefault();
        const { id: ugcId, is_focus } = ugc;
        if (ugcId) {
            const fun = is_focus === 1 ? focusDelete : focusAddPost;
            fun(ugcId).then((res: any) => {
                if (res?.state === 1) {
                    message.success(
                        is_focus === 1
                            ? intl.get('PersonalCenter.UnfollowSuc').d('取消关注成功！')
                            : intl.get('Article.FollowedSuc').d('关注成功！'),
                    );
                    const list = cloneDeep(newList);
                    list.splice(index, 1, { ...ugc, is_focus: 1 - is_focus });
                    setNewList(list);
                }
            });
        }
    };

    // 搜索替换
    const replaceTitle = (tit: string, val?: string) => {
        return val ? (tit || '').replace(new RegExp('' + val + '', 'g'), `<span>${val}</span>`) : tit;
    };

    // 关注按钮
    const focusBtn = (ugc: any, index: number) => {
        return (
            <div
                className={c(styles.ugcBtn, ugc?.is_focus === 1 ? styles.isFocus : styles.noFocus)}
                onClick={(e) => {
                    onFocusUgc(e, ugc, index);
                }}
            >
                <span>
                    {ugc?.is_focus === 1
                        ? intl.get('Article.AlreadyFollowed').d('已关注')
                        : intl.get('Article.Followed').d('关注')}
                </span>
                <span>{intl.get('Article.CancelFollowed').d('取消关注')}</span>
            </div>
        );
    };

    return (
        <>
            {[...newList].map((ugc: any, index: number) => (
                <a href={currentLocaleUrl(`/ugc/${ugc?.id}`)} target="_blank" key={ugc?.id}>
                    <div className={c(styles.ugcWrapper, index === 0 ? styles.ugcWrapperFirst : null)}>
                        <div className={styles.ugcImg}>
                            <NextImage src={ugc?.avatar} layout="fill" alt={ugc?.name} place="ugc" />
                        </div>

                        <div className={styles.ugcContent}>
                            <div className={styles.ugcMain}>
                                <div className={styles.ugcTop}>
                                    <div className={styles.ugcTit}>
                                        <h2
                                            className={styles.ugcTitle}
                                            dangerouslySetInnerHTML={{ __html: replaceTitle(ugc?.name || '', keyword) }}
                                        />
                                        <div className={styles.ugcPublishNum}>
                                            {intl
                                                .get('Ugc.CoPublishedArticles', { field: ugc.news_number })
                                                .d(`共发表${ugc.news_number}篇文章`)}
                                        </div>
                                    </div>
                                    {focusBtn(ugc, index)}
                                </div>

                                <div className={styles.ugcIntroduce}>{ugc.introduction}</div>
                            </div>
                        </div>
                    </div>
                </a>
            ))}
        </>
    );
};

export default SearchUgcList;
