import request from '@/utils/axios';

// ------------- 可视化

// 可视化页面查询
export const getVisualPage = (str: string, locale?: string) => {
    return request.get(`/japi/assembly/${str}`, {}, { locale });
};

// 全局基础配置
export const getBaseConfig = () => {
    return request.get(`/manage/config/pc_config`);
};
