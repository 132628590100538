import React, { useMemo, useCallback } from 'react';
import intl from 'react-intl-universal';

import c from 'classnames';
import { Timeline } from 'antd';
import dayjs from 'dayjs';
import Share from '@/reconstruction/components/public/FlashShare';

import { formaTimerLetter, getHtmlPlainText, sourceUrlHandler, currentLocaleUrl, filterSpace } from '@/utils';

import styles from './list.module.less';
import classNames from 'classnames';

interface Props {
    options: any[];
    isSearch?: boolean;
}

const FlashNewsList: React.FC<Props> = (props) => {
    const { options = [], isSearch = false } = props;

    const newOptions = useMemo(() => {
        return formaTimerLetter([...options]) || {};
    }, [options]);

    return (
        <div className={styles.flashNewsContain}>
            {Object.keys(newOptions).map((date: any) => {
                const dateTimer = Number(date);
                const yearDate = dayjs(dateTimer).format('YYYY-MM-DD');
                const isTodayDate = dayjs(yearDate).isToday();

                const newlist = newOptions[date] || [];

                return (
                    <div key={date} className={styles.flashNewsList}>
                        {/* 日信息日期展示 */}
                        {!isTodayDate && newlist[0] ? (
                            <div className={styles.flashNewsDate}>
                                <em className={styles.flashNewsDate_icon} />
                                {yearDate}
                            </div>
                        ) : null}

                        {/* 日快讯信息  */}
                        <div className={styles.flashNewsListContent}>
                            <Timeline>
                                {[...newlist].map((item: any, itemIndex: number) => {
                                    const {
                                        pk,
                                        code,
                                        seo_url,
                                        title = '',
                                        datetime,
                                        source_url,
                                        introduction = '',
                                        source_id,
                                        source_code,
                                        thumbnail_pics = [],
                                    } = item;

                                    const href = `/article/${seo_url || code}`;
                                    const timer = datetime ? dayjs(datetime).format('HH:mm') : '';
                                    const cover = thumbnail_pics[0]?.url;
                                    const introdu = filterSpace(getHtmlPlainText(introduction));

                                    const sourceLink = sourceUrlHandler(source_code, source_id, source_url);

                                    // const isAct = itemIndex === 0;

                                    return (
                                        // 当前时间段快讯信息
                                        <Timeline.Item
                                            key={pk}
                                            className={classNames(
                                                styles.timeline_item_box,
                                                styles.timeline_item_box_act,
                                            )}
                                        >
                                            <div key={pk} className={styles.flashNewsItem}>
                                                {/* 快讯日期时分秒 */}
                                                <div className={styles.flashNewsTimer}>
                                                    <span>{timer}</span>
                                                    <div className={styles.flashNewsIcon}>
                                                        <Share
                                                            type="twitter"
                                                            size={16}
                                                            title={title}
                                                            // url={href}
                                                            cover={cover}
                                                            style={{ marginRight: 8 }}
                                                        />
                                                        <Share
                                                            type="facebook"
                                                            size={16}
                                                            title={title}
                                                            // url={href}
                                                            cover={cover}
                                                        />
                                                    </div>
                                                </div>

                                                <div className={styles.flashNewsCon}>
                                                    {/* 标题 */}
                                                    <a
                                                        className={styles.flashNewsTitle}
                                                        href={currentLocaleUrl(href)}
                                                        target="_blank"
                                                        dangerouslySetInnerHTML={{
                                                            __html: title,
                                                        }}
                                                    />

                                                    {/* 详情描述 */}
                                                    {introdu ? (
                                                        <div
                                                            className={styles.flashNewsIntrod}
                                                            dangerouslySetInnerHTML={{ __html: introdu }}
                                                        />
                                                    ) : null}

                                                    {/* 原文链接 */}
                                                    {!isSearch && (
                                                        <div className={styles.flashNewsMsg}>
                                                            <div
                                                                className={c(
                                                                    styles.sourceUrl,
                                                                    !sourceLink ? styles.sourceUrlNull : null,
                                                                )}
                                                            >
                                                                {sourceLink && (
                                                                    <a
                                                                        target="_blank"
                                                                        href={currentLocaleUrl(sourceLink)}
                                                                    >
                                                                        <i className={styles.sourceUrl_icon} />
                                                                        {intl
                                                                            .get('PersonalCenter.OriginalLink')
                                                                            .d('原文链接')}
                                                                    </a>
                                                                )}
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </Timeline.Item>
                                    );
                                })}
                            </Timeline>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default FlashNewsList;
