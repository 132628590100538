import React, { useRef, useMemo, useEffect, useState } from 'react';
import styles from './index.module.less';
import type { ArticlesListPros } from '../type';
import intl from 'react-intl-universal';
import { InfiniteScroll } from '@/reconstruction/components/public';
import { ArticleItem } from '@/reconstruction/components/listItem';
import { useAppSelector } from '@/store/hook';
import c from 'classnames';

const PCArticlesList: React.FC<ArticlesListPros> = (props) => {
    const { lineType, serviceData, extraParams, blockApi, listColumn = 4, type } = props;
    const { showBarkly } = useAppSelector((state: any) => {
        return state.config;
    });

    const domRef = useRef<any>(null);
    const listRef = useRef<any>(null);

    /** 是否为无限滚动 */
    const isInfinite = useMemo(() => lineType === 'infinite', [lineType]);

    /** 列表最大数 */
    const maxLen = useMemo(() => {
        switch (listColumn) {
            case 3:
                return 9;
            case 4:
            default:
                return 12;
        }
    }, [listColumn]);

    const [list, setList] = useState((serviceData?.list || []).slice(0, maxLen));

    useEffect(() => {
        setList((serviceData?.list || []).slice(0, maxLen));
    }, [serviceData]);

    /** 是否展示没有更多 */
    const showNoMore = useMemo(() => {
        if (isInfinite) {
            return false;
        }
        switch (maxLen) {
            case 9:
                return list.length < 7;
            case 12:
            default:
                return list.length < 9;
        }
    }, [list, maxLen]);

    /** 额外参数和接口修改后，重新获取数据 */
    useEffect(() => {
        listRef.current?.resetList();
    }, [extraParams, blockApi]);

    const transformData = (res: any) => {
        const { list = [], total = 0 } = res?.data || {};
        if (isInfinite) {
            setList(list);
            return {
                list: list,
                total: total,
            };
        }

        // 处理三行的情况
        setList(list.slice(0, maxLen));
        return {
            list: list.slice(0, maxLen),
            total: 0,
        };
    };

    return (
        <div
            className={c(
                styles.wrapper,
                { [styles.barkly_container]: showBarkly },
                { [styles.noInfinite]: !isInfinite },
            )}
            ref={domRef}
        >
            <InfiniteScroll
                serviceData={serviceData}
                transformData={transformData}
                showNoMoreContent={isInfinite}
                initialLoad={false}
                extraParams={extraParams || {}}
                api={blockApi}
                noDataHeight={isInfinite ? 300 : 1094}
                onRef={listRef}
                renderList={(list) => {
                    if (list.length === 0) {
                        return null;
                    }
                    return (
                        <div className={styles.wrapper__list}>
                            {list.slice(0, isInfinite ? list.length : maxLen).map((item: any) => {
                                return <ArticleItem key={item?.pk} item={item} type={type} listColumn={listColumn} />;
                            })}
                        </div>
                    );
                }}
            />
            {list.length !== 0 && showNoMore && (
                <div className={styles.wrapper__noMore}>
                    <em />
                    <span>{intl.get('Common.MoreLostContent').d('没有更多内容')}</span>
                    <em />
                </div>
            )}
        </div>
    );
};

export default PCArticlesList;
