import React from 'react';
import TransferCom from '@/components/public/TransferCom';
import MFooter from './m';
import PcFooter from './pc';

import { FooterProps } from './types';

const Footer: React.FC<FooterProps> = (props) => {
    return <TransferCom m={<MFooter {...props} />} ipad={<PcFooter {...props} />} pc={<PcFooter {...props} />} />;
};

export default Footer;
