import React, { useState, useMemo, useCallback } from 'react';
import { NextImage } from '@/components';
import SwiperCore, { Pagination, Autoplay, Controller, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import c from 'classnames';
import dayjs from 'dayjs';
import { currentLocaleUrl, formatDomUnit, formatMts, getVisualSkipPage } from '@/utils';
import styles from './index.module.less';
import intl from 'react-intl-universal';
import { AdvertiseProps } from '../type';

SwiperCore.use([Pagination, Autoplay, Controller, Navigation]);

const SecondAdvertise: React.FC<AdvertiseProps> = ({
    elementId,
    canClose,
    customStyle,
    interval = 5,
    swiperList = [],
}) => {
    const { width, widthUnit, height, heightUnit, ...adStyle } = customStyle || {};

    const [showAD, setShowAD] = useState<boolean>(true);

    /**
     * 格式化 swiper 数据
     */
    const swiperData = useMemo(() => {
        const newList = swiperList.filter((item) => {
            const { time } = item || {};
            if (time && time.length !== 0) {
                // 配置了时间
                // 不在时间内不显示图片
                if (dayjs().isAfter(dayjs(time[0]), 'seconds') && dayjs().isBefore(dayjs(time[1]), 'seconds')) {
                    return true;
                } else {
                    return false;
                }
            }
            return true;
        });

        return newList.map((item, index) => ({ ...item, openUrl: getVisualSkipPage(item?.openInfo), key: index }));
    }, [swiperList]);

    return (
        <div style={{ ...formatDomUnit(adStyle || {}), display: showAD && swiperData.length !== 0 ? 'block' : 'none' }}>
            <div
                className={styles.swiperContain}
                style={{
                    width: `${width}${widthUnit}`,
                    height: `${height}${heightUnit}`,
                }}
            >
                <div className={c(styles.adTag, canClose ? styles.closePad : null)}>
                    <span>{intl.get('Common.Advertise').d('广告')}</span>
                    {canClose && (
                        <div
                            className={c(styles.closeIcon, 'iconfont', 'icon-fork')}
                            onClick={() => setShowAD(false)}
                        />
                    )}
                </div>
                <Swiper
                    loop={swiperData.length > 1}
                    observer
                    resizeObserver
                    autoplay={{
                        delay: (interval || 5) * 1000,
                        disableOnInteraction: false,
                    }}
                    grabCursor
                    className={styles.swiperCon}
                    pagination={
                        swiperData.length < 2
                            ? false
                            : {
                                  clickable: true,
                                  bulletClass: styles.swiperBullet,
                                  bulletActiveClass: styles.swiperBulletActive,
                              }
                    }
                    navigation={{
                        nextEl: `.swiper-${elementId} .swiper-button-next`,
                        prevEl: `.swiper-${elementId} .swiper-button-prev`,
                    }}
                >
                    {swiperData.map((item: any, i: number) => {
                        const swiperItem = (
                            <div className={styles.swiperSlideBox} title={item?.title || ''}>
                                <NextImage src={item?.cover} layout="fill" alt={item?.title} priority={i === 0} />
                                {item.title ? (
                                    <div className={styles.swiperSlideTitle}>
                                        <div
                                            className={c(
                                                styles.swiperSlideTitleText,
                                                swiperData.length < 2 ? styles.swiperSlideTitleOne : null,
                                            )}
                                        >
                                            {formatMts(item.titleMts) || item.title}
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                        );
                        return (
                            <SwiperSlide className={c(styles.swiperSlide, styles.typeOne)} key={item.key}>
                                {item.openUrl ? (
                                    <a href={currentLocaleUrl(item.openUrl)} target="_blank">
                                        {swiperItem}
                                    </a>
                                ) : (
                                    swiperItem
                                )}
                            </SwiperSlide>
                        );
                    })}

                    <div style={{ opacity: swiperData.length > 1 ? 1 : 0 }} className={`swiper-${elementId}`}>
                        <div className={c(styles.swiperBtnNext, 'swiper-button-next')} />
                        <div className={c(styles.swiperBtnPrev, 'swiper-button-prev')} />
                    </div>
                </Swiper>
            </div>
        </div>
    );
};

export default SecondAdvertise;
