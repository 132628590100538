import React, { useEffect, useRef, useState } from 'react';
import NextImage from '../NextImage';
import { useAppSelector } from '@/store/hook';
import styles from './index.module.less';
import c from 'classnames';

interface Props {
    list: any[]
    onTabChange: (record: any) => void
    titleKey?: string
    defaultActiveTab?: number
}

const BrokerListTabs: React.FC<Props> = ({ list, onTabChange, titleKey = 'name', defaultActiveTab }) => {
    const { imgPrefix } = useAppSelector((state: any) => { return state.config; })

    const [activeTab, setActiveTab] = useState<number>(0);
    const [isShow, setIsShow] = useState<boolean>(false);
    const showSwitchRef = useRef<any>(null);
    const containerRef = useRef<any>(null);
    const [isLastLineFeed, setIsLastLineFeed] = useState<boolean>(false);

    useEffect(() => {
        const defaultActiveIndex = list.findIndex(item => {
            return item.id === defaultActiveTab;
        });
        setActiveTab(defaultActiveIndex >= 0 ? defaultActiveIndex : 0)
    }, [list, defaultActiveTab])

    const clickHandler = (record: any, index: number) => {
        setActiveTab(index);
        onTabChange(record);
    }

    const showSwitchHander = () => {
        setIsShow(!isShow);
    }

    useEffect(() => {
        const handleResize = () => {
            try {
                if (containerRef.current) {
                    const children = Array.from(containerRef.current.children);
                    const lastChild = children[children.length - 1];

                    if (lastChild) {
                        const lastChildRect = (lastChild as any).getBoundingClientRect();
                        const lastChildBottom = lastChildRect.bottom;
                        const containerBottom = containerRef.current.getBoundingClientRect().bottom;

                        setIsLastLineFeed(lastChildBottom > containerBottom);
                    }
                }
            } catch (e) {
                console.error(e);
            }
        };

        window.addEventListener("resize", handleResize);
        handleResize();

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const moreIcon = isShow ? 'up_more' : 'down_more'

    return (
        <div ref={containerRef} className={c(styles.tabs__wrapper, { [styles.overflow__hiding__one]: !isShow })}>
            {
                isLastLineFeed &&
                <em ref={showSwitchRef} onClick={showSwitchHander} className={styles.operate} >
                    <NextImage
                        src={`/broker/icon/${moreIcon}.webp`}
                        needPrefix
                        alt={moreIcon}
                        width={18}
                        height={20}
                    />
                </em>
            }
            {
                list.map((item: any, index: number) => (
                    <span
                        onClick={() => { clickHandler(item, index); }}
                        className={c(styles.tabs__item, { [styles.active]: activeTab === index })}
                        style={activeTab === index ? { backgroundImage: `url('${imgPrefix}/broker/icon/bg-line.webp')` } : {}}
                        key={item.id}>
                        {item[titleKey]}
                    </span>
                ))
            }
        </div>
    );
}

export default BrokerListTabs
