import React, { useEffect, useState, useRef } from 'react';
import { useRouter } from 'next/router';
import { NextImage, HeaderMarket } from '@/components';
import { getFeedbackUnreadAsync, getUserInfoAsync, setTopHeaderHeight, setTopHeaderBoxHeight } from '@/store/config';
import { useAppDispatch, useAppSelector } from '@/store/hook';
import { IsAndroid, currentLocaleUrl, resizeObserverFun } from '@/utils';
import formUtils from '@/utils/form';
import intl from 'react-intl-universal';
import c from 'classnames';
import { HeaderMenu, HeaderNav, MtsLang, ChildNav } from '../components';
import type { HeaderProps } from '../types';
import styles from './index.module.less';

const { pleaseEnter } = formUtils;

const Header: React.FC<HeaderProps> = (props) => {
    const noNavList = ['/search', '/broker/[brokerId]', '/center', '/center/modify'];
    const { menus, logo = '', isApp = false, download = {}, topBanner = {}, showTopTW, topTWCode } = props;
    const router = useRouter();
    const { pathname, locale } = router;

    const dispatch = useAppDispatch();
    const { showBarkly } = useAppSelector((state: any) => state.config);

    // ------------- 头部整体高度
    const headerRef = useRef<any>(null);
    const [mHeaderHeight, setMHeaderHeight] = useState<number>(45);
    useEffect(() => {
        resizeObserverFun(headerRef.current, setMHeaderHeight, 'clientHeight');
    }, []);

    // ------------- 头部header_box高度
    const headerBoxRef = useRef<any>(null);
    const [mHeaderBoxHeight, setMHeaderBoxHeight] = useState<number>(45);
    useEffect(() => {
        resizeObserverFun(headerBoxRef.current, setMHeaderBoxHeight, 'clientHeight');
    }, []);

    // ------------- search
    const [hideNav] = useState<boolean>(noNavList.includes(pathname));

    // ------------- app下载
    const [downloadOpen, setDownloadOpen] = useState<boolean>(false);
    const [downloadLink, setDownloadLink] = useState<string>('');

    const downloadList = [
        {
            text: 'Hawk Insight Google Play',
            icon: 'icon-google',
            link: '/app',
        },
        {
            text: 'Hawk Insight APK',
            icon: 'icon-android',
            link: download.apk_url || '',
        },
    ];

    useEffect(() => {
        dispatch(setTopHeaderHeight(mHeaderHeight || 45));
    }, [mHeaderHeight]);

    useEffect(() => {
        dispatch(setTopHeaderBoxHeight(mHeaderBoxHeight));
    }, [mHeaderBoxHeight]);

    useEffect(() => {
        if (localStorage.getItem('Authorization')) {
            dispatch(getUserInfoAsync());
            dispatch(getFeedbackUnreadAsync());
        }
    }, [dispatch]);

    const routerGo = (path: string) => {
        router.push(path);
    };

    /**
     * 头图加载完成，设置顶部高度
     * @param e
     */
    const onTopBannerLoad = (e: any) => {
        dispatch(setTopHeaderHeight((mHeaderHeight || 45) + e.target.clientHeight));
    };

    /**
     * 下载
     */
    const downloadClickHandler = () => {
        const is_android = IsAndroid();
        if (is_android) {
            setDownloadOpen(true);
        } else {
            routerGo('/app');
        }
    };
    const downloadConfirmHandler = () => {
        if (downloadLink) {
            router.push(downloadLink);
        }
        setDownloadOpen(false);
    };

    return (
        <header
            id="web-header"
            className={c(styles.header, { [styles.app_header]: isApp, [styles.barkly_container]: showBarkly })}
            ref={headerRef}
        >
            {topBanner.visible && topBanner.url && (
                <div className={styles.top_banner}>
                    <NextImage src={topBanner.url} layout="responsive" alt="banner" onLoad={onTopBannerLoad} />
                </div>
            )}

            <div className={styles.header_box} ref={headerBoxRef}>
                <div className={styles.header_left}>
                    {logo && (
                        <h1 className={styles.logo}>
                            <a href={currentLocaleUrl('/')}>
                                <NextImage src={logo} layout="fill" alt="HawkInsight" />
                            </a>
                        </h1>
                    )}
                </div>

                <div className={c(styles.header_right)}>
                    <MtsLang locale={locale} />

                    <span
                        onClick={() => {
                            routerGo('/search');
                        }}
                        className={c(styles.search__icon, 'iconfont', 'icon-sousuo')}
                    />

                    <HeaderMenu />
                </div>
            </div>

            {showTopTW && topTWCode && <HeaderMarket elementId="headerMarket" code={topTWCode} />}

            <HeaderNav menus={menus} hideNav={hideNav} />

            <div className={styles.download__app} onClick={downloadClickHandler}>
                <span>{intl.get('Header.OpenAppMsg').d('打开App，看你兴趣的资讯')}</span>
            </div>

            <div className={c(styles.shade, { [styles.open]: downloadOpen })} />

            <div className={c(styles.download__popup, { [styles.open]: downloadOpen })}>
                <div className={styles.main__wrapper}>
                    <div className={styles.download__title}>
                        {pleaseEnter(intl.get('Header.DownloadMethod').d('请选择下载方式'))}
                    </div>
                    <div className={styles.download__button}>
                        {downloadList.map((item: any) => (
                            <div
                                className={styles.button__wrapper}
                                onClick={() => {
                                    setDownloadLink(item.link);
                                }}
                                key={`${item.icon}_${item.text}`}
                            >
                                <div className={styles.picture}>
                                    <NextImage
                                        src={`/download/${item.icon}.webp`}
                                        needPrefix
                                        alt="empty"
                                        width={32}
                                        height={32}
                                    />
                                </div>

                                <div className={styles.button__text}>{item.text}</div>
                            </div>
                        ))}
                    </div>
                    <div className={styles.download__option}>
                        <div
                            className={styles.option__close}
                            onClick={() => {
                                setDownloadOpen(false);
                            }}
                        >
                            {intl.get('Common.Cancel').d('取消')}
                        </div>
                        <div className={styles.option__confirm} onClick={downloadConfirmHandler}>
                            {intl.get('Common.Determine').d('确定')}
                        </div>
                    </div>
                </div>
            </div>

            <ChildNav menus={menus} hideNav={hideNav} />
        </header>
    );
};

export default Header;
