import React from 'react';
import c from 'classnames';

import { NextImage } from '@/components';
import { currentLocaleUrl, newFormatTimer } from '@/utils';
import type { Iprops } from '../type';

import styles from './index.module.less';
import { useAppSelector } from '@/store/hook';

const PCArticleItem = ({ item, listColumn, className = '' }: Iprops) => {
    const { code, title, datetime, rule_name, ugc, thumbnail_pics = [], seo_url, is_topping } = item || {};
    const { showBarkly } = useAppSelector((state: any) => {
        return state.config;
    });

    const thumbnail = thumbnail_pics[0] || {};
    const timer = newFormatTimer(datetime);

    return (
        <a
            href={currentLocaleUrl(`/article/${seo_url || code}`)}
            target="_blank"
            className={c(
                styles.wrapper__article,
                {
                    [styles.wrapper__noNewsFlash]: listColumn === 3,
                    [styles.barkly_container]: showBarkly,
                },
                className,
            )}
        >
            <div className={styles.wrapper__cover}>
                {item.category === 'audio' && (
                    <span className={styles.audio__btn}>
                        <NextImage src="/img/audio/article-cover.webp" width={14} height={14} alt="audio" />
                    </span>
                )}
                {item.category === 'video' && (
                    <div className={styles.video_play_btn}>
                        <NextImage src="/img/video/play.webp" width={40} height={40} alt="play" />
                    </div>
                )}
                {/* 资讯图片 */}
                <NextImage
                    src={thumbnail.url}
                    className={styles.thumbnail}
                    place="new_normal"
                    layout="fill"
                    objectFit="cover"
                    alt={title}
                />
            </div>

            {/* 文章标题 */}
            <div className={styles.wrapper__title}>
                {is_topping === 1 ? (
                    <span className={styles.top}>
                        <NextImage
                            src="/icon/top-article.webp"
                            layout="fill"
                            needPrefix
                            objectFit="contain"
                            alt="top"
                        />
                    </span>
                ) : null}

                <h2>{title}</h2>
            </div>

            {/* 底部作者时间 */}
            <div className={styles.wrapper__info}>
                {ugc?.name || rule_name ? <span className={styles.wrapper__ugc}>{ugc?.name || rule_name}</span> : null}
                {timer && (
                    <>
                        <em className={styles.wrapper__line} />
                        <span className={styles.wrapper__timer}>{timer}</span>
                    </>
                )}
            </div>
        </a>
    );
};

export default PCArticleItem;
