import React, { useRef, useMemo, useEffect } from 'react';
import c from 'classnames';
import styles from './index.module.less';
import type { ArticlesListPros } from '../type';
import { InfiniteScroll } from '@/reconstruction/components/public';
import { ArticleItem } from '@/reconstruction/components/listItem';
import { useAppSelector } from '@/store/hook';

const H5ArticlesList: React.FC<ArticlesListPros> = (props) => {
    const { lineType, serviceData, extraParams, blockApi, listColumn = 4 } = props;
    const { showBarkly } = useAppSelector((state: any) => {
        return state.config;
    });
    const domRef = useRef<any>(null);
    const listRef = useRef<any>(null);

    /** 是否为无限滚动 */
    const isInfinite = useMemo(() => lineType === 'infinite', [lineType]);

    /** 是否右布局 */
    const rightLayout = useMemo(() => {
        return listColumn === 3;
    }, [listColumn]);

    /** 额外参数和接口修改后，重新获取数据 */
    useEffect(() => {
        listRef.current?.resetList();
    }, [extraParams, blockApi]);

    const transformData = (res: any) => {
        const { list = [], total = 0 } = res?.data || {};
        if (isInfinite) {
            return {
                list: list,
                total: total,
            };
        }

        // 处理三行的情况
        return {
            list: list.slice(0, 9),
            total: 0,
        };
    };

    return (
        <div className={styles.articleContain} ref={domRef}>
            <InfiniteScroll
                serviceData={serviceData}
                transformData={transformData}
                showNoMoreContent={isInfinite}
                initialLoad={false}
                extraParams={extraParams || {}}
                api={blockApi}
                onRef={listRef}
                renderList={(list) => {
                    return (
                        <div
                            className={c(styles.articleList, styles.listOne, {
                                [styles.barkly_container]: showBarkly,
                            })}
                        >
                            {list.slice(0, isInfinite ? list.length : 9).map((item: any) => {
                                const { pk } = item || {};

                                return <ArticleItem key={pk} rightLayout={rightLayout} item={item} />;
                            })}
                        </div>
                    );
                }}
            />
        </div>
    );
};

export default H5ArticlesList;
