import request from '@/utils/axios';

/**
 * 查询关注创作者列表
 * @param params
 * @returns
 */
export const focusListGet = (
    params: { page: number; pageSize: number; type: string },
    locale?: string,
    headers?: any,
) => {
    return request.get('/focus', params, { locale, headers });
};
/**
 * 查询关注专栏列表
 * @param params
 * @returns
 */
export const focusTopicListGet = (
    params: { page: number; pageSize: number; type: string },
    locale?: string,
    headers?: any,
) => {
    return request.get('/japi/topic/focus/list', params, { locale, headers });
};

/**
 * 关注
 * @param id
 * @returns
 */
export const focusAddPost = (id: any) => {
    return request.post(`/focus/${id}`);
};

/**
 * 取消关注
 * @param id
 * @returns
 */
export const focusDelete = (id: any) => {
    return request.delete(`/focus/${id}`);
};

/**
 * 专栏关注
 * @param id
 * @returns
 */
export const topicFocusAddPost = (id: any) => {
    return request.post(`/japi/topic/focus/${id}`);
};

/**
 * 专栏取消关注
 * @param id
 * @returns
 */
export const topicFocusDelete = (id: any) => {
    return request.delete(`/japi/topic/focus/${id}`);
};

/**
 * 我的关注专栏列表
 */
export const getMyAttentionTopic = (
    params: {
        /** 页数 */
        page: string | number;
        /** 页码 */
        pageSize: string | number;
    },
    locale?: string,
    headers?: any,
) => {
    return request.get('/japi/topic/new_topic_list', params, { locale, headers });
};

/**
 * 推荐专栏列表
 */
export const getRecommendTopic = (
    params: {
        page: string | number;
        pageSize: string | number;
        type: 'new' | 'hot' | 'all';
        topicId?: string | number;
    },
    locale?: string,
    headers?: any,
) => {
    return request.get('/japi/topic/recommended_column_list', params, { locale, headers });
};
