import intl from 'react-intl-universal';

class FormUtils {
    /**
     * @example
     * required() === '此为必填项'
     */
    required = (msg?: string) => ({
        required: true,
        message: msg ?? intl.get('Common.Required'), // 此为必填项
    });

    /**
     * @example
     * pleaseEnter('用户名') === '请输入用户名' // true
     */
    pleaseEnter = (field: string) => intl.get('Common.InputPlaceholder', { field }).d(`请输入${field}`);

    /**
     * @example
     * pleaseAgainEnter('用户名') === '请再次输入用户名' // true
     */
    pleaseAgainEnter = (field: string) => intl.get('Common.PleaseAgainEnter', { field }).d(`请再次输入${field}`);

    /**
     * @example
     * pleaseEnterInfo('用户名') === '请输入用户名信息' // true
     */
    pleaseEnterInfo = (field: string) => intl.get('Common.PleaseEnterInformation', { field }).d(`请输入${field}信息`);

    /**
     * @example
     *
     * pleaseEnterCorrect('用户名') === '请输入正确的用户名' // true
     */
    pleaseEnterCorrect = (field: string) => intl.get('Common.PleaseEnterCorrect', { field }).d(`请输入正确的${field}`);

    /**
     * @example
     *
     * pleaseSelect('用户名') === '请选择用户名' // true
     */
    pleaseSelect = (field: string) => intl.get('Common.PleaseSelectField', { field }).d(`请选择${field}`);
}

const formUtils = new FormUtils();

export default formUtils;
