import React from 'react';
import c from 'classnames';
import type { MainProps } from '../types';
import styles from './index.module.less';

const Main: React.FC<any> = (props: any) => {
    // isNew => 新的布局 1376       （1184）
    const { child } = props || {};

    return (
        <main className={c({ [styles.main]: true })}>
            <section className={styles.section}>{child}</section>
        </main>
    );
};

export default Main;
