import React from 'react';
import { Form, Input } from 'antd';
import intl from 'react-intl-universal';

import formUtils from '@/utils/form';
import { NextImage } from '@/components';

import styles from './index.module.less';

const { pleaseEnter, pleaseAgainEnter } = formUtils;

const { Password } = Input;

const BindAccount = () => {
    return (
        <div className={styles.mobile}>
            <div className={styles.formType}>{intl.get('BindAccount.SetPassword').d('设置密码')}</div>
            <Form.Item
                name="password"
                style={{ marginBottom: 40 }}
                rules={[
                    { required: true, message: pleaseEnter(intl.get('BindAccount.Password').d('请输入密码')) },
                    { type: 'string', max: 16, message: intl.get('Setting.PassSixteen').d('密码长度最多16位字符') },
                    { type: 'string', min: 8, message: intl.get('Setting.PassEight').d('密码长度最少8位字符') },
                ]}
            >
                <Password
                    autoComplete="new-password"
                    iconRender={(iconVisible) =>
                        iconVisible ? (
                            <NextImage
                                src="/img/login/pwd_active.webp"
                                width={22}
                                style={{ cursor: 'pointer' }}
                                height={22}
                                alt="pwd_active"
                            />
                        ) : (
                            <NextImage
                                style={{ cursor: 'pointer' }}
                                src="/img/login/pwd.webp"
                                width={22}
                                height={22}
                                alt="pwd"
                            />
                        )
                    }
                    placeholder={pleaseEnter(intl.get('BindAccount.Password').d('请输入密码'))}
                />
            </Form.Item>
            <Form.Item
                name="confirm"
                className={styles.botMargin}
                style={{ marginBottom: 40 }}
                rules={[
                    {
                        required: true,
                        message: pleaseAgainEnter(intl.get('BindAccount.Password').d('请再次输入密码')),
                    },
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                            if (!value || getFieldValue('password') === value) {
                                return Promise.resolve();
                            }
                            return Promise.reject(
                                new Error(intl.get('BindAccount.PswInconsistent').d('两次密码不一致')),
                            );
                        },
                    }),
                ]}
            >
                <Password
                    autoComplete="new-password"
                    iconRender={(iconVisible) =>
                        iconVisible ? (
                            <NextImage
                                src="/img/login/pwd_active.webp"
                                width={22}
                                style={{ cursor: 'pointer' }}
                                height={22}
                                alt="pwd_active"
                            />
                        ) : (
                            <NextImage
                                style={{ cursor: 'pointer' }}
                                src="/img/login/pwd.webp"
                                width={22}
                                height={22}
                                alt="pwd"
                            />
                        )
                    }
                    placeholder={pleaseAgainEnter(intl.get('BindAccount.Password').d('请再次输入密码'))}
                />
            </Form.Item>
        </div>
    );
};

export default BindAccount;
