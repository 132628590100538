import React from 'react';
import styles from './index.module.less';
import intl from 'react-intl-universal';
import c from 'classnames';

interface Props {
    /** 业务名 */
    name: string;
    /** 展示位置 */
    site: 'leftTop' | 'rightTop' | 'leftBottom' | 'rightBottom';
    /** 容器类名 */
    className?: string;
}

const BrokerBusinessTag: React.FC<Props> = ({ name = '', site = 'leftTop', className = '' }) => {
    const currentLocale = intl.getInitOptions().currentLocale;
    const num = currentLocale !== 'zh-CN' ? 8 : 4;

    return name ? (
        <div className={c(styles.wrapper, styles[site], className)} style={{}}>
            {name.substring(0, num)}
        </div>
    ) : (
        <React.Fragment />
    );
};

export default BrokerBusinessTag;
