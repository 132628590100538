import React from 'react';
import { NextImage } from '@/components';
import SwiperCore, { Pagination, Autoplay, Controller, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { formatMts } from '@/utils/mts';
import 'swiper/swiper-bundle.css';
import c from 'classnames';
import styles from './index.module.less';
import { currentLocaleUrl } from '@/utils';

interface SwiperProps {
    elementId: string | number;
    interval?: number;
    imgWidth: number;
    swiperData: any[];
}

SwiperCore.use([Pagination, Autoplay, Controller, Navigation]);

const SecondSwiper: React.FC<SwiperProps> = ({ elementId, interval = 3, swiperData = [] }) => {
    const swiperItem = (item: any, l: any[], i: number) => {
        return (
            <div className={styles.swiperSlideBox} title={item?.title || ''}>
                <NextImage src={item?.url} layout="fill" alt={item?.title} priority={i === 0} />
                {item.title ? (
                    <div className={c(styles.swiperSlideTitle, l.length < 2 ? styles.swiperSlideTitleOne : null)}>
                        <div className={styles.swiperSlideTitleText}>{formatMts(item.titleMts) || item.title}</div>
                    </div>
                ) : null}
            </div>
        );
    };

    return (
        <div className={styles.swiperContain}>
            <div className={styles.swiperLeft}>
                <Swiper
                    loop={swiperData[0].length > 1}
                    observer
                    resizeObserver
                    autoplay={{
                        delay: (interval || 3) * 1000,
                        disableOnInteraction: false,
                    }}
                    grabCursor
                    className={styles.swiperCon}
                    pagination={
                        swiperData[0].length < 2
                            ? false
                            : {
                                  clickable: true,
                                  bulletClass: styles.swiperBullet,
                                  bulletActiveClass: styles.swiperBulletActive,
                              }
                    }
                    navigation={{
                        prevEl: `.swiper-0-${elementId} .swiper-button-prev`,
                        nextEl: `.swiper-0-${elementId} .swiper-button-next`,
                    }}
                >
                    {swiperData[0].map((item: any, i: number) => {
                        return (
                            <SwiperSlide className={c(styles.swiperSlide, styles.typeOne)} key={item.key}>
                                {item.openUrl ? (
                                    <a href={currentLocaleUrl(item.openUrl)} target="_blank">
                                        {swiperItem(item, swiperData[0], i)}
                                    </a>
                                ) : (
                                    swiperItem(item, swiperData[0], i)
                                )}
                            </SwiperSlide>
                        );
                    })}

                    <div style={{ opacity: swiperData[0].length > 1 ? 1 : 0 }} className={`swiper-0-${elementId}`}>
                        <div className={c(styles.swiperBtnNext, 'swiper-button-next')} />
                        <div className={c(styles.swiperBtnPrev, 'swiper-button-prev')} />
                    </div>
                </Swiper>
            </div>

            <ul className={styles.swiperRight}>
                {swiperData[1].map((item: any) => {
                    const { key, list: sList } = item || {};
                    return (
                        <li key={key}>
                            <Swiper
                                loop={sList.length > 1}
                                observer
                                resizeObserver
                                autoplay={{
                                    delay: (interval || 3) * 1000,
                                    disableOnInteraction: false,
                                }}
                                grabCursor
                                className={styles.swiperCon}
                            >
                                {sList.map((sItem: any, i: number) => {
                                    return (
                                        <SwiperSlide className={c(styles.swiperSlide, styles.typeOne)} key={sItem.key}>
                                            {sItem.openUrl ? (
                                                <a href={currentLocaleUrl(sItem.openUrl)} target="_blank">
                                                    {swiperItem(sItem, sList, i)}
                                                </a>
                                            ) : (
                                                swiperItem(sItem, sList, i)
                                            )}
                                        </SwiperSlide>
                                    );
                                })}
                            </Swiper>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

export default SecondSwiper;
