import React from 'react';
import { Select } from 'antd';

import { NextImage } from '@/components';

import styles from './index.module.less';

interface Item {
    value: string | number;
    label: string;
    id?: string;
}

interface Props {
    /** 列表 */
    option: Item[];
    /** 变化事件 */
    onChange: (record: Item) => void;
    /** 占位符 */
    placeholder?: string;
    /** 宽度 */
    width?: number;
    /** 已选项 */
    selectedList?: string[] | number[];
}

const DropDownBox = (props: Props) => {
    const { option, onChange, placeholder, width, selectedList } = props;

    return (
        <Select
            mode="multiple"
            placeholder={placeholder}
            className={styles.dropDownBox}
            style={{ width }}
            defaultValue={['china']}
            options={option}
            value={selectedList}
            maxTagCount="responsive"
            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
            menuItemSelectedIcon={false}
            allowClear={false}
            popupClassName={styles.select__list}
            optionRender={(option) => {
                const { label, value, data } = option;
                const isActive = selectedList?.includes(value as never);
                return (
                    <li onClick={() => onChange(data as Item)} className={styles.select__list__item}>
                        {isActive ? (
                            <NextImage src="/icon/yigouxuan.webp" width={14} height={14} needPrefix />
                        ) : (
                            <span className={styles.frame} />
                        )}
                        <div className={styles.label}>{label}</div>
                    </li>
                );
            }}
        />
    );
};

DropDownBox.prototype = {
    option: {
        type: Array,
        default: () => [],
    },
    onChange: {
        type: Function,
        default: () => {},
    },
    placeholder: {
        type: String,
        default: '请选择',
    },
    width: {
        type: Number,
        default: 208,
    },
    selectedList: {
        type: Array,
        default: [],
    },
};

export default DropDownBox;
