import { calcDay } from '@/utils';
import dayjs from 'dayjs';

/** 时间显示格式 */
export const useActivityTime = (start: number | string, end: number | string) => {
    const date1 = dayjs(start);
    const date2 = dayjs(end);
    const diffYear = date2.year() - date1.year();
    const diffMonth = date2.month() - date1.month();
    const diffDay = date2.day() - date1.day();
    let endTimeRule = '';
    if (diffYear > 0) {
        endTimeRule = `YYYY/MM/DD`;
    }
    if (diffYear <= 0 && diffMonth > 0) {
        endTimeRule = `MM/DD`;
    }
    if (diffYear <= 0 && diffMonth <= 0 && diffDay > 0) {
        endTimeRule = `DD`;
    }
    const activityTime = `${dayjs(start).format(`YYYY/MM/DD`)}${endTimeRule ? '~' : ''}${
        endTimeRule ? dayjs(end).format(endTimeRule) : ''
    }`;

    return {
        /** 格式后的时间 */
        activityTime,
    };
};

/** 获取状态信息 */
export const useStateInfo = (startTime: any, endTime: any, city: string) => {
    let activityState = 0;
    const currentTime = Date.now();
    if (currentTime <= startTime) {
        activityState = 1;
    }
    if (currentTime >= startTime && currentTime < endTime) {
        activityState = 2;
    }
    if (currentTime >= endTime) {
        activityState = 3;
    }
    const region = city?.split?.('/')?.reverse()[0] || '';

    return {
        /** 当前状态 */
        activityState,
        /** 区域 */
        region,
        /** 倒计时前缀 */
        countdownPrefix: calcDay(currentTime, startTime),
    };
};
