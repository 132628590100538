import request from '@/utils/axios';

/**
 * 查询创作者列表
 * @param params
 */
export const getUgcList = (
    params: {
        page?: string | number;
        pageSize?: string | number;
    },
    extra?: any,
) => {
    return request.get('/ugc/list', params, extra);
};

/**
 * 查询创作者详情
 * @param params
 */
export const getUgc = (
    {
        uid = 0,
        ugcId,
    }: {
        uid?: string | number;
        ugcId: string | number;
    },
    locale?: string,
) => {
    return request.get('/ugc', { uid, ugcId }, { locale });
};

/** 获取创作者下的稿件列表 */
export const getUgcArticle = (
    params: { page: number; pageSize?: number | string; ugc_id: number },
    locale?: string,
) => {
    return request.get('/ugc/article/list', params, { locale });
};

/** 带稿件的创作者列表 */
export const getArticleUgc = (params: { page?: number; pageSize?: number | string }, locale?: string) => {
    return request.get('/ugc/pc/random/list', params, { locale });
};

/**
 * 我的关注创作者列表
 */
export const getMyAttentionUgc = (
    params: {
        /** 页数 */
        page: string | number;
        /** 页码 */
        pageSize: string | number;
    },
    locale?: string,
    headers?: any,
) => {
    return request.get('/japi/topic/new_ugc_list', params, { locale, headers });
};
/**
 * 推荐创作者列表
 */
export const getRecommendedUgc = (
    params: {
        /** 页数 */
        page: string | number;
        /** 页码 */
        pageSize: string | number;
    },
    locale?: string,
    headers?: any,
) => {
    return request.get('/japi/topic/recommended_ugc_list', params, { locale, headers });
};
/**
 * 专栏详情下的推荐创作者列表
 */
export const getTopicRecommendedUgc = (
    params: {
        /** 页数 */
        page?: string | number;
        /** 页码 */
        pageSize?: string | number;
    },
    locale?: string,
    headers?: any,
) => {
    return request.get('/japi/topic/get_recommended_ugc_list', params, { locale, headers });
};
