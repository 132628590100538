import request from '@/utils/axios';

// 获取顶部频道菜单
export const getMenuApi = () => {
    return request.get('/japi/navigation');
};

// 查询文章详情
export const getArticleDetail = (params: { code: string; seo_url: string; blockId?: number }, extra?: any) => {
    return request.get(`/japi/article`, params, extra);
};

/**
 * 文章关键词 / title 查询
 * @param params
 */
export const getSearchFun = (
    params: {
        tags?: string;
        title?: string;
        type?: string;
        page?: string | number;
        pageSize?: string | number;
    },
    locale?: string,
) => {
    return request.post('/japi/article/article/list', params, { locale });
};

// 猜你喜欢
export const getArticleLike = (pk: string, locale?: string) => {
    return request.get(`/japi/article/list/like/${pk}`, {}, { locale });
};

// 创作者文章推荐
export const getUgcArticleLike = (pk: string, locale?: string) => {
    return request.get(`/japi/article/ugc/list/${pk}`, {}, { locale });
};

// 快讯新闻
export const getFlashNews = (str?: string, locale?: string) => {
    return request.get(`/japi/article/quick/list${str || ''}`, {}, { locale });
};

// 最新活动
export const getNewActivitys = (locale?: string) => {
    return request.get('/japi/activity/recommend', {}, { locale });
};

// 剧集 更具ID查询 剧集数 和 是否停用
export const getVideosNum = ({ ids }: { ids: string }) => {
    return request.get(`/japi/article/blocks/video/count`, { ids });
};

// 视频剧集列表
export const getTvList = (data: { page: number; pageSize: number; app_id: number }) => {
    return request.get(`/japi/article/episode/list`, data);
};

// 头图
export const getTopBanner = () => {
    return request.get('/japi/component/pc_new_year_img');
};

// 获取顶部频道菜单
export const getQuickMenuApi = (locale?: string) => {
    return request.get('/japi/component/pc_quick_navigation', {}, { locale });
};

// 根据合集ID查询文章接口
export const getArticleList = (str: string, locale?: string) => {
    return request.get(`/japi/article/blocks/list${str}`, {}, { locale });
};

// 热榜新闻
export const getHotArticle = (data: { limitSize: number; blockIds: number[] }, locale?: string) => {
    return request.post(`/japi/article/hot`, data, { locale });
};

/** 查询活动详情 */
export const getActivity = (id: number | string, locale?: string) => {
    return request.get(`/japi/activity/select/${id}`, {}, { locale });
};
