import React, { useState, useMemo, useRef, memo, forwardRef, useImperativeHandle } from 'react';
import { Modal } from 'antd';
import styles from './index.module.less';
import intl from 'react-intl-universal';

const ModalContent = memo(
    forwardRef((_parmas: any, ref) => {
        const [open, setOpen] = useState<boolean>(false);
        const [modalProps, setModalProps] = useState<any>({});
        const [modalChildren, setModalChildren] = useState<React.ReactNode>();

        useImperativeHandle(
            ref,
            () => ({
                // 注入参数
                injectProps: (props: any) => {
                    const { children, open, ...resProps } = props;
                    setOpen(open);
                    setModalProps(resProps);
                    setModalChildren(children);
                },
                // 打开
                open: () => {
                    setOpen(true);
                },
                // 关闭
                close: () => {
                    setOpen(false);
                },
            }),
            [],
        );

        const onClose = () => {
            setOpen(false);
        };

        return (
            <Modal
                open={open}
                okText={intl.get('Common.Determine').d('确定')}
                cancelText={intl.get('Common.Cancel').d('取消')}
                width={400}
                closable={false}
                onCancel={onClose}
                okButtonProps={{ type: 'link' }}
                cancelButtonProps={{ type: 'link' }}
                className={styles.customModal}
                {...modalProps}
            >
                {modalChildren}
            </Modal>
        );
    }),
);

interface CustomModalProps {
    open: () => void;
    close: () => void;
    injectProps: (a: any) => void;
}

const CustomModal = () => {
    const modalRef = useRef<CustomModalProps>(null);

    const handle = useMemo(() => {
        return {
            confirm: (init: any) => {
                modalRef?.current?.injectProps({ ...init }); // 注入参数
            },
            setParmas: (parmas: any) => {
                modalRef?.current?.injectProps({ ...parmas });
            },
            open: () => {
                modalRef?.current?.open();
            },
            close: () => {
                modalRef?.current?.close();
            },
        };
    }, []);

    return [handle, <ModalContent key="modal" ref={modalRef} />] as const;
};

export default CustomModal;
