import type { TopicDataType } from '@/type/topic';
import request from '@/utils/axios';

/**
 *  获取专题详情
 */
export const getTopicsDetails = (
    params: {
        /**
         * 专题id
         */
        pk: string | number;
        /** 是否pc 1-是*/
        is_pc: number;
    },
    locale?: string,
    headers?: any,
) => {
    return request.get('/japi/topic/item_list', params, { locale, headers });
};

/**
 *  获取专题列表
 */
export const getTopicsList = (
    params: {
        pageSize: string | number;
        page: string | number;
    },
    locale?: string,
): API.Response<{ list: TopicDataType[]; total: number }> => {
    return request.get('/japi/topic/topic_list', params, { locale });
};

/**
 * 查询专栏列表，带稿件
 * @param params
 * @returns
 */
export const getTopicArticleList = (
    params: {
        pageSize: number;
        article_size: number;
    },
    locale?: string,
) => {
    return request.get('/japi/topic/visualization/list', params, { locale });
};
/**
 * 查询专栏下热门的稿件
 * @param params
 * @returns
 */
export const getTopicHotArticle = (
    params: {
        topicId: string;
    },
    locale?: string,
) => {
    return request.get('/japi/topic/hot_article', params, { locale });
};
/**
 * 查询专栏详情下的推荐专栏
 * @param params
 * @returns
 */
export const getTopicsDetailsRecTopic = (
    params: {
        page?: string | number;
        pageSize?: string | number;
        topicId?: string;
    },
    locale?: string,
) => {
    return request.get('/japi/topic/get_recommended_column_list', params, { locale });
};
