import React from 'react';
import { Divider } from 'antd';
import c from 'classnames';
import { NextImage } from '@/components';
import { currentLocaleUrl, newFormatTimer } from '@/utils';
import styles from './index.module.less';

const ArticleItem = ({ item, hasNewsFlash }: any) => {
    const { code, title, article_add_time, rule_name, ugc, originate, thumbnail_pics = [], seo_url } = item || {};
    const thumbnail = thumbnail_pics[0] || {};
    const timer = newFormatTimer(article_add_time);

    return (
        <a
            href={currentLocaleUrl(`/article/${seo_url || code}`)}
            target="_blank"
            className={c(hasNewsFlash && styles.articleItem_a_box)}
        >
            {thumbnail.url ? (
                <div className={c(styles.articleCover, hasNewsFlash && styles.articleCoverRight)}>
                    {/* 资讯图片 */}
                    <NextImage src={thumbnail.url} layout="fill" alt={title} />
                    <span className={c(styles.videoPlay, 'iconfont', 'icon-shipin')} />
                </div>
            ) : null}

            <div className={c(styles.articleCon, hasNewsFlash && styles.articleConRight)}>
                {/* 文章标题 */}
                <div className={styles.articleTitle}>
                    <h2>{title}</h2>
                </div>

                {/* 底部作者时间 */}
                <div className={styles.articleMsg}>
                    {ugc?.name || rule_name || originate ? (
                        <span className={styles.msga}>{ugc?.name || rule_name || originate}</span>
                    ) : null}
                    {timer && (
                        <div>
                            <Divider type="vertical" />
                            <span className={styles.msgb}>{timer}</span>
                        </div>
                    )}
                </div>
            </div>
        </a>
    );
};

export default ArticleItem;
