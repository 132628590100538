import React, { useEffect, useState } from 'react';
import { FloatButton } from 'antd';
import Script from 'next/script';
import c from 'classnames';
import { useRouter } from 'next/router';

import NewMLayout from '@/reconstruction/components/public/Layout/m';
import NewPcLayout from '@/reconstruction/components/public/Layout/pc';
import { Meta } from '@/components';
import TransferCom from '@/components/public/TransferCom';
import { useAppSelector } from '@/store/hook';
import { currentLocaleUrl, loginAuthenticationToPage } from '@/utils';

import MLayout from './m';
import PcLayout from './pc';
import styles from './index.module.less';
import useTawk from './hook/useTawk';

const Layout = (props: any) => {
    const router = useRouter();
    const { lang, globalData = {}, metaData = {} } = props.children.props;
    const { baseConfig = {}, env_tag = '' } = globalData;
    const { showBrokerTool, showBrokerReputation } = useAppSelector((state: any) => state.config);

    const { brokerId }: any = router.query || {};
    const { showNewLayout = true, tawk_switch = 0 } = baseConfig;

    // 是否添加统计代码
    const [isPrd, setIsPrd] = useState<boolean>(false);

    useEffect(() => {
        const environment = localStorage?.getItem('environment');
        setIsPrd(environment === 'prd');
    }, []);

    // -------------------------------------- 多语言的客服 Start --------------------------------------
    const { showTawk, onTawkClick } = useTawk({ tawk_switch, lang });
    // -------------------------------------- 多语言的客服 End --------------------------------------

    return (
        <>
            <Meta {...metaData} site_name={baseConfig?.site_name} env_tag={env_tag} />
            <Script
                charSet="UTF-8"
                id="LA_COLLECT"
                src="//sdk.51.la/js-sdk-pro.min.js"
                onLoad={() => {
                    // @ts-ignore
                    if (window.LA) {
                        // @ts-ignore
                        LA.init({ id: 'JxqK54XxazdvX0Jf', ck: 'JxqK54XxazdvX0Jf' });
                    }
                }}
            />

            {/* 极验 */}
            <Script src="https://static.geetest.com/v4/gt4.js" />

            {isPrd ? (
                <Script
                    src="https://www.googletagmanager.com/gtag/js?id=G-KSD5ECQ3Y6"
                    onLoad={() => {
                        window.dataLayer = window.dataLayer || [];
                        function gtag() {
                            window.dataLayer.push(arguments);
                        }
                        // @ts-ignore
                        gtag('js', new Date());
                        // @ts-ignore
                        gtag('config', 'G-KSD5ECQ3Y6');
                    }}
                />
            ) : null}

            {showNewLayout ? (
                <TransferCom
                    m={<NewMLayout {...props} />}
                    ipad={<NewPcLayout {...props} />}
                    pc={<NewPcLayout {...props} />}
                />
            ) : (
                <TransferCom m={<MLayout {...props} />} ipad={<PcLayout {...props} />} pc={<PcLayout {...props} />} />
            )}

            {/* 交易商对比入口 */}
            {showBrokerTool && (
                <div
                    className={c(styles.tool, styles.contrast, styles.bottom_icon)}
                    onClick={() => window.open(currentLocaleUrl('/page/Pc_Barterer_Comparison'))}
                >
                    <i className={c('iconfont', 'icon-jiahao1', styles.icon)} />
                </div>
            )}

            {/* 发布交易商荣誉入口 */}
            {showBrokerReputation && (
                <div
                    className={c(styles.tool, styles.bottom_icon)}
                    onClick={() => loginAuthenticationToPage(router, `/broker/complaint?id=${brokerId}`)}
                >
                    <i className={c('iconfont', 'icon-fabu', styles.icon)} />
                </div>
            )}

            {/* 交易商工具入口 */}
            {showBrokerTool && (
                <div
                    className={c(styles.tool, styles.bottom_icon)}
                    onClick={() => window.open(currentLocaleUrl('/broker/tradeTool'))}
                >
                    <i className={c('iconfont', 'icon-jiaoyigongju', styles.icon)} />
                </div>
            )}

            {/* 客服入口 */}
            {tawk_switch && showTawk && (
                <div className={c(styles.tool, styles.tawk, styles.bottom_icon)} onClick={onTawkClick}>
                    <i className={c(styles.icon)} />
                </div>
            )}

            <FloatButton.BackTop
                className={c(styles.go_top, styles.bottom_icon)}
                visibilityHeight={200}
                icon={<i className={c('iconfont', 'icon-huidaodingbu1', styles.icon)} />}
            />
        </>
    );
};

export default Layout;
