import React from 'react';

import TransferCom from '@/components/public/TransferCom';

import PCSecondAdvertise from './pc';
import MSecondAdvertise from './m';
import SideSecondAdvertise from './side';
import { AdvertiseProps } from './type';

const SecondAdvertise: React.FC<AdvertiseProps> = (props) => {
    const { serviceData } = props;
    const { isRight } = serviceData || {};
    const pcDom = isRight ? <SideSecondAdvertise {...props} /> : <PCSecondAdvertise {...props} />;

    return <TransferCom pc={pcDom} ipad={pcDom} m={<MSecondAdvertise {...props} />} />;
};

export default SecondAdvertise;
