import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { Affix } from 'antd';
import { NextImage } from '@/components';
import c from 'classnames';
import { resizeObserverFun } from '@/utils';
import { useAppSelector } from '@/store/hook';
import { useRouter } from 'next/router';
import styles from './index.module.less';
import intl from 'react-intl-universal';

interface Props {
    auth?: boolean;
    isPage?: boolean;
}

const AuthMask: React.FC<Props> = ({ auth, isPage }) => {
    const router = useRouter();
    const [isLogin, setIsLogin] = useState<boolean>(true);
    const { topHeaderHeight: topHeight } = useAppSelector((state: any) => state.config);

    // 宽度
    const wrapRef = useRef<any>(null);
    const [wrapHeight, setWrapHeight] = useState<number>(0);
    useEffect(() => {
        resizeObserverFun(wrapRef.current, setWrapHeight, 'clientHeight');
    }, []);

    useEffect(() => {
        setIsLogin(!!localStorage?.getItem('Authorization'));
    }, []);

    const toLogin = useCallback(() => {
        (window as any)?.onLogin?.();
    }, []);

    /**
     * 是否展示遮罩
     */
    const hasAuth = useMemo(() => {
        return auth || isLogin;
    }, [auth, isLogin]);

    /**
     * padding
     */
    const paddingTop = useMemo(() => {
        const h = (wrapHeight - 238) / 4;
        return h < 0 ? 0 : h > 200 ? 200 : h;
    }, [wrapHeight]);

    const maskRender = useMemo(() => {
        return (
            <div className={styles.contain} style={{ padding: isPage ? paddingTop : 0 }}>
                <NextImage width={150} height={150} src="/login/auth.webp" needPrefix alt="auth" />
                <p className={styles.tip}>{intl.get('Home.NeedLogTip').d('当前页面需登录后方可查看')}</p>
                <div className={styles.btn} onClick={toLogin}>
                    <span>{intl.get('Home.LoginOrReg').d('登录/注册')}</span>
                </div>
            </div>
        );
    }, [paddingTop, isPage, toLogin]);

    return (
        <>
            <div className={c(hasAuth ? styles.hide : styles.mask, isPage ? styles.page : null)}></div>
            <div
                className={c(hasAuth ? styles.hide : styles.auth, isPage ? styles.page : styles.components)}
                ref={wrapRef}
            >
                {isPage ? <div style={{ position: 'sticky', top: topHeight }}>{maskRender}</div> : maskRender}
            </div>
        </>
    );
};

export default AuthMask;
