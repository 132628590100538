import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useRouter } from 'next/router';
import { setActiveNavId, setActiveNavKeys, setTopChildHeight } from '@/store/config';
import { useAppDispatch, useAppSelector } from '@/store/hook';
import { currentLocaleUrl, formatMts, resizeObserverFun } from '@/utils';
import { getChildList } from '@/utils/home';
import SwiperCore, { Mousewheel } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import c from 'classnames';
import styles from './index.module.less';
import type { ChildNavProps } from '../types';

SwiperCore.use([Mousewheel]);

const HeaderNav: React.FC<ChildNavProps> = ({ top, menus = [] }) => {
    const router = useRouter();
    const { pathname } = router;
    const dispatch = useAppDispatch();
    const { activeNavKeys } = useAppSelector((state: any) => state.config);

    // ------------- 二级导航高度
    const childRef = useRef<any>(null);
    const [pcChildHeight, setPcChildHeight] = useState<number>(0);
    useEffect(() => {
        resizeObserverFun(childRef.current, setPcChildHeight, 'clientHeight');
    }, []);

    useEffect(() => {
        dispatch(setTopChildHeight(pcChildHeight || 0));
    }, [pcChildHeight]);

    // 子列表
    const [childList, setChildList] = useState<any[]>([]);

    useEffect(() => {
        setChildList(getChildList(menus, activeNavKeys[0]));
    }, [activeNavKeys, menus]);

    // 跳转
    const toKeyPath = useCallback((item: any, isIndex?: boolean) => {
        const { id, parentId, web, type, inventedUrl } = item;
        const index = (menus || []).findIndex((m: any) => [m?.inventedUrl || m.id, m.id].includes(parentId));

        switch (type) {
            case 'page':
                dispatch(setActiveNavId(inventedUrl || id));
                const parentItem = menus.find((pItem: any) => pItem.id === parentId) || {};
                const pathKeys = [inventedUrl || id];
                if (parentItem?.id) {
                    pathKeys.unshift(parentItem?.inventedUrl || parentItem?.id);
                }
                dispatch(setActiveNavKeys(pathKeys));
                router.push(currentLocaleUrl(isIndex && index === 0 ? '/' : `/${pathKeys.join('/')}`));
                break;
            case 'web':
                const { url } = web;
                window.open(currentLocaleUrl(url));
                break;
            default:
                break;
        }
    }, []);

    /**
     * 渲染nav每一项
     */
    const navItemRender = useCallback((item: any, isIndex: boolean) => {
        const { name, nameMts, children = [] } = item;
        const navMtsName = formatMts(nameMts) || name;

        return (
            <div
                className={styles.aHref}
                onClick={() => {
                    let activeItem = item;

                    if ((children || []).length !== 0) {
                        activeItem = (children || [])[0];
                    }
                    toKeyPath(activeItem, isIndex);
                }}
            >
                <span>{navMtsName}</span>
            </div>
        );
    }, []);

    return (
        <div
            style={{ top: top || 0 }}
            className={c(styles.nav_box, {
                [styles.nav_box_show]: childList.length !== 0 && !(pathname === '/search'),
            })}
            ref={childRef}
        >
            <nav className={c({ [styles.nav]: true })}>
                <Swiper
                    observer
                    observeParents
                    mousewheel
                    resizeObserver
                    grabCursor
                    slidesPerView="auto"
                    className={c(styles.list)}
                >
                    {childList.map((item: any, i: number) => {
                        const { id, inventedUrl } = item;
                        return (
                            <SwiperSlide
                                className={c(styles.list_item, {
                                    [styles.active]: activeNavKeys[1] && [inventedUrl, id].includes(activeNavKeys[1]),
                                })}
                                key={`${id}_${inventedUrl}`}
                            >
                                {navItemRender(item, i === 0)}
                            </SwiperSlide>
                        );
                    })}
                </Swiper>
            </nav>
        </div>
    );
};

export default HeaderNav;
