import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import c from 'classnames';
import { setRootId, setActiveNavId, setMenuOpen } from '@/store/config';
import { useAppSelector, useAppDispatch } from '@/store/hook';
import { formatMts } from '@/utils';
import type { NavBarProps } from '../types';
import styles from './index.module.less';

const NavBar: React.FC<NavBarProps> = (props) => {
    const { menus = [] } = props;
    const router = useRouter();
    const dispatch = useAppDispatch();

    const { topHeaderHeight: topHeight, menuOpen, activeNavId } = useAppSelector((state: any) => state.config);

    useEffect(() => {
        dispatch(setRootId(menus[0]?.inventedUrl || menus[0]?.id));
    }, [dispatch, menus]);

    useEffect(() => {
        // 除了菜单页 首页默认取菜单的第一个id 其余页面不设置id

        const { id } = router.query || {};
        if (id) {
            // addHrefEventListener();
            dispatch(setActiveNavId(id));
            return;
        }

        if (router.route === '/') {
            const { id } = menus[0] || {};
            dispatch(setActiveNavId(id || ''));
            return;
        }
    }, [router.query.id, menus]);

    const onMenuClick = (id: string) => {
        dispatch(setActiveNavId(id));
        dispatch(setMenuOpen(false));
    };

    /**
     * 禁用/启用页面滚动
     */
    useEffect(() => {
        if (menuOpen) {
            document.body.style.height = '100vh';
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.height = 'auto';
            document.body.style.overflow = 'auto';
        }
    }, [menuOpen]);

    return (
        <aside
            className={c(styles.menuDrawer, menuOpen ? styles.menuOpen : null)}
            style={{ top: menuOpen ? topHeight : '100vh', height: `calc(100vh - ${topHeight}px)` }}
        >
            <ul className={styles.menuList}>
                {[...menus].map((e: any, i: number) => {
                    const { id: eId, name, nameMts, inventedUrl } = e;

                    return (
                        <li key={eId} className={c(activeNavId === eId ? styles.active : null)}>
                            <Link href={i === 0 ? '/' : `/${inventedUrl || eId}`}>
                                <span
                                    onClick={() => {
                                        onMenuClick(eId);
                                    }}
                                >
                                    {formatMts(nameMts) || name}
                                </span>
                            </Link>
                        </li>
                    );
                })}
            </ul>
        </aside>
    );
};

export default NavBar;
