import request from '@/utils/axios';

// 用户登录注册
export const postLoginApi = (data: any) => {
    return request.post('/auth/pc/login', data);
};

// 发送验证码短信
export const postVerificationCodeApi = (data: any, moreConfig?: { noMsg: boolean }) => {
    return request.post('/auth/pc/verificationCode', data, moreConfig);
};

// 登录-绑定账号
export const bindingAccount = (parmas: {
    uid: any; // 第三方id 登录的时候获取
    type: number; // 第三方类型 1.代表谷歌，2.代表facebook，3.代表苹果,6.代表推特
    mobile?: string | number; // 邮箱手机二选一
    phoneCode?: string;
    mail?: string; // 邮箱手机二选一
    verificationCode: string;
}) => {
    return request.post('/auth/binding/account', parmas);
};

// 登录/设置 忘记密码
export const updataPassword = (parmas: { password: any; verify: string }) => {
    return request.post('/auth/update/password', parmas);
};

// 根据IP获取 电话区号
export const getIpPhoneCode = () => {
    return request.get('/auth/get/ip');
};
