import React from 'react';
import TransferCom from '@/components/public/TransferCom';
import MAuthMask from './m';
import PcAuthMask from './pc';

interface IProps {
    auth?: boolean;
    isPage?: boolean;
}

const AuthMask: React.FC<IProps> = (props) => {
    return <TransferCom m={<MAuthMask {...props} />} pc={<PcAuthMask {...props} />} />;
};

export default AuthMask;
