import React from 'react';
import c from 'classnames';
import _ from 'lodash';
import intl from 'react-intl-universal';

import { NextImage } from '@/components';
import { formatTimer, getTagsLen, getHtmlPlainText, filterSpace, currentLocaleUrl, getArticleViewNum } from '@/utils';
import { IProps } from '../type';

import styles from './index.module.less';

const SearchArticleList: React.FC<IProps> = (props) => {
    const { dataSource, keyword, autoShowVideo, onCollectItem, isViewNum = false, isShowUgc = true } = props;

    const {
        title,
        code,
        seo_url,
        category,
        introduction,
        rule_name,
        ugc,
        tag_info,
        view_num,
        datetime,
        thumbnail_pics = [],
    } = dataSource || {};

    const tags = getTagsLen(tag_info, 3, keyword);

    const introdu = filterSpace(getHtmlPlainText(introduction || ''));
    const newTags = tags.map((tag, index) => ({ tag, key: index }));

    return (
        <a
            target="_blank"
            href={currentLocaleUrl(`/article/${seo_url || code}`)}
            className={c(styles.searchWrapper, props.className || '')}
            key={code}
        >
            {/* 图片&视频展示 */}
            <div
                className={c(
                    styles.searchImage,
                    category == 'video' && styles.searchVideoImg,
                    autoShowVideo && category == 'video' && styles.showVideoImg,
                )}
            >
                <NextImage
                    src={thumbnail_pics[0]?.url || '/img/place/img_new_normal.webp'}
                    layout="fill"
                    alt={title}
                    place="new_normal"
                />
                {category === 'video' && (
                    <div className={styles.video_play_btn}>
                        <NextImage src="/img/video/play.webp" width={40} height={40} alt="play" />
                    </div>
                )}
            </div>

            {/* 右侧标题简介区域 */}
            <div className={styles.searchMain}>
                {/* 标题&简介*/}
                <div className={styles.linkBox}>
                    <div className={styles.titleWrapper}>
                        {/* 标题 */}
                        <h2 className={styles.searchTitle} dangerouslySetInnerHTML={{ __html: title || '' }} />
                    </div>

                    {/* 简介 */}
                    {(introdu || thumbnail_pics[0]?.url) && <div className={styles.searchContent}>{introdu || ''}</div>}
                </div>

                {/* 底部 */}
                <div className={styles.searchBottom}>
                    <div className={styles.searchBottomLeft}>
                        {isShowUgc && (
                            <>
                                <span className={styles.searchRuleName}>{ugc?.name || rule_name || ''}</span>
                                <i />
                            </>
                        )}
                        <span className={styles.searchTime}>{formatTimer(datetime)}</span>

                        {isViewNum && (
                            <span className={styles.view_num}>
                                <span className={c(styles.icon, 'iconfont', 'icon-liulanliang')} />
                                <span>{getArticleViewNum(view_num || 0, 'w')}</span>
                            </span>
                        )}
                    </div>

                    <div className={styles.searchBottomRight}>
                        {onCollectItem ? (
                            <span onClick={onCollectItem}>{intl.get('Common.Delete').d('删除')}</span>
                        ) : (
                            <>
                                {_.map(newTags, (tagItem, tagIndex: number) => (
                                    /** 原创显示所有原创稿件 并非关键词=原创 */
                                    <span
                                        key={tagItem.key}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            window.open(currentLocaleUrl(`/keyword?tags=${tagItem.tag}`));
                                        }}
                                    >
                                        {tagItem.tag}
                                        {tagIndex !== tags.length - 1 ? '/' : ''}
                                    </span>
                                ))}
                            </>
                        )}
                    </div>
                </div>
            </div>
        </a>
    );
};

export default SearchArticleList;
