import TransferCom from '@/components/public/TransferCom';

import MActivityListItem from './m';
import PCActivityListItem from './pc';
import type { ActivityListItemType } from './type';

const ActivityListItem = (props: ActivityListItemType) => {
    return (
        <TransferCom
            m={<MActivityListItem {...props} />}
            ipad={<PCActivityListItem {...props} />}
            pc={<PCActivityListItem {...props} />}
        />
    );
};

export default ActivityListItem;
