import React, { useMemo, useState, useCallback, useRef, useEffect } from 'react';
import SwiperCore, { Navigation } from 'swiper';
import { useRouter } from 'next/router';
import { Affix } from 'antd';
import { useAppSelector } from '@/store/hook';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import c from 'classnames';
import styles from './index.module.less';

interface Props {
    isLink?: boolean; // 是否a标签切换页面
    isFixed?: boolean; // 是否吸顶
    fixedTop?: number; // 吸顶高度
    activeIndex?: number; // tab下标
    className?: string; // 外层class
    elementId?: string;
    translateY?: number; // isFixed = true时  调整到初始内容时的偏移量
    wrapperClass?: string; // 外层class
    hasUnderline?: boolean; // 是否展示下划线
    defaultActiveTab?: number; // 外层class
    toggleBtnClass?: string; // 切换按钮class
    list: any[]; // tab list
    itemRender: (itemParams: any, activeTab: number) => React.ReactNode; // tabitem
    onTabChange?: (activeTab: number, params: any) => void; // tab切换事件
}

SwiperCore.use([Navigation]);

const Tabs: React.FC<Props> = ({
    list = [],
    elementId = 'a',
    activeIndex,
    defaultActiveTab = 0,
    // translateY = 24,
    onTabChange,
    hasUnderline,
    itemRender,
    wrapperClass,
    isFixed = false,
    fixedTop,
    isLink = false,
    className = '',
}) => {
    const tabList = useMemo(() => {
        return list.map((item, index) => {
            return { ...item, index };
        });
    }, [list]);

    const [activeTab, setActiveTab] = useState<number>(0);

    const tabIndex = useMemo(() => {
        return typeof activeIndex === 'number' ? activeIndex : activeTab;
    }, [activeIndex, activeTab]);

    // const [offsetTop, setOffsetTop] = useState<number>(100)
    const router = useRouter();

    const {
        topHeight,
        rootId,
        showBarkly,
        activeNavKeys = [],
    } = useAppSelector((state: any) => {
        return state.config;
    });
    const tabsRef = useRef<any>(null);

    useEffect(() => {
        setActiveTab(defaultActiveTab);
    }, [defaultActiveTab]);

    // 处理url
    const getUrl = useCallback(
        (url: string, index: number) => {
            const bastPath = `/${activeNavKeys.join('/')}`;

            return index || index === 0 ? `${bastPath}/${url || String(index + 1)}` : bastPath;
        },

        [rootId, router.asPath, activeNavKeys],
    );

    const onTabClick = useCallback(
        (item: any, index: number) => {
            if (item.index !== tabIndex) {
                setActiveTab(item.index);
                if (isLink) {
                    console.log('bastPath', getUrl(item.url, index));
                    history?.replaceState?.(null, '', getUrl(item.url, index));
                }
                onTabChange?.(item.index, item);
            }
        },
        [tabIndex, isLink, onTabChange, activeNavKeys],
    );

    const TabsSwiper = useMemo(() => {
        return (
            <Swiper
                observer
                resizeObserver
                grabCursor
                slidesPerView="auto"
                className={c(styles.tabs, { [styles.isFixed]: isFixed }, wrapperClass, styles.has_underline)}
                navigation={{
                    nextEl: `.swiper-${elementId} .swiper-button-next`,
                    prevEl: `.swiper-${elementId} .swiper-button-prev`,
                }}
            >
                {tabList.map((item: any, index: number) => (
                    <SwiperSlide
                        className={styles.tabsItem}
                        key={item.index}
                        onClick={() => {
                            onTabClick(item, index);
                        }}
                    >
                        {itemRender(item, tabIndex)}
                    </SwiperSlide>
                ))}
                <div className={`swiper-${elementId}`}>
                    <div className={c(styles.swiperBtnNext, 'swiper-button-next')}>
                        <div className={styles.swiperBackNext} />
                    </div>
                    <div className={c(styles.swiperBtnPrev, 'swiper-button-prev')}>
                        <div className={styles.swiperBackPrev} />
                    </div>
                </div>
            </Swiper>
        );
    }, [tabIndex, elementId, getUrl, isFixed, isLink, itemRender, onTabClick, tabList, wrapperClass, showBarkly]);

    return (
        <div id="tabs" ref={tabsRef} className={c(className, showBarkly ? styles.barkly_container : '')}>
            {isFixed ? (
                <Affix offsetTop={fixedTop || topHeight} className={styles.affix}>
                    {TabsSwiper}
                </Affix>
            ) : (
                <>{TabsSwiper}</>
            )}
        </div>
    );
};

export default Tabs;
