import React, { useRef } from 'react';
import { Popover } from 'antd';
import c from 'classnames';
import { NextImage } from '@/components';
import intl from 'react-intl-universal';
import styles from './index.module.less';

interface WhatsAPPProps {
    dataSource?: {
        mts?: string;
        name: string;
        icon: string;
        list?: {
            title: string;
            codeUrl: string;
        }[];
    };
}

const WhatsAPP: React.FC<WhatsAPPProps> = ({ dataSource }) => {
    const { name, mts, icon, list = [] } = dataSource || {};

    const whatsRef = useRef<any>(null);

    return (
        <Popover
            overlayClassName={styles.antPopover}
            placement="bottom"
            getPopupContainer={() => whatsRef?.current}
            content={
                <div className={styles.list}>
                    {list.map((item: any) => {
                        return (
                            <div key={item.codeUrl} className={styles.listItem}>
                                <NextImage src={item.codeUrl} needPrefix width={100} height={100} alt={name} />
                                <div className={styles.codePhone}>{item.phone}</div>
                                <div className={styles.codeTitle}>{item.title}</div>
                            </div>
                        );
                    })}
                </div>
            }
        >
            <div className={styles.content} ref={whatsRef}>
                <span className={c(styles.icon, 'iconfont', icon)} />
                <span>{mts ? intl.get(mts).d(name || '') : name}</span>
            </div>
        </Popover>
    );
};

export default WhatsAPP;
