import React, { useState, useEffect } from 'react';
import c from 'classnames';
import intl from 'react-intl-universal';
import { NextImage } from '@/components';
import styles from './index.module.less';

interface ItemProps {
    id?: string | number;
    value: string | number;
    label: string;
}

interface DropProps {
    value?: string | number;
    options?: ItemProps[];
    defaultValue?: string;
    fieldNames?: { label: string; value: string };
    /** 选择回调 */
    onSelect?: (v: any, item?: ItemProps) => void;
    /** 回调 */
    onChange?: (v: any) => void;
    /** 自定义按钮 */
    dropBtnRender?: (label: any, open?: boolean) => React.ReactNode;
    /** 自定义弹窗内容 */
    dropMenuRender?: (options: ItemProps[]) => React.ReactNode;
}

const DropUpBox: React.FC<DropProps> = ({
    value,
    options = [],
    defaultValue,
    fieldNames,
    onChange,
    onSelect,
    dropBtnRender,
    dropMenuRender,
}) => {
    const locale = intl.getInitOptions().currentLocale;

    /** 展开下拉 */
    const [open, setOpen] = useState<boolean>(false);
    /**  */
    const [activeKey, setActiveKey] = useState<string>(defaultValue || '');
    const [activeLabel, setActiveLabel] = useState<string | number>('');
    /**
     * 禁用/启用页面滚动
     */
    useEffect(() => {
        if (open) {
            document.body.style.height = '100vh';
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.height = 'auto';
            document.body.style.overflow = 'auto';
        }
    }, [open]);

    /**
     * 获取 active label
     */
    useEffect(() => {
        const activeItem: any = options.find((item: any) => item[fieldNames?.value || 'value'] === value) || {};

        setActiveKey(activeItem[fieldNames?.value || 'value'] || '');
        setActiveLabel(activeItem[fieldNames?.label || 'label'] || '');
    }, [locale, value, options]);

    /**
     * 展开、关闭
     */
    const onChangeOpen = () => {
        setOpen(!open);
    };

    /**
     * 选择回调
     * @param item
     */
    const onSelectItem = (item: any) => {
        onChange?.(item[fieldNames?.value || 'value']);
        onSelect?.(item[fieldNames?.value || 'value'], item);
        setOpen(false);
    };

    return (
        <div className={styles.wrapper}>
            {dropBtnRender ? (
                <div onClick={onChangeOpen}>{dropBtnRender(activeLabel, open)}</div>
            ) : (
                <div
                    className={c({
                        [styles.drop_btn]: true,
                        [styles.drop_btn_open]: open,
                    })}
                    onClick={onChangeOpen}
                >
                    <span className={styles.drop_name}>{activeLabel}</span>
                </div>
            )}

            <div className={c(styles.drop_menu, { [styles.drop_menu_open]: open })}>
                <div className={styles.drop_list}>
                    {dropMenuRender ? (
                        dropMenuRender(options)
                    ) : (
                        <ul className={styles.list}>
                            {options.map((item: any) => {
                                return (
                                    <li
                                        key={item[fieldNames?.value || 'value']}
                                        className={c({
                                            [styles.active]: item[fieldNames?.value || 'value'] === activeKey,
                                        })}
                                        onClick={() => onSelectItem(item)}
                                    >
                                        <span>{item.label}</span>
                                    </li>
                                );
                            })}
                        </ul>
                    )}

                    <div className={styles.drop_close} onClick={onChangeOpen}>
                        <NextImage src={`/setting/modal_close.webp`} needPrefix width={16} height={16} alt="close" />
                    </div>
                </div>

                <div className={styles.drop_modal} onClick={onChangeOpen} />
            </div>
        </div>
    );
};

export default DropUpBox;
