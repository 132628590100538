import React from 'react';
import dayjs from 'dayjs';
import NextImage from '@/components/public/NextImage';
import styles from './index.module.less';
import intl from 'react-intl-universal';
import { currentLocaleUrl } from '@/utils';

interface Props {
    list: any[];
    ids: string;
    morePage: any;
}

const FlashNews: React.FC<Props> = ({ list = [], morePage, ids }) => {
    return (
        <div className={styles.flashNews}>
            <span className={styles.watermark} />
            <div className={styles.flashNewsCon}>
                <h2 className={styles.flashNewsTit}>
                    <span>7*24</span>
                    <span>{intl.get('Home.NewsFlash').d('快讯')}</span>
                    <NextImage src="/icon/flash.webp" needPrefix width={28} height={28} alt="flash" />
                </h2>
                <ul className={styles.flashNewsList}>
                    {[...list].map((item: any) => {
                        const { id, pk, title, datetime, code, seo_url } = item;
                        const timer = datetime ? dayjs(datetime).format('HH:mm') : datetime;
                        return (
                            <li key={id || pk}>
                                <a href={currentLocaleUrl(`/article/${seo_url || code}`)} target="_blank">
                                    <div className={styles.itemTimer}>{timer}</div>
                                    <div className={styles.itemContain}>{title}</div>
                                </a>
                            </li>
                        );
                    })}
                </ul>
                <a className={styles.loadMore} href={currentLocaleUrl(`/${morePage}?ids=${ids}`)} target="_blank">
                    {intl.get('Common.SeeMore').d('查看更多')}
                </a>
            </div>
        </div>
    );
};

export default FlashNews;
