import React from 'react';
import c from 'classnames';
import intl from 'react-intl-universal';

import { NextImage } from '@/components';

import styles from './index.module.less';
import { UgcListItemPropsType } from '../type';

const MUgcListItem = (props: UgcListItemPropsType) => {
    const { ugcData, onFocusUgc, className } = props;

    const { news_number, is_focus, name, avatar, introduction } = ugcData || {};

    return (
        <div className={c(styles.wrapper, className || '')}>
            <div className={styles.cover}>
                <NextImage src={avatar} place="ugc" layout="fill" objectFit="cover" />
            </div>
            <div className={styles.info__wrapper}>
                <div className={styles.info__top}>
                    <div className={styles.info__top__left}>
                        <div className={styles.title}>{name}</div>
                        <div className={styles.article__num}>
                            <span>{news_number || 0}</span> {intl.get('PersonalCenter.Article').d('篇文章')}
                        </div>
                    </div>
                    <div className={c(styles.focus, { [styles.not]: is_focus !== 1 })} onClick={onFocusUgc}>
                        {is_focus === 1 ? (
                            intl.get('Article.AlreadyFollowed').d('已关注')
                        ) : (
                            <React.Fragment>
                                <NextImage
                                    src={'/h5/attention.png'}
                                    alt="attention"
                                    needPrefix
                                    width={14}
                                    height={14}
                                />
                                <span>{intl.get('Article.Followed').d('关注')}</span>
                            </React.Fragment>
                        )}
                    </div>
                </div>
                <div className={styles.introduction}>{introduction}</div>
            </div>
        </div>
    );
};

export default MUgcListItem;
