import React from 'react';
import { Form, Input } from 'antd';
import c from 'classnames';
import intl from 'react-intl-universal';
import type { SearchInputProps } from '../types';
import styles from './index.module.less';

const SearchInput: React.FC<SearchInputProps> = (props) => {
    const { form, inpVal, isFocus, inputRef, onFocus, onGoBack, onBlur, onPressEnter, onInputChange } = props || {};

    return (
        <div className={c(styles.search_contain, isFocus ? styles.search_focus : null)}>
            <Form form={form} className={styles.form}>
                <Form.Item noStyle name="searchVal">
                    <Input
                        ref={inputRef}
                        autoComplete="off"
                        allowClear={{
                            clearIcon: (
                                <span onClick={onGoBack} className={c(styles.input_icon, 'iconfont', 'icon-shanchu')} />
                            ),
                        }}
                        suffix={
                            <div
                                onMouseDown={(e) => e.preventDefault()}
                                onClick={onPressEnter}
                                className={styles.icon_group}
                            >
                                <span className={c(styles.input_icon, styles.sh_icon, 'iconfont', 'icon-sousuo')} />
                            </div>
                        }
                        className={c(
                            styles.search_input,
                            inpVal ? styles.search_has : null,
                            isFocus && !inpVal ? styles.search_color : null,
                        )}
                        placeholder={intl.get('Common.Search').d('搜索')}
                        onChange={onInputChange}
                        onPressEnter={onPressEnter}
                        onFocus={onFocus}
                        onBlur={onBlur}
                    />
                </Form.Item>
            </Form>
        </div>
    );
};

export default SearchInput;
