import { useState, useCallback, useEffect, useImperativeHandle, useMemo } from 'react';
import { useRouter } from 'next/router';
import { App } from 'antd';
import intl from 'react-intl-universal';

import { postVerificationCodeApi } from '@/services/auth';
import { geeLanguage } from '@/constants/mts';

import styles from './index.module.less';

interface CodeProps {
    mobile: string;
    phone_code: string;
}
interface Props {
    validate: () => any; // 验证手机号
    onRef?: React.MutableRefObject<object | undefined>;
}

let timer: any = null;
const AuthCode: React.FC<Props> = (props) => {
    const { validate, onRef } = props;
    const router = useRouter();
    const { locale } = router;
    const [second, setSecond] = useState<number>(60);
    const [hasSend, setHasSend] = useState<boolean>(false);
    const { message } = App.useApp();
    useEffect(() => {
        return () => {
            clearInterval(timer);
        };
    }, []);

    const handleClick = useCallback(() => {
        if (second === 60) {
            validate().then((values: any) => {
                // 极验校验通过后才发送短信
                if (!window.initGeetest4) {
                    message.warning(intl.get('BindAccount.LoadWarnMsg').d('页面尚未加载完毕，请稍后再试'));
                    return;
                }
                const { captchaId, captchaKey }: any = process.env.NEXT_PUBLIC_SETTING;
                // 初始化极验
                window.initGeetest4(
                    {
                        captchaId,
                        product: 'bind',
                        language: geeLanguage[locale || 'zh-CN'],
                    },
                    (captcha: any) => {
                        // captcha为验证码实例
                        captcha
                            .onReady(() => {
                                captcha.showCaptcha();
                            })
                            .onSuccess(() => {
                                // 校验通过
                                const result = captcha.getValidate(); // 获取极验参数
                                const params = {
                                    captchaId,
                                    captchaKey,
                                    lotNumber: result.lot_number,
                                    genTime: result.gen_time,
                                    captchaOutput: result.captcha_output,
                                    passToken: result.pass_token,
                                    ...values,
                                };
                                // console.log('校验成功：', params)
                                postVerificationCodeApi({
                                    // 发送验证码
                                    ...params,
                                })
                                    .then((res: any) => {
                                        if (res.state !== 1) {
                                            clearInterval(timer);
                                            setHasSend(false);
                                            setSecond(60);
                                            captcha.reset();
                                        } else {
                                            captcha.destroy();
                                        }
                                    })
                                    .catch((err: any) => {
                                        clearInterval(timer);
                                        setHasSend(false);
                                        setSecond(60);
                                        captcha.reset();
                                    });
                                clearInterval(timer);
                                timer = setInterval(() => {
                                    setSecond((preSecond: number) => {
                                        if (preSecond === 1) {
                                            setHasSend(true);
                                            clearInterval(timer);
                                        }
                                        return preSecond === 1 ? 60 : preSecond - 1;
                                    });
                                }, 1000);
                            })
                            .onError(() => {
                                // 校验失败
                                captcha.reset();
                            });
                    },
                );
            });
        }
    }, [message, second, validate]);

    // 暴露方法给父级组件
    useImperativeHandle(onRef, () => {
        return {
            // 发送验证码
            sendCode: (values: CodeProps) => {
                if (second === 60) {
                    postVerificationCodeApi({
                        ...values,
                    })
                        .then((res: any) => {
                            if (res.state !== 1) {
                                clearInterval(timer);
                                setHasSend(false);
                                setSecond(60);
                            }
                        })
                        .catch((err: any) => {
                            clearInterval(timer);
                            setHasSend(false);
                            setSecond(60);
                        });
                    clearInterval(timer);
                    timer = setInterval(() => {
                        setSecond((preSecond: number) => {
                            if (preSecond === 1) {
                                setHasSend(true);
                                clearInterval(timer);
                            }
                            return preSecond === 1 ? 60 : preSecond - 1;
                        });
                    }, 1000);
                }
            },
        };
    });

    const renderText = useMemo(() => {
        let result;
        if (second && second !== 60) {
            result = `${second}S`;
        } else if (hasSend) {
            result = intl.get('BindAccount.Resend').d('重新发送');
        } else {
            result = intl.get('BindAccount.GetVeriCode').d('获取验证码');
        }
        return result;
    }, [second, hasSend]);

    return (
        <span className={styles.code} onClick={handleClick}>
            {renderText}
        </span>
    );
};

export default AuthCode;
