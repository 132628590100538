import React from 'react';

import RcBlockNewsFlash from './BlockNewsFlash';
import RcRightNewsFlash from './RightNewsFlash';

import { formatDomUnit } from '@/utils';

import { IProps } from '../type';

interface Props extends IProps {}

const NewsFlash: React.FC<Props> = (props) => {
    const { customStyle, isRightNewsFlash } = props;

    return isRightNewsFlash ? (
        <RcRightNewsFlash {...props} />
    ) : (
        <div style={{ ...formatDomUnit(customStyle || {}) }}>
            <RcBlockNewsFlash {...props} />
        </div>
    );
};

export default NewsFlash;
