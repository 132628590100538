import React, { useState, useEffect, useRef } from 'react'
import { Col, Row } from 'antd'
import RenderItem from './renderItem'
import { resizeObserverFun, getColSpan } from '@/utils'
import styles from './index.module.less'

interface Props {
  list: any[]
}

const VideoList: React.FC<Props> = ({ list = [] }) => {
  const domRef = useRef<any>(null)
  const [domWidth, setDomWidth] = useState<number>(0)
  useEffect(() => {
    resizeObserverFun(domRef.current, setDomWidth);
  }, [])

  return <div className={styles.videoList} ref={domRef} >
    <Row gutter={24}>
      {
        [...list].map((e: any) => {
          const { pk, key } = e || {}
          return <Col key={pk || key} span={getColSpan(domWidth, 4)}>
            <RenderItem item={e} />
          </Col>
        })
      }
    </Row>
  </div>
}

export default VideoList