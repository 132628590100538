import TransferCom from '@/components/public/TransferCom';

import MNormalTitle from './m';
import PCNormalTitle from './pc';
import type { NormalTitlePros } from './types';

const NormalTitle: React.FC<NormalTitlePros> = (props) => {
    return (
        <TransferCom
            m={<MNormalTitle {...props} />}
            ipad={<PCNormalTitle {...props} />}
            pc={<PCNormalTitle {...props} />}
        />
    );
};

export default NormalTitle;
