import React, { useMemo } from 'react';
import { Button } from 'antd';
import { NextImage } from '@/components';
import intl from 'react-intl-universal';
import { formatBrokerBusiness } from '@/utils';
import c from 'classnames';
import type { BrokerItemDProps } from '../../type';
import styles from './index.module.less';

const BrokerItem: React.FC<BrokerItemDProps> = ({
    record,
    locale,
    accountUrl,
    onClickItem,
    onOpenAccount,
    onCompared,
}) => {
    const {
        logo,
        name_abbreviation,
        business_name,
        score,
        exclusive_activities,
        security_slogan,
        broker_describe,
        broker_other_info,
    } = record;

    const [showAll, setShowAll] = React.useState<boolean>(false);

    /** 专属活动 */
    const exclusiveActivities = useMemo(() => {
        return (
            exclusive_activities || // 默认
            broker_describe?.exclusive_activities || // 外汇
            broker_other_info?.description?.exclusive_activities || // 证券
            ''
        );
    }, [exclusive_activities, broker_describe, broker_other_info]);

    /** slogan */
    const slogan = useMemo(() => {
        return security_slogan || broker_describe?.slogan || '';
    }, [exclusive_activities, broker_other_info]);

    /**
     * 展开收起 按钮
     */
    const showBtn = useMemo(() => {
        return (
            <div className={c(styles.wrapper_more, { [styles.show_all]: showAll })}>
                <div
                    className={styles.more}
                    onClick={() => {
                        setShowAll(!showAll);
                    }}
                >
                    <div className={styles.text_more}>
                        {intl.get('Broker.SecurityListTit3').d('Hawk Insight 专属优惠')}
                    </div>
                    <span
                        className={c(styles.arrow_more, 'iconfont', 'icon-jiantou', {
                            [styles.arrow_more_t]: showAll,
                        })}
                    />
                </div>
            </div>
        );
    }, [showAll]);

    return (
        <div className={styles.li}>
            <div className={styles.li_top} onClick={onClickItem}>
                <div className={styles.logo_box}>
                    <div className={styles.logo}>
                        <NextImage src={logo} layout="fill" alt={name_abbreviation} place="new_normal" />
                    </div>

                    <span className={styles.business}>{formatBrokerBusiness(business_name, locale)}</span>
                </div>

                <div className={styles.info}>
                    <div className={styles.info_top}>
                        <div className={styles.name_box}>
                            <span className={styles.name}>{name_abbreviation}</span>
                        </div>
                        <p className={styles.score}>
                            <span>
                                <strong>{Number(score || 0).toFixed(1)}</strong>
                            </span>
                            <span>{intl.get('Broker.Score').d('评分')}</span>
                        </p>
                    </div>

                    <div className={styles.btn__wrapper}>
                        {accountUrl && (
                            <Button className={styles.open_account} onClick={(e: any) => onOpenAccount(e, accountUrl)}>
                                {intl.get('Broker.OpenAnAccount').d('开户')}
                            </Button>
                        )}
                        <Button
                            className={styles.compared}
                            onClick={(e: any) => {
                                e?.preventDefault();
                                e?.stopPropagation();
                                e?.nativeEvent.stopImmediatePropagation();
                                onCompared?.(record);
                            }}
                        >
                            {intl.get('Broker.Compared1').d('对比')}
                        </Button>
                    </div>
                </div>
            </div>
            {/* 底部查看更多 */}
            {(slogan || exclusiveActivities) && (
                <div className={styles.wrapper_bottom}>
                    <div className={styles.bottom_left}>{slogan}</div>
                    {exclusiveActivities && showBtn}
                </div>
            )}
            {exclusiveActivities && showAll && (
                <div className={c(styles.wrapper_main)}>
                    <div className={styles.main_con} dangerouslySetInnerHTML={{ __html: exclusiveActivities || '' }} />
                </div>
            )}
        </div>
    );
};

export default BrokerItem;
