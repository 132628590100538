import React from 'react';
import { useAppDispatch, useAppSelector } from '@/store/hook';
import { setMenuOpen } from '@/store/config';
import c from 'classnames';
import styles from './index.module.less';

const HeaderMenu: React.FC = () => {
    const dispatch = useAppDispatch();
    // 菜单展开收起
    const { menuOpen, showBarkly } = useAppSelector((state: any) => state.config);
    const onOpen = (e: any) => {
        e.stopPropagation();
        e.preventDefault();
        dispatch(setMenuOpen(!menuOpen));
    };

    return (
        <div className={c(styles.menu, { [styles.barkly_container]: showBarkly })}>
            <span onClick={onOpen} className={c('iconfont', 'icon-zhankai', menuOpen ? styles.active : null)} />
        </div>
    );
};

export default HeaderMenu;
