import { useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import { useCookies } from 'react-cookie';

import { postLoginCallback } from '../utils';
import { bindingAccount, postLoginApi, updataPassword } from '@/services/auth';

import type { LoginContextType, LoginFromType, LoginFormType, LoginVerifyType } from '../content';
import intl from 'react-intl-universal';
import { Form } from 'antd';
import { verificationAuthCode } from '@/services/setting';

const useLoginForm = (onCancel: () => void) => {
    const router = useRouter();
    const local = intl.getInitOptions().currentLocale;
    const [_cookies, setCookie] = useCookies(['AUTH_TOKEN']);

    /** 当前表单类型 */
    const [currentFormType, setCurrentFormType] = useState<LoginFromType>('phone');
    /** 手机表单 */
    const [phoneForm] = Form.useForm();
    /** 邮箱表单 */
    const [emailForm] = Form.useForm();
    /** 验证类型 */
    const [verifyType, setVerifyType] = useState<LoginVerifyType>('code');
    /** 密码类型 */
    const [formType, setFormType] = useState<LoginFormType>('normal');
    /** 加载状态 */
    const [loading, setLoading] = useState<boolean>(false);
    /** 身份证明 */
    const [verify, setVerify] = useState<string>('');

    /** 提交按钮文案 */
    const loginBtnText = useMemo(() => {
        switch (formType) {
            case 'set':
                return intl.get('Common.Confirm').d('确认');
            case 'forget':
                return intl.get('BindAccount.Next').d('下一步');
            case 'bind':
                return intl.get('BindAccount.Accomplish').d('完成');
            case 'normal':
            default:
                return intl.get('Login.RegisterLog').d('注册/登录');
        }
    }, [formType, local]);

    // —————————————————————— 设置密码 ————————————————————
    const setPwdHandler = (values: any) => {
        setLoading(true);
        const { verify } = JSON.parse(localStorage?.getItem('userInfo') || '{}');
        const params = {
            verify,
            password: values.password,
        };
        updataPassword(params)
            .then((res: any) => {
                postLoginCallback(res, router, setFormType, setCookie, onCancel);
            })
            .finally(() => {
                setLoading(false);
            });
    };
    // —————————————————————— 设置密码 ————————————————————

    // —————————————————————— 绑定提交 ————————————————————
    const BindHandler = (values: any) => {
        const loginType = sessionStorage.getItem('loginType') || '';

        const types: any = {
            facebook: 2,
            apple: 3,
            google: 1,
            twitter: 6,
        };
        const { mobile, mail, phone_code, password, verificationCode } = values;
        const { thirdAccounts = [] } = JSON.parse(localStorage.getItem('userInfo') || '{}');

        let verifyParam;
        switch (currentFormType) {
            case 'phone':
                verifyParam = { mobile, verificationCode, phoneCode: phone_code };
                break;
            case 'email':
                verifyParam = {
                    mail,
                    verificationCode,
                };
                break;
            default:
                verifyParam = {};
        }

        const params = {
            uid: thirdAccounts.find((item: any) => {
                return item.type === types[loginType];
            })?.thirdUniqueAcount,
            type: thirdAccounts.find((item: any) => {
                return item.type === types[loginType];
            })?.type,

            ...verifyParam,
        };
        bindingAccount(params as any)
            .then((res: any) => {
                const { state, data } = res;

                if (Number(state) === 1) {
                    const { type: bindType, uauthCode } = data || {};
                    // 绑定密码
                    if (bindType === 1) {
                        setLoading(true);
                        updataPassword({ password, verify: data?.verify })
                            .then((res: any) => {
                                const { state } = res;
                                if (Number(state) === 1) {
                                    postLoginApi({
                                        ...params,
                                        login_type: 0,
                                        password,
                                    })
                                        .then((res2: any) => {
                                            postLoginCallback(res2, router, setFormType, setCookie, onCancel);
                                        })
                                        .finally(() => {
                                            setLoading(false);
                                        });
                                }
                            })
                            .catch(() => {
                                setLoading(false);
                            });
                    } else {
                        localStorage.setItem('Authorization', uauthCode);
                        setCookie?.('AUTH_TOKEN', uauthCode, { path: '/', sameSite: true });

                        if (router.asPath.includes('/login/callback')) {
                            window.history.go(-2); // 返回两级，跳过第三方登录页
                        } else {
                            router.reload();
                        }
                    }
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const onBindFinish = (values: any) => {
        setLoading(true);
        BindHandler(values);
    };
    // —————————————————————— 绑定提交 ————————————————————
    // —————————————————————— 登录提交 ————————————————————
    // 登录校验
    const loginHandler = (values: any) => {
        delete values.countryCode;
        postLoginApi({ ...values, login_type: verifyType === 'code' ? 4 : 0 })
            .then((res: any) => {
                postLoginCallback(res, router, setFormType, setCookie, onCancel);
            })
            .finally(() => {
                setLoading(false);
            });
    };
    // 找回密码校验
    const findPwdHandler = (values: any) => {
        const { mobile, mail, phone_code, verificationCode } = values;
        let params: any;

        switch (currentFormType) {
            case 'phone':
                params = { mobile, verificationCode, phoneCode: phone_code };
            case 'email':
                params = {
                    mail,
                    verificationCode,
                };
        }

        verificationAuthCode(params)
            .then((res: any) => {
                const { state, data } = res;
                setLoading(false);
                if (Number(state) === 1) {
                    switch (currentFormType) {
                        case 'phone':
                            localStorage.setItem('userInfo', JSON.stringify({ mobile, phoneCode: phone_code }));
                        case 'email':
                            localStorage.setItem('userInfo', JSON.stringify({ mail }));
                    }
                    goResetPassword(data?.verify);
                }
            })
            .catch(() => {
                setLoading(false);
            });
    };
    /** 表单提交 */
    const onFormFinish = (values: any) => {
        setLoading(true);
        if (formType === 'bind') {
            onBindFinish(values);
            return;
        }
        switch (formType) {
            case 'normal':
                loginHandler(values);
                break;
            case 'forget':
                findPwdHandler(values);
                break;
            case 'set':
                setPwdHandler(values);
                break;
        }
    };
    // —————————————————————— 登录提交 ————————————————————

    /** 重置验证码/密码 */
    const resetPassword = () => {
        let from = currentFormType === 'phone' ? phoneForm : emailForm;
        from?.setFieldsValue({
            verificationCode: '',
            password: '',
        });
    };

    /** 前往重置密码 */
    const goResetPassword = (verify: any) => {
        setFormType('set');
        setVerify(verify);
        resetPassword();
    };

    return {
        currentFormType,
        setCurrentFormType,
        /** 手机表单 */
        phoneForm,
        /** 邮箱表单 */
        emailForm,
        /** 验证类型 */
        verifyType,
        /** 设置验证类型 */
        setVerifyType,
        /** 密码类型 */
        formType,
        /** 设置密码类型 */
        setFormType,
        /** 加载状态 */
        loading,
        /** 设置加载状态 */
        setLoading,
        /** 登录提交 */
        onFormFinish,
        /** 绑定提交 */
        onBindFinish,
        /** 提交按钮文案 */
        loginBtnText,
        /** 身份证明 */
        verify,
        /** 重置验证码/密码 */
        resetPassword,
    } as LoginContextType;
};

export default useLoginForm;
