import React from 'react';
import { Modal } from 'antd';
import { NextImage } from '@/components';
import type { ModalProps } from 'antd/es/modal';
import intl from 'react-intl-universal';
import styles from './index.module.less';

export const ModalStyle: React.FC<ModalProps> = (props) => {
    const { open, children, onCancel, ...modalProps } = props;

    return (
        <Modal
            open={open}
            okText={intl.get('Common.Determine').d('确定')}
            cancelText={intl.get('Common.Cancel').d('取消')}
            width={390}
            centered
            closable={false}
            destroyOnClose
            onCancel={onCancel}
            className={styles.custom_modal}
            {...modalProps}
        >
            {children}
        </Modal>
    );
};

export const TitleRender: React.FC<ModalProps> = (props) => {
    const { title } = props;

    return (
        <div className={styles.title__wrapper}>
            <div className={styles.warning__icon}>
                <NextImage src="/icon/warning.webp" layout="fill" needPrefix />
            </div>
            <p>{title}</p>
        </div>
    );
};
