import TransferCom from '@/components/public/TransferCom';
import MBrokerItem from '../MBrokerItem';
import PCBrokerItem from './pc';
import type { BrokerItemProps } from '../type';

/**
 * 榜单样式
 * @param props
 * @returns
 */
const BrokerItemA: React.FC<BrokerItemProps> = (props) => {
    return (
        <TransferCom
            m={<MBrokerItem {...props} />}
            ipad={<PCBrokerItem {...props} />}
            pc={<PCBrokerItem {...props} />}
        />
    );
};

export default BrokerItemA;
