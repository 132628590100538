import React, { useMemo, useRef, useState } from 'react';
import c from 'classnames';
import { NextImage, Scrollbar } from '@/components';
import intl from 'react-intl-universal';
import { currentLocaleUrl } from '@/utils';
import BrokerSelect from '../BrokerSelect';
import type { CommonProps } from '../../types';
import styles from './index.module.less';

/**
 * 展开样式
 * @param param
 * @returns
 */
const Expand: React.FC<CommonProps> = ({
    onRef,
    imgPrefix,
    recommendList = [],
    contrastList = [],
    onOpen,
    onAddItem,
    onDelItem,
    onGoToDetail,
    onGoToContrast,
    setContrastList,
}) => {
    // select
    const parentRef = useRef<any>(null);

    // 数据展示
    const noData = useMemo(() => contrastList.length === 0, [contrastList]);
    const threeData = useMemo(() => contrastList.length === 3, [contrastList]);

    // 鼠标按下
    const [mouseDownId, setMouseDownId] = useState<string>('');
    // error msg
    const [errorMsg, setErrorMsg] = useState<string>('');

    /**
     * 鼠标按下
     * @param event
     * @param id
     */
    const onMouseDown = (event: any, id: string) => {
        event?.stopPropagation();
        event?.nativeEvent?.stopImmediatePropagation();
        setMouseDownId(id);
    };

    /**
     * 清空交易商
     */
    const onClearBroker = () => {
        setContrastList([]);
        setErrorMsg('');
    };

    /**
     * 推荐列表添加交易商
     * @param item
     * @returns
     */
    const onRecommendAddFun = (item: any) => {
        if (threeData) {
            return;
        }
        onAddItem(item, setErrorMsg);
    };

    /**
     * 删除某个交易商
     * @param id
     */
    const onDelItemFun = (id: string) => {
        onDelItem(id);
        setErrorMsg('');
    };

    return (
        <div className={styles.expand}>
            <div className={styles.header}>
                <div className={styles.headerText}>
                    <span>{intl.get('Broker.BrokerComparison').d('交易商对比')}</span>
                    <div className={styles.openBtn} onClick={() => onOpen(false)}>
                        <NextImage src={`${imgPrefix}/broker/icon/packup.webp`} width={20} height={20} alt="packup" />
                    </div>
                </div>
                <p>{intl.get('Broker.UpToThree').d('最多可添加3个交易商')}</p>
            </div>
            <div className={styles.contrast}>
                {contrastList.length !== 0 && (
                    <ul className={styles.contrastList}>
                        {contrastList.map((item: any) => {
                            const { id, logo, score, name_abbreviation } = item;
                            return (
                                <li key={id}>
                                    <div
                                        onClick={() => onGoToDetail(item)}
                                        onMouseDown={(e) => onMouseDown(e, id as string)}
                                        onMouseUp={() => setMouseDownId('')}
                                        onMouseLeave={() => setMouseDownId('')}
                                        className={c(styles.brokerBtn, id === mouseDownId ? styles.mouseDown : null)}
                                    >
                                        <div className={styles.logo}>
                                            <NextImage
                                                className={styles.img}
                                                src={logo}
                                                layout="fill"
                                                alt={name_abbreviation}
                                                place="logo_a"
                                            />
                                        </div>

                                        <p className={styles.name} title={name_abbreviation}>
                                            {name_abbreviation}
                                        </p>

                                        <span className={styles.num}>{Number(score || 0).toFixed(1)}</span>
                                    </div>

                                    <div className={styles.closeIconBox}>
                                        <span
                                            onClick={() => onDelItemFun(String(id))}
                                            className={c(styles.closeIcon, 'iconfont', 'icon-shanchu')}
                                        />
                                    </div>
                                </li>
                            );
                        })}
                    </ul>
                )}

                {errorMsg ? <p className={styles.errorMsg}>{errorMsg}</p> : null}

                <div className={styles.selBroker} ref={parentRef}>
                    <BrokerSelect
                        onRef={onRef}
                        parentRef={parentRef}
                        contrastList={contrastList}
                        onAddItem={onAddItem}
                        setErrorMsg={setErrorMsg}
                    />
                </div>

                <div className={styles.contrastBtnBox}>
                    <div
                        className={c({ [styles.contrastBtn]: true, [styles.noData]: noData })}
                        onClick={onGoToContrast}
                    >
                        <span className={c({ [styles.contrastText]: true, [styles.contrastTextHover]: !noData })}>
                            {intl.get('Broker.StartCompare').d('开始对比')}
                        </span>
                    </div>
                </div>
                <div className={styles.clearBox}>
                    <span
                        className={c({ [styles.clearBtn]: true, [styles.clearBtnHover]: !noData })}
                        onClick={onClearBroker}
                    >
                        <span className={c(styles.clearIcon, 'iconfont', 'icon-clear')} />
                        <span>{intl.get('Broker.ClearAllBroker').d('清空所有交易商')}</span>
                    </span>
                </div>
            </div>
            {recommendList.length !== 0 && (
                <>
                    <div className={styles.midTip}>{intl.get('Broker.RecommendedComparison').d('推荐对比')}</div>
                    <div className={styles.recBox}>
                        <Scrollbar
                            noScrollX
                            trackYClassName={styles.recScrollTrackY}
                            thumbYClassName={styles.recScrollThumbY}
                        >
                            <ul className={styles.recList}>
                                {recommendList.map((item: any) => {
                                    const { id, logo, name_abbreviation, seo_url } = item;
                                    return (
                                        <li key={id} title={name_abbreviation}>
                                            <a
                                                className={styles.recBroker}
                                                href={currentLocaleUrl(`/broker/${seo_url || id}`)}
                                                target="_blank"
                                            >
                                                <div className={styles.logo}>
                                                    <NextImage
                                                        className={styles.img}
                                                        src={logo}
                                                        layout="fill"
                                                        alt={name_abbreviation}
                                                        place="logo_a"
                                                    />
                                                </div>

                                                <p className={styles.name} title={name_abbreviation}>
                                                    {name_abbreviation}
                                                </p>
                                            </a>

                                            <div
                                                className={c({ [styles.addBtn]: true, [styles.addBtnDis]: threeData })}
                                                onClick={() => onRecommendAddFun(item)}
                                            >
                                                <span
                                                    className={c({
                                                        [styles.addIcon]: true,
                                                        [styles.addIconDis]: threeData,
                                                        iconfont: true,
                                                        'icon-jiahao': true,
                                                    })}
                                                />
                                                <span>{intl.get('Search.Compared').d('对比')}</span>
                                            </div>
                                        </li>
                                    );
                                })}
                            </ul>
                        </Scrollbar>
                    </div>
                </>
            )}
        </div>
    );
};

export default Expand;
