import React from 'react';
import TransferCom from '@/components/public/TransferCom';
import MMain from './m';
import PcMain from './pc';

import type { MainProps } from './types';

const Main: React.FC<MainProps> = (props) => {
    return <TransferCom m={<MMain {...props} />} ipad={<PcMain {...props} />} pc={<PcMain {...props} />} />;
};

export default Main;
