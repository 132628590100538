import type { FormInstance } from 'antd';
import React, { Dispatch, SetStateAction } from 'react';

/** 表单类型 手机、邮箱、忘记密码、设置密码 */
export type LoginFromType = 'phone' | 'email';
/** 表单类型 默认、忘记密码、设置密码、绑定 */
export type LoginFormType = 'normal' | 'forget' | 'set' | 'bind';
/** 登录密码类型 验证码、密码 */
export type LoginVerifyType = 'code' | 'password';

export interface LoginContextType {
    /** 当前表单类型 */
    currentFormType: LoginFromType;
    /** 设置当前表单类型 */
    setCurrentFormType: React.Dispatch<React.SetStateAction<LoginFromType>>;
    /** 验证类型 */
    verifyType: LoginVerifyType;
    /** 设置验证类型 */
    setVerifyType: Dispatch<SetStateAction<LoginVerifyType>>;
    /** 密码类型 */
    formType: LoginFormType;
    /** 设置验证类型 */
    setFormType: Dispatch<SetStateAction<LoginFormType>>;
    /** 设置加载状态 */
    loading: boolean;
    /** 登录提交 */
    onFormFinish: (values: any) => void;
    /** 绑定提交 */
    onBindFinish: (values: any) => void;
    /** 提交按钮文案 */
    loginBtnText: string;
    /** 手机表单 */
    phoneForm?: FormInstance<any>;
    /** 邮箱表单 */
    emailForm?: FormInstance<any>;
    /** 身份证明 */
    verify: string;
    /** 重置验证码/密码 */
    resetPassword: () => void;
}

export const LoginContext = React.createContext<LoginContextType>({
    currentFormType: 'phone',
    setCurrentFormType: () => {},
    /** 设置加载状态 */
    loading: false,
    /** 登录提交 */
    onFormFinish: () => {},
    /** 绑定提交 */
    onBindFinish: () => {},
    /** 验证类型 */
    verifyType: 'code',
    /** 设置验证类型 */
    setVerifyType: () => {},
    /** 密码类型 */
    formType: 'normal',
    /** 设置密码类型 */
    setFormType: () => {},
    /** 提交按钮文案 */
    loginBtnText: '',
    /** 手机表单 */
    phoneForm: undefined,
    /** 邮箱表单 */
    emailForm: undefined,
    /** 身份证明 */
    verify: '',
    /** 重置验证码/密码 */
    resetPassword: () => {},
});
