import React, { useMemo } from 'react';
import dayjs from 'dayjs';
import { useAppSelector } from '@/store/hook';
import Header from '../components/Header';
import NavBar from '../components/NavBar';
import Main from '../components/Main';
import Footer from '../components/Footer';
import c from 'classnames';
import styles from './index.module.less';

const Layout: React.FC<any> = (props: any) => {
    const {
        globalData = {},
        showAdvertising = false,
        showTopTW = false,
        leftAdvertisingCode,
        rightAdvertisingCode,
        topTWCode,
    } = props?.children?.props || {};

    const { baseConfig = {}, menus = [] } = globalData;
    const { topHeaderHeight: topHeight } = useAppSelector((state: any) => state.config);

    const topBannerData = useMemo(() => {
        const { starttime, endtime, top_pc_banner } = baseConfig;
        const currentDay = dayjs();
        return {
            visible: currentDay.isBefore(dayjs(endtime)) && currentDay.isAfter(dayjs(starttime)),
            url: top_pc_banner,
        };
    }, [baseConfig]);

    return (
        <div className={c({ [styles.wrapper]: true })} style={{ paddingTop: topHeight }}>
            <Header
                menus={menus}
                logo={baseConfig.logo}
                download={baseConfig.download}
                topBanner={topBannerData}
                showTopTW={showTopTW}
                topTWCode={topTWCode}
            />

            <Main
                child={props?.children}
                showAdvertising={showAdvertising}
                leftAdvertisingCode={leftAdvertisingCode}
                rightAdvertisingCode={rightAdvertisingCode}
            />

            <NavBar menus={menus} config={baseConfig} />

            <Footer config={baseConfig} />
        </div>
    );
};

export default Layout;
