import React, { memo, useMemo } from 'react';
import { NextImage } from '@/components';
import styles from './index.module.less';
import c from 'classnames';

interface Props {
    /** 评级 */
    grade: number
    /** 容器classname */
    wrapClassname?: string
    /** 内容classname */
    itemClassname?: string
}

type Star = 'empty' | 'half' | 'full'

const StarLv: React.FC<Props> = ({
    grade,
    wrapClassname = '',
    itemClassname = ''
}) => {
    /** 星级 */
    const startMap: Star[] = useMemo(() => {
        return Array(5).fill('').map((_, index) => {
            // 一个星
            if (grade >= (index + 1)) {
                return 'full';
            }
            if (grade < (index + 1) && grade >= (index + 0.5)) {
                return 'half';
            }
            return 'empty'
        })
    }, [grade]);

    const newStartMap = useMemo(() => {
        return startMap.map((item, index) => ({ value: item, key: index }))
    }, [startMap])

    return (
        <div className={c(styles.grade__warpper, wrapClassname)}>
            {
                newStartMap.map((item) => (
                    <em className={c(styles.star, itemClassname)} key={item.key} >
                        <NextImage layout='fill'
                            src={`/broker/icon/star-${item.value}.webp`}
                            needPrefix
                            alt='star'
                        />
                    </em>
                ))
            }
        </div>
    );
}

export default memo(StarLv);