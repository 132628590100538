import React, { useEffect, useContext } from 'react';
import { Button, Form, Input, Select } from 'antd';

import AuthCode from '@/components/public/AuthCode';
import { useAppDispatch, useAppSelector } from '@/store/hook';
import intl from 'react-intl-universal';
import formUtils from '@/utils/form';

import styles from './index.module.less';
import { LoginContext } from '../../content';
import { NextImage } from '@/components';
import { getPhoneCodeListAsync } from '@/store/config';
import BindAccount from '../BindAccount';

const { pleaseEnter } = formUtils;
const { Password } = Input;

const Mobile = () => {
    const { loading, verifyType, formType, loginBtnText, phoneForm, onFormFinish } = useContext(LoginContext);
    const dispatch = useAppDispatch();

    const { phoneCodeItem, phoneCodeList = [] } = useAppSelector((state) => state.config);

    const validateMobile = () => {
        return phoneForm?.validateFields(['mobile', 'phone_code']);
    };

    useEffect(() => {
        dispatch(getPhoneCodeListAsync());
    }, [dispatch]);

    // 禁用提交按钮
    const btnDisabel = (value: any) => {
        const { verificationCode, password, confirm, mobile } = value;

        // 绑定流程的额外判断
        const bindDisable =
            formType === 'bind' ? !confirm || !password || !(verificationCode && verificationCode.length === 6) : false;

        return (!(verificationCode && verificationCode.length === 6) && !password) || !mobile || bindDisable;
    };

    useEffect(() => {
        const { country, phoneCode } = phoneCodeItem;
        phoneForm?.setFieldsValue({ countryCode: `${country}.${phoneCode}`, phone_code: phoneCode });
    }, [phoneForm, phoneCodeItem]);

    return (
        <div className={styles.mobile}>
            <Form
                form={phoneForm}
                autoComplete="off"
                size="large"
                className={styles.form}
                initialValues={{ phone_code: phoneCodeItem?.phoneCode }}
                onFinish={onFormFinish}
            >
                <Form.Item
                    style={{ marginBottom: 40 }}
                    name="mobile"
                    rules={[{ required: true, message: pleaseEnter(intl.get('Setting.PhoneNum').d('请输入手机号码')) }]}
                >
                    <Input
                        className={styles.mobileInput}
                        addonBefore={
                            <Form.Item name="phone_code" noStyle>
                                <Select
                                    showSearch
                                    showArrow={false}
                                    filterOption={(val, option) => {
                                        return option.label.indexOf(val) > -1;
                                    }}
                                    dropdownMatchSelectWidth={200}
                                    style={{ minWidth: 70 }}
                                    className={styles.phone_code}
                                    options={phoneCodeList}
                                    optionLabelProp="oLabel"
                                ></Select>
                            </Form.Item>
                        }
                        placeholder={pleaseEnter(intl.get('Setting.PhoneNum').d('请输入手机号码'))}
                    />
                </Form.Item>
                <Form.Item name="countryCode" hidden>
                    <Input />
                </Form.Item>
                {verifyType === 'code' && (
                    <Form.Item
                        style={{ marginBottom: 40 }}
                        name="verificationCode"
                        rules={[
                            {
                                required: true,
                                message: pleaseEnter(intl.get('Common.VerificationCode').d('请输入验证码')),
                            },
                            {
                                len: 6,
                                message: pleaseEnter(intl.get('Common.SixVerificationCode').d('请输入6位长度的验证码')),
                            },
                        ]}
                    >
                        <Input
                            placeholder={pleaseEnter(intl.get('Common.VerificationCode').d('请输入验证码'))}
                            maxLength={6}
                            type="number"
                            suffix={<AuthCode validate={validateMobile} />}
                        />
                    </Form.Item>
                )}
                {verifyType === 'password' && (
                    <Form.Item
                        style={{ marginBottom: 40 }}
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: pleaseEnter(intl.get('BindAccount.Password').d('请输入密码')),
                            },
                        ]}
                    >
                        <Password
                            autoComplete="new-password"
                            className={styles.pwd}
                            iconRender={(iconVisible) =>
                                iconVisible ? (
                                    <NextImage
                                        src="/img/login/pwd_active.webp"
                                        width={22}
                                        style={{ cursor: 'pointer' }}
                                        height={22}
                                        alt="pwd_active"
                                    />
                                ) : (
                                    <NextImage
                                        style={{ cursor: 'pointer' }}
                                        src="/img/login/pwd.webp"
                                        width={22}
                                        height={22}
                                        alt="pwd"
                                    />
                                )
                            }
                            placeholder={pleaseEnter(intl.get('BindAccount.Password').d('请输入密码'))}
                        />
                    </Form.Item>
                )}

                {['bind'].includes(formType) && <BindAccount />}

                <Form.Item style={{ marginBottom: 16 }} shouldUpdate>
                    {({ getFieldsValue }) => {
                        return (
                            <Button
                                className={styles.btn}
                                type="primary"
                                htmlType="submit"
                                block
                                size="large"
                                disabled={btnDisabel(getFieldsValue())}
                                loading={loading}
                                aria-label="Registration Or Login"
                            >
                                {loginBtnText}
                            </Button>
                        );
                    }}
                </Form.Item>
            </Form>
        </div>
    );
};

export default Mobile;
