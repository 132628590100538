import React from 'react';
import TransferCom from '@/components/public/TransferCom';
import MNavBar from './m';
import PcNavBar from './pc';

import { NavBarProps } from './types';

const NavBar: React.FC<NavBarProps> = (props) => {
    return <TransferCom m={<MNavBar {...props} />} ipad={<PcNavBar {...props} />} pc={<PcNavBar {...props} />} />;
};

export default NavBar;
