import React, { useRef, useMemo } from 'react';
import c from 'classnames';
import RenderItem from './renderItem';
import styles from './index.module.less';
import type { ArticlesListPros } from '../type';

const H5ArticlesList: React.FC<ArticlesListPros> = (props) => {
    const { options = [] } = props;

    const domRef = useRef<any>(null);

    const list = useMemo(() => options?.slice(0, 9), [options]);

    return (
        <div className={styles.articleContain} ref={domRef}>
            <div className={c(styles.articleList, styles.listOne)}>
                {list.map((item: any, index: number) => {
                    const { thumbnail_pics = [] } = item || {};
                    const thumbnail = thumbnail_pics[0] || {};
                    if (index > 2) {
                        return null;
                    }
                    return (
                        <div
                            key={`${item.code || item.union_id || item._id}_${index}`}
                            className={c(styles.articleItem, thumbnail.url ? styles.typeOne : null)}
                        >
                            <RenderItem item={item} />
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default H5ArticlesList;
