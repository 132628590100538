import React from 'react';
import TransferCom from '@/components/public/TransferCom';
import MHeaderNav from './m';
import PcHeaderNav from './pc';

import type { HeaderNavProps } from './types';

const HeaderNav: React.FC<HeaderNavProps> = (props) => {
    return (
        <TransferCom m={<MHeaderNav {...props} />} ipad={<PcHeaderNav {...props} />} pc={<PcHeaderNav {...props} />} />
    );
};

export default HeaderNav;
