import React from 'react';
import intl from 'react-intl-universal';
import c from 'classnames';

import { useAppSelector } from '@/store/hook';

import styles from './index.module.less';
import NextImage from '../NextImage';

const NoContent = ({ minHeight = 300 }: { minHeight?: number }) => {
    const { showBarkly } = useAppSelector((state: any) => {
        return state.config;
    });

    return (
        <div
            style={{ minHeight: minHeight }}
            className={c(styles.wrapper__no_content, { [styles.barkly_container]: showBarkly })}
        >
            <div className={styles.wrapper__img}>
                <NextImage src="/img/place/null_data_new.webp" layout="fill" />
            </div>
            <div className={styles.wrapper__text}>{intl.get('Common.NoContent').d('暂无内容')}</div>
        </div>
    );
};

export default NoContent;
