import React, { useState } from 'react';
import { useRouter } from 'next/router';

import { NextImage } from '@/components';
import { langItems } from '@/constants/mts';

import styles from './index.module.less';
import type { MtsLangProps } from '../types';

const MtsLang: React.FC<MtsLangProps> = (props) => {
    const { locale } = props;
    const router = useRouter();

    const [nextIndex, setNextIndex] = useState<number>(locale === 'zh-CN' ? 1 : 0);

    // h5 语言
    const handleLangClick = (i: number) => {
        const nowItem = langItems[i];
        router.replace(router.asPath, router.asPath, { locale: nowItem.key });
        setNextIndex(1 - i);
    };

    return (
        <div className={styles.lang__icon} onClick={() => handleLangClick(nextIndex)}>
            <NextImage
                src={`/icon/${langItems[nextIndex].key}.webp`}
                needPrefix
                width={20}
                height={20}
                alt={langItems[nextIndex].key}
            />
        </div>
    );
};

export default MtsLang;
