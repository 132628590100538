import React from 'react';

import { NextImage } from '@/components';

import styles from './index.module.less';
import intl from 'react-intl-universal';
import { currentLocaleUrl } from '@/utils';
interface Props {
    list: any[];
}

const HotNews: React.FC<Props> = ({ list = [] }) => {
    return (
        <div className={styles.hotNews}>
            <span className={styles.watermark} />
            <div className={styles.hotNewsCon}>
                <h2 className={styles.hotNewsTit}>
                    <span>{intl.get('Article.HotRanking').d('热榜排行')}</span>
                    <NextImage src="/icon/hot.webp" needPrefix width={28} height={28} alt="hot" />
                </h2>
                <ul className={styles.hotNewsList}>
                    {[...list].map((item: any, i: number) => {
                        const { union_id, code, seo_url, cover = [], originate, ugc = {}, title } = item;
                        return (
                            <li key={union_id}>
                                <a href={currentLocaleUrl(`/article/${seo_url || code}`)} target="_blank">
                                    <div className={styles.itemCover}>
                                        <NextImage
                                            src={(cover || [])[0]?.url}
                                            alt={title}
                                            place="normal"
                                            objectFit="cover"
                                            width={90}
                                            height={70}
                                        />
                                        <span className={styles.rank}>{i + 1}</span>
                                    </div>
                                    <div className={styles.itemContain}>
                                        <div className={styles.itemTitle}>{title}</div>
                                        <div className={styles.itemMsg}>{ugc && ugc.name ? ugc.name : originate}</div>
                                    </div>
                                </a>
                            </li>
                        );
                    })}
                </ul>
            </div>
        </div>
    );
};

export default HotNews;
