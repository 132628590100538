import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useRouter } from 'next/router';
import c from 'classnames';
import { setRootId, setActiveNavId, setActiveNavKeys, setMenuOpen } from '@/store/config';
import { useAppSelector, useAppDispatch } from '@/store/hook';
import { formatMts, currentLocaleUrl } from '@/utils';
import { getRootItemId, getSelectKeysItem } from '@/utils/home';
import { menuExtraList } from '../config';
import { Menu } from 'antd';
import { cloneDeep } from 'lodash';
import intl from 'react-intl-universal';
import type { MenuProps } from 'antd';
import type { NavBarProps } from '../types';
import styles from './index.module.less';

const NavBar: React.FC<NavBarProps> = (props) => {
    const { menus = [] } = props;
    const router = useRouter();
    const dispatch = useAppDispatch();
    const currentLocale = intl.getInitOptions().currentLocale;

    const {
        topHeaderBoxHeight: topHeight,
        menuOpen,
        activeNavId,
        activeNavKeys,
    } = useAppSelector((state: any) => state.config);

    // 默认展开菜单
    const [openKeys, setOpenKeys] = useState<string[]>([]);

    // 是否登录
    const [isLogin, setIsLogin] = useState<boolean>(true);

    useEffect(() => {
        setIsLogin(!!localStorage?.getItem('Authorization'));
    }, []);

    useEffect(() => {
        dispatch(setRootId(getRootItemId(menus)));
    }, [dispatch, menus]);

    useEffect(() => {
        setOpenKeys(activeNavKeys);
    }, [activeNavKeys]);

    // sub点击
    const onTitleClick: MenuProps['onClick'] = useCallback(
        (menuProps: { key: any }) => {
            const { key } = menuProps;
            const cloneList = cloneDeep(openKeys);
            if (cloneList.includes(key)) {
                setOpenKeys([]);
            } else {
                setOpenKeys([key]);
            }
        },
        [openKeys],
    );

    const formatMenus: any = useCallback(
        (l: any[]) => {
            if (l.length !== 0) {
                return l.map((item: any) => {
                    const { id, inventedUrl, name, nameMts, children = [] } = item || {};
                    const navMtsName = formatMts(nameMts) || name;

                    return {
                        key: inventedUrl || id,
                        label: navMtsName,
                        children: children.length !== 0 ? formatMenus(children || []) : undefined,
                        // 有二级导航，暂时不能点击前往一级导航
                        // onTitleClick: (menuProps: any) => {
                        //     const { key } = menuProps;
                        //     onMenuItemClick({ ...menuProps, keyPath: [key] });
                        // },
                        onTitleClick,
                    } as any;
                });
            } else {
                return [];
            }
        },
        [onTitleClick],
    );

    // 自定义menus
    const newMenus: MenuProps['items'] = useMemo(() => {
        return formatMenus(menus, true);
    }, [currentLocale, menus, openKeys]);

    /**
     * 禁用/启用页面滚动
     */
    useEffect(() => {
        if (menuOpen) {
            document.body.style.height = '100vh';
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.height = 'auto';
            document.body.style.overflow = 'auto';
        }
    }, [menuOpen]);

    const toLogin = () => {
        (window as any)?.onLogin?.();
    };

    /**
     * 渲染一级菜单每项
     */
    // const toKeyPath = useCallback((item: any, isIndex: boolean) => {
    //     const { id, web, type, inventedUrl } = item;
    //     switch (type) {
    //         case 'page':
    //             router.push(currentLocaleUrl(isIndex ? '/' : `/${inventedUrl || id}`));
    //             break;
    //         case 'web':
    //             const { url } = web;
    //             window.open(currentLocaleUrl(url));
    //             break;
    //         default:
    //             break;
    //     }
    // }, []);

    const toKeyPath = useCallback((item: any, isIndex?: boolean) => {
        const { id, parentId, web, type, inventedUrl } = item;
        const index = (menus || []).findIndex((m: any) => [m?.inventedUrl || m.id, m.id].includes(parentId));

        switch (type) {
            case 'page':
                dispatch(setActiveNavId(inventedUrl || id));
                const parentItem = menus.find((pItem: any) => pItem.id === parentId) || {};
                const pathKeys = [inventedUrl || id];
                if (parentItem?.id) {
                    pathKeys.unshift(parentItem?.inventedUrl || parentItem?.id);
                }
                dispatch(setActiveNavKeys(pathKeys));
                router.push(currentLocaleUrl(isIndex && index === 0 ? '/' : `/${pathKeys.join('/')}`));
                break;
            case 'web':
                const { url } = web;
                window.open(currentLocaleUrl(url));
                break;
            default:
                break;
        }
    }, []);

    // 菜单点击
    const onMenuItemClick: MenuProps['onClick'] = (menuProps) => {
        const { keyPath = [] } = menuProps;
        const selectItem = getSelectKeysItem(menus, keyPath, keyPath.length - 1);

        dispatch(setMenuOpen(false));
        if (selectItem?.type !== 'web') {
            const pathKeys = (keyPath || []).reverse();
            dispatch(setActiveNavKeys(pathKeys));
            dispatch(setActiveNavId(selectItem?.id));
        }

        toKeyPath(selectItem, selectItem?.isIndex);
    };

    return (
        <aside
            className={c(styles.menuDrawer, menuOpen ? styles.menuOpen : null)}
            style={{ top: menuOpen ? topHeight : '100vh', height: `calc(100vh - ${topHeight}px)` }}
        >
            {!isLogin && (
                <div className={styles.login_box}>
                    <div className={styles.go_login} onClick={toLogin}>
                        <span>{intl.get('Header.LogToSeeAll').d('现在登录，畅想全部内容')}</span>
                        <span className={c('iconfont', 'icon-jiantou', styles.icon)} />
                    </div>
                </div>
            )}

            <Menu
                onSelect={onMenuItemClick}
                mode="inline"
                items={newMenus}
                className={styles.menus}
                selectedKeys={[activeNavId]}
                openKeys={openKeys}
                expandIcon={(props) => {
                    const { isOpen } = props;
                    return <span className={c('iconfont', 'icon-jiantou', styles.icon, { [styles.open]: isOpen })} />;
                }}
            />

            {/* <Collapse
                defaultActiveKey={[activeNavId]}
                ghost
                expandIconPosition="end"
                className={styles.collapse}
                expandIcon={(props: any) => {
                    const { isActive } = props;
                    return <span className={c('iconfont', 'icon-jiantou', styles.icon, { [styles.open]: isActive })} />;
                }}
            >
                {menus.map((firstItem, i: number) => {
                    const { id, children = [] } = firstItem || {};

                    return (
                        <Panel header={navItemRender(firstItem, i === 0)} key={id} showArrow={children.length !== 0}>
                            {children.length !== 0 ? (
                                <ul className={styles.child_list}>
                                    {children.map((secondItem: any) => {
                                        const { id: childId, inventedUrl: childInventedUrl } = secondItem || {};
                                        return (
                                            <li
                                                className={c(styles.child_item, {
                                                    [styles.s_active]: [childInventedUrl, childId].includes(
                                                        activeNavId,
                                                    ),
                                                })}
                                                key={childId}
                                            >
                                                {navItemRender(secondItem)}
                                            </li>
                                        );
                                    })}
                                </ul>
                            ) : null}
                        </Panel>
                    );
                })}
            </Collapse> */}

            <ul className={styles.list}>
                {menuExtraList.map((item) => {
                    return (
                        <li className={styles.item} key={item.link}>
                            <a href={currentLocaleUrl(item.link)} target="_blank">
                                <span>{intl.get(item.name).d(item.alias)}</span>
                            </a>
                        </li>
                    );
                })}
            </ul>
        </aside>
    );
};

export default NavBar;
