import TransferCom from '../TransferCom';

import H5NavFlash from './h5';
import PCNavFlash from './pc';
import type { NavFlashPros } from './type';

const NavFlash = (props: NavFlashPros) => {
    return (
        <TransferCom m={<H5NavFlash {...props} />} ipad={<PCNavFlash {...props} />} pc={<PCNavFlash {...props} />} />
    );
};

export default NavFlash;
