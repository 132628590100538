import React from 'react';
import TransferCom from '@/components/public/TransferCom';
import MHeader from './m';
import PcHeader from './pc';

import type { HeaderProps } from './types';

const Header: React.FC<HeaderProps> = (props) => {
    return <TransferCom m={<MHeader {...props} />} ipad={<PcHeader {...props} />} pc={<PcHeader {...props} />} />;
};

export default Header;
