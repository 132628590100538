import React from 'react';
import c from 'classnames';
import _ from 'lodash';

import { NextImage } from '@/components';
import { formatTimer, currentLocaleUrl, getArticleViewNum } from '@/utils';
import { IProps } from '../type';

import styles from './index.module.less';
import intl from 'react-intl-universal';

const SearchArticleList: React.FC<IProps> = (props) => {
    const { dataSource, isViewNum = false, isShowUgc = true, onCollectItem } = props;
    const { title, code, seo_url, category, rule_name, view_num, ugc, datetime, thumbnail_pics = [] } = dataSource;

    return (
        <div className={c(styles.searchWrapper, props.className || '')} key={code}>
            {/* 图片&视频展示 */}
            {thumbnail_pics[0]?.url && (
                <a target="_blank" href={currentLocaleUrl(`/article/${seo_url || code}`)}>
                    <div className={c(styles.searchImage, category == 'video' && styles.searchVideoImg)}>
                        <NextImage src={thumbnail_pics[0]?.url} layout="fill" alt={title} />
                        {category === 'video' && (
                            <div className={styles.video_play_btn}>
                                <NextImage src="/img/video/play.webp" width={18} height={18} alt="play" />
                            </div>
                        )}
                    </div>
                </a>
            )}

            {/* 右侧标题简介区域 */}
            <div className={styles.searchMain}>
                {/* 标题&简介*/}
                <a target="_blank" href={currentLocaleUrl(`/article/${seo_url || code}`)} className={styles.linkBox}>
                    <div className={styles.titleWrapper}>
                        {/* 标题 */}
                        <h2 className={styles.searchTitle} dangerouslySetInnerHTML={{ __html: title || '' }} />
                    </div>
                </a>

                {/* 底部 */}
                <div className={styles.searchBottom}>
                    <div className={styles.searchBottomLeft}>
                        {isShowUgc && (
                            <>
                                <span className={styles.searchRuleName}>{ugc?.name || rule_name || ''}</span>
                                <i />
                            </>
                        )}
                        <span className={styles.searchTime}>{formatTimer(datetime)}</span>
                        {isViewNum && (
                            <span className={styles.view_num}>
                                <span className={c(styles.icon, 'iconfont', 'icon-liulanliang')} />
                                <span>{getArticleViewNum(view_num || 0, 'w')}</span>
                            </span>
                        )}
                    </div>
                    {onCollectItem && (
                        <span className={styles.collect} onClick={onCollectItem}>
                            {intl.get('Common.Delete').d('删除')}
                        </span>
                    )}
                </div>
            </div>
        </div>
    );
};

export default SearchArticleList;
