import React, { useMemo, useCallback, useState } from 'react';
import intl from 'react-intl-universal';

import { Timeline, Typography } from 'antd';
import Share from '@/reconstruction/components/public/FlashShare';

import dayjs from 'dayjs';
import c from 'classnames';
import _ from 'lodash';
import {
    formaTimerLetter,
    getHtmlPlainText,
    sourceUrlHandler,
    currentLocaleUrl,
    filterSpace,
    getTagsLen,
} from '@/utils';
import styles from './list.module.less';

interface Props {
    type?: string;
    keyword?: string;
    options: any[];
    /** 是否搜索快讯 */
    isSearch?: boolean;
}

const { Paragraph } = Typography;

const FlashNewsList: React.FC<Props> = (props) => {
    const { options = [], type, keyword, isSearch = false } = props;
    const [showIdList, setShowIdList] = useState<Record<string, boolean>>({});

    const newOptions = useMemo(() => {
        return formaTimerLetter([...options]) || {};
    }, [options]);

    const renderTimeItem = (newlist: any[]) => {
        return (
            <Timeline mode="left">
                {_.map([...newlist], (item: any) => {
                    const {
                        pk,
                        code,
                        seo_url,
                        title = '',
                        datetime,
                        source_url,
                        introduction = '',
                        source_id,
                        source_code,
                        tag_info = [],
                        thumbnail_pics = [],
                    } = item;

                    const href = `/article/${seo_url || code}`;
                    const timer = datetime ? dayjs(datetime).format('HH:mm') : '';
                    const cover = thumbnail_pics[0]?.url;

                    const introdu = filterSpace(getHtmlPlainText(introduction));

                    const tags = getTagsLen(tag_info, 3, keyword);

                    const sourceLink = sourceUrlHandler(source_code, source_id, source_url);

                    const onExpandPg = (e: any, is: boolean) => {
                        e.stopPropagation && e.stopPropagation();
                        setShowIdList((v) => ({ ...v, [pk]: is }));
                    };

                    const allSymbol = () => ({
                        rows: 3,
                        expandable: true,
                        symbol: (
                            <span
                                onClick={(e) => {
                                    onExpandPg(e, true);
                                }}
                                className={styles.flashNewsdesc_link}
                            >
                                <span className={styles.flashNewsdesc_link_down}>
                                    {intl.get('Common.Unfold').d('展开')}
                                    {intl.get('Common.All').d('全部')}
                                    <i
                                        className="iconfont icon-jiantou"
                                        style={{
                                            fontSize: '14px',
                                            marginLeft: 4,
                                            transform: 'rotateZ(90deg)',
                                        }}
                                    />
                                </span>
                            </span>
                        ),
                    });

                    // 用于展开和收缩全部
                    const introduText = () => (
                        <Paragraph className={styles.flashNewsdesc_box} ellipsis={showIdList[pk] ? false : allSymbol()}>
                            {introdu}

                            {/* 用于收缩时挂载 */}
                            {showIdList[pk] && (
                                <span
                                    onClick={(e) => {
                                        onExpandPg(e, false);
                                    }}
                                    className={styles.flashNewsdesc_link}
                                >
                                    <span className={styles.flashNewsdesc_link_up}>
                                        {intl.get('Common.PackUp').d('收起')}
                                        {intl.get('Common.All').d('全部')}
                                        <i
                                            className="iconfont icon-jiantou"
                                            style={{
                                                fontSize: '14px',
                                                marginLeft: 4,
                                                transform: 'rotateZ(-90deg)',
                                            }}
                                        />
                                    </span>
                                </span>
                            )}
                        </Paragraph>
                    );

                    return (
                        // 当前时间段快讯信息
                        <Timeline.Item
                            key={pk}
                            className={c(styles.timeline_item_box, styles.timeline_item_box_act)}
                            label={<span className={styles.flashNewsTimer}>{timer}</span>}
                        >
                            <div className={styles.item_box}>
                                <div key={pk} className={styles.flashNewsItem}>
                                    <div className={styles.flashNewsCon}>
                                        {/* 标题 */}
                                        <a
                                            className={styles.flashNewsTitle}
                                            href={currentLocaleUrl(href)}
                                            target="_blank"
                                            dangerouslySetInnerHTML={{
                                                __html: title,
                                            }}
                                        />

                                        {/* 详情描述 */}
                                        {introdu ? <>{introduText()}</> : null}

                                        <div className={styles.flashNewsMsg}>
                                            {isSearch ? (
                                                <div className={styles.flashNewsTag}>
                                                    {_.map(tags, (tag: any, tagIndex: number) => {
                                                        return (
                                                            <a
                                                                key={tag}
                                                                target="_blank"
                                                                href={currentLocaleUrl(`/keyword?tags=${tag}`)}
                                                                className={
                                                                    keyword !== '原创' &&
                                                                    keyword === tag &&
                                                                    type === 'tag'
                                                                        ? styles.searchOriginal
                                                                        : undefined
                                                                }
                                                            >
                                                                {tag}
                                                                {tagIndex !== tags.length - 1 ? '/' : ''}
                                                            </a>
                                                        );
                                                    })}
                                                </div>
                                            ) : (
                                                <div className={c(styles.sourceUrl)}>
                                                    {sourceLink && (
                                                        <a target="_blank" href={currentLocaleUrl(sourceLink)}>
                                                            <i className={styles.sourceUrl_icon} />
                                                            {intl.get('PersonalCenter.OriginalLink').d('原文链接')}
                                                        </a>
                                                    )}
                                                </div>
                                            )}

                                            {/* 图标展示 */}
                                            <div className={styles.flashNewsIcon}>
                                                <Share
                                                    type="twitter"
                                                    size={28}
                                                    title={title}
                                                    cover={cover}
                                                    style={{ marginRight: 20 }}
                                                />
                                                <Share type="facebook" size={28} title={title} cover={cover} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Timeline.Item>
                    );
                })}
            </Timeline>
        );
    };

    return (
        <div className={styles.flashNewsContain}>
            {_.map(Object.keys(newOptions), (date: any) => {
                const dateTimer = Number(date);
                const yearDate = dayjs(dateTimer).format('YYYY.MM.DD');
                const isTodayDate = dayjs(yearDate).isToday();
                const newlist = newOptions[date] || [];

                return (
                    <div key={date} className={styles.flashNewsList}>
                        {/* 日信息日期展示 */}
                        {!isTodayDate && newlist[0] ? (
                            <div className={styles.flashNewsDate}>
                                <em className={styles.flashNewsDate_icon} />
                                {yearDate}
                            </div>
                        ) : null}

                        {/* 日快讯信息  */}
                        <div className={styles.flashNewsListContent}>{renderTimeItem(newlist)}</div>
                    </div>
                );
            })}
        </div>
    );
};

export default FlashNewsList;
