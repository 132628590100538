import React, { useMemo, useCallback } from 'react';

import c from 'classnames';
import { Timeline } from 'antd';
import dayjs from 'dayjs';

import { formaTimerLetter, currentLocaleUrl } from '@/utils';

import styles from './list.module.less';

interface Props {
    type?: string;
    keyword?: string;
    options: any[];
}

const FlashNewsList: React.FC<Props> = (props) => {
    const { options = [], type, keyword } = props;

    const newOptions = useMemo(() => {
        return formaTimerLetter([...options]) || {};
    }, [options]);

    // 搜索替换
    const replaceTitle = (tit: string, val: string) => {
        return (tit || '').replace(new RegExp('' + val + '', 'g'), `<span>${val}</span>`);
    };

    // 搜索时 用到
    const formatTitle = useCallback(
        (tit: string) => {
            switch (type) {
                case 'tag':
                    return tit;
                case 'title':
                    return keyword ? replaceTitle(tit, keyword) : tit;
                default:
                    return tit;
            }
        },
        [type, keyword],
    );

    return (
        <div className={styles.flashNewsContain}>
            <Timeline>
                {Object.keys(newOptions).map((date: any, boxIndex: number) => {
                    const dateTimer = Number(date);
                    const yearDate = dayjs(dateTimer).format('YYYY-MM-DD');
                    const isTodayDate = dayjs(yearDate).isToday();

                    const newlist = newOptions[date] || [];

                    return (
                        <React.Fragment key={date}>
                            {[...newlist].map((item: any, i: number) => {
                                const { pk, code, seo_url, title = '', datetime } = item;

                                const href = `/article/${seo_url || code}`;
                                const timer = datetime ? dayjs(datetime).format('HH:mm') : '';

                                return (
                                    // 当前时间段快讯信息
                                    <React.Fragment key={pk}>
                                        {/* 单列一行显示日期 */}
                                        {i === 0 && !isTodayDate && (
                                            <Timeline.Item
                                                className={c(
                                                    styles.timeline_item_box,
                                                    styles.timeline_item_date,
                                                    styles.timeline_item_box_act,
                                                    styles.flashNewsDate_icon,
                                                )}
                                            >
                                                <div className={styles.flashNewsDate}>{yearDate}</div>
                                            </Timeline.Item>
                                        )}

                                        {/* 正文区域 */}
                                        <Timeline.Item
                                            className={c(styles.timeline_item_box, styles.timeline_item_box_act)}
                                        >
                                            <div key={pk} className={styles.flashNewsItem}>
                                                <div className={styles.flashNewsCon}>
                                                    {/* 标题 */}
                                                    <a
                                                        className={styles.flashNewsTitle}
                                                        href={currentLocaleUrl(href)}
                                                        target="_blank"
                                                        dangerouslySetInnerHTML={{
                                                            __html: formatTitle(title),
                                                        }}
                                                    />

                                                    {/* 快讯日期时分秒 */}
                                                    <div className={styles.flashNewsTimer}>
                                                        <span>{timer}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </Timeline.Item>
                                    </React.Fragment>
                                );
                            })}
                        </React.Fragment>
                    );
                })}
            </Timeline>
        </div>
    );
};

export default FlashNewsList;
