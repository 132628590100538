import React, { useCallback, useEffect } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { setRootId, setActiveNavId } from '@/store/config';
import { useAppDispatch, useAppSelector } from '@/store/hook';
import { currentLocaleUrl, formatMts } from '@/utils';
import SwiperCore, { Mousewheel } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import c from 'classnames';
import styles from './index.module.less';

SwiperCore.use([Mousewheel]);
interface HeaderNavProps {
    menus: any[];
    inSearch?: boolean;
}

const HeaderNav: React.FC<HeaderNavProps> = ({ menus = [], inSearch }) => {
    const router = useRouter();
    const dispatch = useAppDispatch();
    const { activeNavId } = useAppSelector((state: any) => state.config);

    useEffect(() => {
        dispatch(setRootId(menus[0]?.inventedUrl || menus[0]?.id));
    }, [dispatch, menus]);

    useEffect(() => {
        // 除了菜单页 首页默认取菜单的第一个id 其余页面不设置id

        const { id } = router.query || {};
        if (id) {
            // addHrefEventListener();
            dispatch(setActiveNavId(id));
            return;
        }

        if (router.route === '/') {
            dispatch(setActiveNavId(menus[0]?.id || ''));
            return;
        }
    }, [router.query.id, menus]);

    /**
     * 渲染nav每一项
     */
    const navItemRender = useCallback((item: any, isIndex: boolean) => {
        const { id, name, nameMts, web, type, inventedUrl } = item;
        const navMtsName = formatMts(nameMts) || name;

        switch (type) {
            case 'page':
                return (
                    <Link href={isIndex ? '/' : `/${inventedUrl || id}`}>
                        <div
                            className={styles.aHref}
                            onClick={() => {
                                dispatch(setActiveNavId(inventedUrl || id));
                            }}
                        >
                            <span>{navMtsName}</span>
                        </div>
                    </Link>
                );

            case 'web':
                const { url } = web;
                return (
                    <a className={styles.aHref} href={currentLocaleUrl(url)} target="_blank">
                        <span>{navMtsName}</span>
                    </a>
                );
            default:
                return null;
        }
    }, []);

    return (
        <nav className={c({ [styles.nav]: true, [styles.nav_hide]: inSearch })}>
            <Swiper
                observer
                observeParents
                mousewheel
                resizeObserver
                grabCursor
                slidesPerView="auto"
                // slideToClickedSlide
                slidesOffsetBefore={-16}
                className={c(styles.first_list)}
            >
                {menus.map((item: any, i: number) => {
                    const { id, inventedUrl } = item;
                    return (
                        <SwiperSlide
                            className={c(styles.first_list_item, {
                                [styles.active]: [inventedUrl, id].includes(activeNavId),
                            })}
                            key={`${id}_${inventedUrl}`}
                        >
                            {navItemRender(item, i === 0)}
                        </SwiperSlide>
                    );
                })}
            </Swiper>
        </nav>
    );
};

export default HeaderNav;
