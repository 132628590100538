import React, { useMemo } from 'react';
import Head from 'next/head';
import intl from 'react-intl-universal';

interface Props {
    title?: string;
    keywords?: string;
    description?: string;
    ogImage?: string;
    site_name?: string;
    env_tag?: string;
}

const Meta: React.FC<Props> = (props) => {
    const lang = intl.getInitOptions().currentLocale;

    const { title, titleMTS, keywords, keywordsMTS, description, descriptionMTS, ogImage }: any =
        process.env.NEXT_PUBLIC_SETTING;

    const pageTitle = useMemo(() => {
        // 传入的title自带站名后缀则不需要加后缀了
        const resultTitle = props.title || intl.get(titleMTS).d(title);
        const resultSiteName = props.site_name || 'Hawk Insight';
        return resultTitle?.includes(`_${resultSiteName}`) ? resultTitle : `${resultTitle}_${resultSiteName}`;
    }, [props.title, props.site_name, title, titleMTS, lang]);

    return (
        <Head>
            <title>{pageTitle}</title>
            {/* UAT环境禁止搜索引擎抓取 */}
            {props.env_tag === 'UAT' ? (
                <>
                    {/* 谷歌站长工具验证 */}
                    <meta name="google-site-verification" content="lZNvJyptNGJMMb5INhnRm2ym777cjtFXC0_W_P7lck8" />
                    {/* 通用禁止 */}
                    <meta name="robots" content="noindex,nofollow" />
                    {/* 禁止Google 搜索 */}
                    <meta name="googlebot" content="noindex, nofollow" />
                </>
            ) : null}
            {/* SEO */}
            <meta name="keywords" content={props.keywords || intl.get(keywordsMTS).d(keywords)} />
            <meta name="description" content={props.description || intl.get(descriptionMTS).d(description)} />
            <meta property="og:title" content={props.title || intl.get(titleMTS).d(title)} />
            <meta property="og:image" content={props.ogImage || ogImage} />
            <meta property="og:description" content={props.description || intl.get(descriptionMTS).d(description)} />
            {/* twitter */}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={props.title || intl.get(titleMTS).d(title)} />
            <meta name="twitter:description" content={props.description || intl.get(descriptionMTS).d(description)} />
            <meta name="twitter:image" content={props.ogImage || ogImage} />
            <meta name="google-adsense-account" content="ca-pub-1111738937300894" />
            <link rel="icon" href="/favicon.ico" />
            {/* <meta http-equiv="Content-Security-Policy" content="script-src 'self'; object-src 'none'; style-src cdn.example.org third-party.org; child-src https:"></meta> */}
        </Head>
    );
};

export default Meta;
