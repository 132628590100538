import React from 'react';
import c from 'classnames';
import { NextImage } from '@/components';
import styles from './index.module.less';
import intl from 'react-intl-universal';
interface DataEmptyProp {
    height?: number | string;
    emptyImg?: string;
    emptyText?: string;
}

const DataEmpty: React.FC<DataEmptyProp> = (props) => {
    const {
        height,
        emptyImg = '/img/place/null_data.webp',
        emptyText = intl.get('Common.NoData').d('暂无数据'),
    } = props;

    return (
        <div className={c('data-empty', styles.empty)} style={{ height }}>
            <NextImage width={380} height={305} src={emptyImg} alt="empty" />
            <span>{emptyText}</span>
        </div>
    );
};

export default DataEmpty;
