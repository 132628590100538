import React from 'react';
import c from 'classnames';
import { NextImage } from '@/components';
import { currentLocaleUrl, formatTimer, getArticleViewNum } from '@/utils';
import styles from './index.module.less';
import intl from 'react-intl-universal';

const ArticleItem = ({ item }: any) => {
    const {
        code,
        title,
        view_num,
        is_original,
        is_topping,
        datetime,
        rule_name,
        ugc,
        thumbnail_pics = [],
        seo_url,
    } = item || {};
    const thumbnail = thumbnail_pics[0] || {};
    const timer = formatTimer(datetime);

    return (
        <a href={currentLocaleUrl(`/article/${seo_url || code}`)} target="_blank">
            {thumbnail.url ? (
                <div className={styles.articleCover}>
                    <NextImage src={thumbnail.url} layout="fill" alt={title} />
                    <span className={c(styles.videoPlay, 'iconfont', 'icon-shipin')} />
                </div>
            ) : null}

            <div className={styles.articleCon}>
                <div className={styles.articleTitle}>
                    {is_topping === 1 ? (
                        <span className={c(styles.top, is_original !== 1 ? styles.margin : null)}>
                            {intl.get('Broker.Top').d('置顶')}
                        </span>
                    ) : null}
                    {is_original === 1 ? (
                        <span
                            className={c(
                                styles.origin,
                                intl.getInitOptions().currentLocale === 'en' ? styles['en_origin'] : '',
                            )}
                        >
                            {intl.get('Common.Original').d('原创')}
                        </span>
                    ) : null}
                    <h2>{title}</h2>
                </div>

                <div className={styles.articleMsg}>
                    {ugc?.name || rule_name ? <span className={styles.msga}>{ugc?.name || rule_name}</span> : null}
                    <div>
                        <span className={styles.msgb}>{timer}</span>
                        <span className={styles.msgc}>
                            <span className={c(styles.icon, 'iconfont', 'icon-liulanliang')} />
                            {getArticleViewNum(view_num || 0)}
                        </span>
                    </div>
                </div>
            </div>
        </a>
    );
};

export default ArticleItem;
