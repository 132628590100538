import { BrokerDataType, ReputationDataType } from '@/type/broker';
import request from '@/utils/axios';

/**
 * 查询交易商详情
 * @param id
 */
export const getBrokerDetail = (id: string, locale?: string, noMsg?: boolean) => {
    return request.get(`/japi/broker/yz/${id}`, {}, { locale, noMsg });
};
/**
 * 查询交易商榜单列表
 * @param params
 */
export const getBillboardList = (
    params?: { pageSize?: string | number; page_size?: string | number; page?: string | number },
    locale?: string,
) => {
    return request.get('/japi/broker/billboard/list', params, { locale });
};
/**
 * 查询交易商列表
 * @param params
 */
export const getBrokerList = async (
    params?: {
        pageSize?: string | number;
        page?: string | number;
        /** 查询类型 0-全部 1-推荐 2-置顶 */
        is_top_or_recommend?: 0 | 1 | 2;
        /** 排序字段 评分 ：score  ，评级 ：rate */
        sort_field?: 'score' | 'rate';
        /** 排序方式 -1 - 倒序  ;1 - 升序 默认 降序 */
        sort_type?: -1 | 1;
        /** 根据name查询 */
        full_name?: string;
        /** 根据简称查询 */
        name_abbreviation?: string;
        /** 业务类型 */
        type?: number;
    },
    locale?: string,
) => {
    return request.get('/japi/broker/yz/list', params, { locale });
};
/**
 * 查询交易商榜单详情
 * @param params
 */
export const getBillboardDetails = (params: { id: string | number }, extra?: any) => {
    return request.get('/japi/broker/billboard/details', params, extra);
};
/**
 * 查询交易商校验页面筛选排序值
 */
export const getBrokerValues = (params: { type: number }, locale?: string) => {
    return request.get('/japi/broker/yz/dealer/values', params, { locale });
};
/**
 * 查询交易商筛选后的列表
 */
export const getScreenList = (data: any, locale?: string) => {
    return request.post('/japi/broker/switch/screen/list', data, { locale });
};

/**
 * 查询交易商推荐列表
 * @param params
 */
export const getBrokerTopOrRecList = (params: { is_top_or_recommend: string; state: string | number }) => {
    return request.get('/japi/broker/yz/list', params);
};

/**
 * 查询交易商是否停用
 * @param params
 */
export const getBrokerIsStop = (ids: any[]) => {
    return request.post('/japi/broker/switch/determine', { ids });
};

/**
 * 查询交易商对比字段列表
 *
 */
export const getBrokerSwitchList = (type: number, locale?: string) => {
    return request.get(`/japi/broker/switch/list?type=${type}`, {}, { locale });
};
/**
 * 查询业务列表
 */
export const getBusinessList = (locale?: string) => {
    return request.get('/japi/broker/business', {}, { locale });
};

/**
 * 查询信誉列表
 */
export const getReputationList = (
    params: {
        brokerId: string;
        page?: number;
        pageSize?: number;
    },
    extra?: any,
): API.Response<{ list: ReputationDataType[]; total: number }> => {
    return request.get(`/japi/broker/reputation/exposure/list`, params, extra);
};

/**
 * 查询举报详情
 */
export const getReputationDetail = (id: string, locale?: string): API.Response<ReputationDataType> => {
    return request.get(`/japi/broker/reputation/exposure/detail/${id}`, {}, { locale });
};

/**
 * 发布曝光
 */
export const addExposure = (data: {
    /** 交易商ID */
    brokerId: string;
    /** 曝光标题 */
    exposureTitle: string;
    /** 曝光内容 */
    content: string;
    /** 曝光图片 */
    exposurePicture?: string[];
}): API.Response<{ _id: string }> => {
    return request.post(`/japi/broker/reputation/exposure/add`, data);
};

/**
 * 发布举报
 */
export const addReport = (data: {
    /** 信誉id */
    exposureId: string;
    /** 举报内容 */
    reportDescribe: string;
    /** 举报联系方式 */
    contactWay: string;
    /** 举报图片 */
    reportPicture?: string[];
}): API.Response<null> => {
    return request.post('/japi/broker/reputation/report/add', data);
};

/**
 * 获取同类型至多 3 个对比交易商，可能少于3个
 */
export const getThreeContrast = (locale?: string): API.Response<any> => {
    return request.get('/japi/broker/yz/random/contrast', {}, { locale });
};

/**
 * 榜单推荐接口 （5条）
 */
export const getRecommendList = (locale?: string): API.Response<any> => {
    return request.get('/japi/broker/billboard/recommend', {}, { locale });
};

/**
 * 点赞 / 踩
 */
export const onLikeOrNo = (parmas: { id: string | number; type: number }, locale?: string): API.Response<any> => {
    return request.get('/japi/broker/billboard/do/user', parmas, { locale });
};
/**
 * 获取交易商详情列表
 */
export const getBrokerDetailList = (data: { ids: string[] }, locale?: string): API.Response<BrokerDataType[]> => {
    return request.post('/japi/broker/yz/select/list', data, { locale });
};
