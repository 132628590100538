import { useEffect, useState } from 'react';
import { NextImage } from '@/components';
import {
    WeiboShareButton,
    FacebookShareButton,
    TwitterShareButton
} from 'next-share';


interface Props {
    type: 'weibo' | 'facebook' | 'twitter'
    style?: any,
    size?: number, // 图标大小
    title?: string, // 标题
    url?: any, // 地址
    cover?: string // 封面
}

const Share: React.FC<Props> = ({ size, title, url, cover, type, style = {} }) => {
    const [shareUrl, setShareUrl] = useState<string>('')

    useEffect(() => {
        setShareUrl(url || location.href)
    }, [url])

    switch (type) {
        case 'weibo':
            return (
                <WeiboShareButton
                    url={shareUrl}
                    title={`【${title}】`}
                    image={cover}
                    style={{ width: size, height: size, ...style }}
                >
                    <NextImage src='/bind/wb.webp' width={size} height={size} needPrefix alt='wb' />
                </WeiboShareButton>
            )
        case 'facebook':
            return (
                <FacebookShareButton
                    url={shareUrl}
                    quote={title}
                    style={{ width: size, height: size, ...style }}
                >
                    <NextImage src='/bind/facebook.webp' width={size} height={size} needPrefix alt='facebook' />
                </FacebookShareButton>
            )
        case 'twitter':
            return (
                <TwitterShareButton
                    url={shareUrl}
                    title={title}
                    style={{ width: size, height: size, ...style }}
                >
                    <NextImage src='/bind/twitter.webp' width={size} height={size} needPrefix alt='twitter' />
                </TwitterShareButton>
            )
        default:
            return null
    }
}

export default Share