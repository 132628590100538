import React, { memo, forwardRef } from 'react';
import { Scrollbar } from 'react-scrollbars-custom';
import type { ScrollbarProps } from 'react-scrollbars-custom';
import c from 'classnames';
import styles from './index.module.less';

interface CustomScrollProps extends ScrollbarProps {
    className?: any;
    trackYClassName?: any;
    thumbYClassName?: any;
    children?: React.ReactNode;
}

const CustomScroll = memo(
    forwardRef<ScrollbarProps, CustomScrollProps>(({ className, trackYClassName, thumbYClassName, children }, ref) => {
        return (
            <Scrollbar
                ref={ref as any}
                noScrollX
                wrapperProps={{ className: styles.wrapper }}
                scrollerProps={{ className: c(styles.scroller, className) }}
                trackYProps={{ className: c(styles['track-y-props'], trackYClassName) }}
                thumbYProps={{ className: c(styles['thumb-y-props'], thumbYClassName) }}
            >
                {children}
            </Scrollbar>
        );
    }),
);

export default CustomScroll;
