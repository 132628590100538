import React from 'react';

import { formatMts } from '@/utils/mts';

import type { FooterProps } from '../types';
import styles from './index.module.less';
import { FooterMenus } from './components/index';

const Footer: React.FC<FooterProps> = ({ config, menus }) => {
    const { icp_msg, icp_msg_mts, footer, footer_mts } = config || {};

    return (
        <footer className={styles.footer} id="footer">
            <FooterMenus menus={menus || []} />
            <div className={styles.contain}>
                <div
                    className={styles.risk_tip}
                    id="risk_tip"
                    dangerouslySetInnerHTML={{ __html: formatMts(footer_mts) || footer }}
                />

                <div
                    className={styles.icp_msg}
                    id="copyright"
                    dangerouslySetInnerHTML={{ __html: formatMts(icp_msg_mts) || icp_msg }}
                />
            </div>
        </footer>
    );
};

export default Footer;
