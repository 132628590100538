import React from 'react';
import intl from 'react-intl-universal';
import c from 'classnames';

import { calcDay, currentLocaleUrl } from '@/utils';
import { NextImage } from '@/components';

import styles from './index.module.less';
import { ActivityListItemType } from '../type';
import { useActivityTime, useStateInfo } from '../hook';

const PCActivityListItem = (props: ActivityListItemType) => {
    const { dataSource, className, layout, showRegion = true } = props;
    const { id, picture, title, synopsis, activityStartTimeMon, activityEndTimeMon, city } = dataSource || {};

    /** 格式显示时间*/
    const { activityTime } = useActivityTime(activityStartTimeMon, activityEndTimeMon);

    /** 获取状态信息*/
    const { activityState, region, countdownPrefix } = useStateInfo(activityStartTimeMon, activityEndTimeMon, city);

    return (
        <a
            href={currentLocaleUrl(`/activity/${id}`)}
            className={c(styles.wrapper, className || '', { [styles.small]: layout === 'small' })}
            target="_blank"
        >
            <div className={c(styles.listItem)}>
                <div className={styles.listItemCover}>
                    <NextImage src={picture} layout="fill" alt={title} />
                </div>
                <div className={styles.listItemContent}>
                    <div className={styles.title_content}>
                        <div className={styles.title}>{title}</div>
                        <div className={styles.synopsis} style={{ marginTop: showRegion ? 4 : 8 }}>
                            {synopsis}
                        </div>
                    </div>
                    <div className={styles.time_container}>
                        {showRegion && (
                            <div className={styles.time}>
                                <span className={c(styles.arrow__more, 'iconfont', 'icon-shijian1')} />
                                <span>{activityTime}</span>
                            </div>
                        )}
                        <div className={styles.btns}>
                            {showRegion ? (
                                <span className={styles.city}>
                                    <i className={c(styles.icon, 'iconfont', 'icon-dingwei')} />
                                    <span>{region}</span>
                                </span>
                            ) : (
                                <span className={styles.city}>
                                    <span className={c(styles.arrow__more, 'iconfont', 'icon-shijian1')} />
                                    <span>{activityTime}</span>
                                </span>
                            )}
                            {activityState === 1 && (
                                <div className={styles.countdown}>
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: intl.get('Activity.ManyDayEvent', {
                                                field1: countdownPrefix,
                                                field2: intl.get('Activity.Start').d('开始'),
                                                field3: styles.before,
                                            }),
                                        }}
                                    />
                                </div>
                            )}
                            {activityState === 2 && (
                                <span className={c(styles.countdown, styles.progressing)}>
                                    <span>{intl.get('Activity.Progress').d('进行中')}</span>
                                </span>
                            )}
                            {activityState === 3 && (
                                <span className={styles.countdown}>
                                    <span className={styles.end}>{intl.get('Activity.Over').d('已结束')}</span>
                                </span>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </a>
    );
};

export default PCActivityListItem;
