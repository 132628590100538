import React, { useCallback, useEffect } from 'react';
import Link from 'next/link';
import { setRootId, setActiveNavId } from '@/store/config';
import { useAppDispatch, useAppSelector } from '@/store/hook';
import {
    // addHrefEventListener,
    currentLocaleUrl,
    formatMts,
} from '@/utils';
import { Scrollbar } from '@/components';
import IcpMsg from '../../IcpMsg';
import c from 'classnames';
import { useRouter } from 'next/router';
import type { NavBarProps } from '../types';
import styles from './index.module.less';

const NavBar: React.FC<NavBarProps> = (props) => {
    const { menus = [], config } = props;

    const router = useRouter();
    const dispatch = useAppDispatch();
    const menuOpen = true;
    const { topHeaderHeight: topHeight, activeNavId } = useAppSelector((state: any) => state.config);

    // 除了菜单页 首页默认取菜单的第一个id 其余页面不设置id
    useEffect(() => {
        dispatch(setRootId(menus[0]?.inventedUrl || menus[0]?.id));
    }, [dispatch, menus]);

    useEffect(() => {
        // 除了菜单页 首页默认取菜单的第一个id 其余页面不设置id

        const { id } = router.query || {};
        if (id) {
            // addHrefEventListener();
            dispatch(setActiveNavId(id));
            return;
        }

        if (router.route === '/') {
            const { id } = menus[0] || {};
            dispatch(setActiveNavId(id || ''));
            return;
        }
    }, [router.query.id, menus]);

    /**
     * 渲染菜单每项
     */
    const navItemRender = useCallback((e: any, isIndex: boolean) => {
        const { id, name, nameMts, web, type, icon, inventedUrl } = e;
        const navMtsName = formatMts(nameMts) || name;
        switch (type) {
            case 'page':
                return (
                    <Link href={isIndex ? '/' : `/${inventedUrl || id}`}>
                        <div
                            className={styles.aHref}
                            onClick={() => {
                                dispatch(setActiveNavId(id));
                            }}
                        >
                            <div>
                                <span className={c(styles.icon, 'iconfont', icon)} />
                                <span>{navMtsName}</span>
                            </div>
                        </div>
                    </Link>
                );

            case 'web':
                const { url } = web;
                return (
                    <a className={styles.aHref} href={currentLocaleUrl(url)} target="_blank">
                        <div>
                            <span className={c(styles.icon, 'iconfont', icon)} />
                            <span>{navMtsName}</span>
                        </div>
                    </a>
                );
            default:
                return null;
        }
    }, []);

    return (
        <aside
            className={c({ [styles.menu_box]: true, [styles.menu_open]: menuOpen })}
            style={{ paddingTop: topHeight }}
        >
            <Scrollbar noScrollX trackYClassName={styles.recScrollTrackY} thumbYClassName={styles.recScrollThumbY}>
                <menu className={styles.menu}>
                    {[...menus].map((e: any, i: number) => {
                        const { id: eId, inventedUrl } = e;
                        return (
                            <li
                                key={eId}
                                className={c(styles.item, {
                                    [styles.active]: [inventedUrl, eId].includes(activeNavId),
                                })}
                            >
                                {navItemRender(e, i === 0)}
                            </li>
                        );
                    })}
                </menu>

                <IcpMsg content={config?.icp_msg} mtsContent={config?.icp_msg_mts} />
            </Scrollbar>
        </aside>
    );
};

export default NavBar;
