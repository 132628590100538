import React, { useMemo, useCallback } from 'react';
import { currentLocaleUrl } from '@/utils';
import { formatFileItem } from '@/utils/broker';
import TransferCom from '@/components/public/TransferCom';
import MBrokerItem from './m';
import type { BrokerItemProps } from '../type';

/**
 * 列表样式
 * @param props
 * @returns
 */
const BrokerItem: React.FC<BrokerItemProps> = (props) => {
    const { record } = props || {};

    const { id, seo_url, broker_datum, broker_other_info } = record;

    /**
     * 开户信息
     */
    const accountUrl = useMemo(() => {
        const { data_upload } = broker_other_info || {};
        const openAccountItem = (broker_datum?.open_account || data_upload?.open_account || [])[0];
        const { url } = formatFileItem(openAccountItem, id, 'account') || {};
        return url;
    }, [id, broker_datum, broker_other_info, formatFileItem]);

    /**
     * 点击项
     */
    const onClickItem = useCallback(
        (event: any) => {
            event?.stopPropagation();
            event?.nativeEvent.stopImmediatePropagation();
            window.open(currentLocaleUrl(`/broker/${seo_url || id}`));
        },
        [seo_url, id],
    );

    /**
     * 去开户
     * @param event
     * @param url
     */
    const onOpenAccount = (event: any, url?: string) => {
        event?.preventDefault();
        event?.stopPropagation();
        event?.nativeEvent.stopImmediatePropagation();

        if (url) {
            window.open(currentLocaleUrl(url));
        }
    };

    const normalProps = {
        accountUrl,
        onClickItem,
        onOpenAccount,
    };

    return <TransferCom m={<MBrokerItem {...props} {...normalProps} />} ipad={null} pc={null} />;
};

export default BrokerItem;
