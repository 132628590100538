import TransferCom from '@/components/public/TransferCom';

import H5ArticlesList from './h5';
import PCArticlesList from './pc';
import type { ArticlesListPros } from './type';

const HomeColumnArticleList = (props: ArticlesListPros) => {
    return (
        <TransferCom
            m={<H5ArticlesList {...props} />}
            ipad={<PCArticlesList {...props} />}
            pc={<PCArticlesList {...props} />}
        />
    );
};

export default HomeColumnArticleList;
