import _ from "lodash"
import { useEffect, useRef, useState } from "react"
import type { UseGetShowTitleParams } from "./type";

/** 获取是否展示标题 */
export const useGetShowTitle = (props: UseGetShowTitleParams) => {
    const { rollDistance } = props;
    // 是否展示标题
    const [showTitle, setShowTitle] = useState<boolean>(false)
    // 滚动距离
    const rollTop = useRef<number>(0);

    useEffect(() => { // 处理在滚动展示标题
        if (!rollDistance) {
            setShowTitle(false)
            return;
        };

        const onScroll = _.throttle(() => {
            const top = document.documentElement.scrollTop || document.body.scrollTop;

            if (rollTop.current > top) { // 向上滚动
                setShowTitle(false);
            } else {
                setShowTitle(top > rollDistance)
            }

            rollTop.current = top;
        }, 200);

        window.addEventListener('scroll', onScroll, { passive: true });
        return () => {
            window.removeEventListener('scroll', onScroll);
        }
    }, [rollDistance, rollTop])

    return showTitle;
}