import { useState, useEffect } from 'react';
import intl from 'react-intl-universal';

import { Swiper, SwiperSlide } from 'swiper/react';
import { useAppSelector } from '@/store/hook';

import request from '@/utils/axios';
import { currentLocaleUrl, getVisualSkipPage, formatMts } from '@/utils';

import style from './index.module.less';
import { apiLangHeaderMap } from '@/constants/mts';

import { IProps } from '../../type';

interface Props extends IProps {}

const NewsFlash: React.FC<Props> = (props) => {
    const { navsId = [], serviceData, groupTitleUrl } = props;

    const [list, setList] = useState<any[]>(serviceData?.list ?? []);

    // 切换语言同步数据
    useEffect(() => {
        setList(serviceData?.list ?? []);
    }, [serviceData]);

    const { imgPrefix } = useAppSelector((state: any) => {
        return state.config;
    });

    useEffect(() => {
        fetchData();

        const interval = setInterval(() => {
            fetchData();
        }, 2000);

        return () => clearInterval(interval);
    }, []);

    const fetchData = async (i?: number) => {
        const res = await request.get(
            '/japi/article/quick/list',
            {
                page: 1,
                pageSize: 20,
                ids: navsId?.join(','),
            },
            {
                locale: apiLangHeaderMap[intl.getInitOptions().currentLocale || 'zh-CN'] || 'zh',
            },
        );
        if (res?.state === 1) {
            const resList: any[] = res?.data?.list || [];
            if (!resList.length) {
                return;
            }
            const oldKeys = new Set(list.map((obj) => obj.pk));
            const isExist = resList.every((obj) => oldKeys.has(obj.pk));

            if (!isExist) {
                setList(resList);
            }
        }
    };

    return (
        <div className={style.flash_box}>
            <div className={style.top_box}>
                <span className={style.top_title}>
                    {intl.get('PersonalCenter.Newest').d('最新')}
                    {intl.get('PersonalCenter.Flash').d('快讯')}
                </span>
                {groupTitleUrl && Object.keys(groupTitleUrl).length && (
                    <a
                        href={currentLocaleUrl(getVisualSkipPage(groupTitleUrl))}
                        className={style.top_link}
                        target="_blank"
                    >
                        {intl.get('Common.SeeMore').d('查看更多')}
                    </a>
                )}
            </div>

            {/* 快讯内容 */}
            <div className={style.flash_list}>
                <Swiper
                    loop={list.length > 1}
                    observer
                    resizeObserver
                    autoplay={{
                        delay: 5 * 1000,
                        disableOnInteraction: false,
                    }}
                    grabCursor
                >
                    {list?.map((item: any, index: number) => {
                        const { seo_url, code } = item;

                        return (
                            <SwiperSlide key={item?.pk}>
                                <span className={style.flash_item}>
                                    <a
                                        target="_blank"
                                        href={currentLocaleUrl(`/article/${seo_url || code}`)}
                                        key={index}
                                    >
                                        {item.title}
                                    </a>
                                </span>
                            </SwiperSlide>
                        );
                    })}
                </Swiper>
            </div>
        </div>
    );
};

export default NewsFlash;
