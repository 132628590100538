import React from 'react';
import TransferCom from '@/components/public/TransferCom';
import MHeader from './m';
import PcHeader from './pc';

const HeaderMenu: React.FC = (props) => {
    return <TransferCom m={<MHeader />} ipad={<PcHeader {...props} />} pc={<PcHeader {...props} />} />;
};

export default HeaderMenu;
