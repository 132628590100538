import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Form, Input } from 'antd';
import { NextImage } from '@/components';
import InfiniteScroller from 'react-infinite-scroller';
import { getBrokerList } from '@/services/broker';
import { useAppSelector, useAppDispatch } from '@/store/hook';
import { setCanScroll } from '@/store/config';
import { Swiper, SwiperSlide } from 'swiper/react';
import NoContent from '@/components/public/NoContent';
import debounce from 'lodash/debounce';
import c from 'classnames';
import intl from 'react-intl-universal';
import formUtils from '@/utils/form';
import type { BrokerSelectProps, BrokerSearchProps } from './types';

import styles from './index.module.less';

const { pleaseEnter } = formUtils;

const BrokerMenu: React.FC<BrokerSelectProps> = (props) => {
    const currentLocale = intl.getInitOptions().currentLocale;
    const { open, activeType, activeIndex, businessList = [], onClose, onChange } = props;

    const listRef = useRef<any>(null);
    const [form] = Form.useForm();
    const [searchVal, setSearchValue] = useState<string>('');

    // 当前选择交易商的业务类型
    const [businessId, setBusinessId] = useState<number | undefined>(undefined);
    // 交易商下拉选择
    const [brokerList, setBrokerList] = useState<any[]>([]);
    // 业务类型选中id
    const [activeTabId, setActiveTabId] = useState<number | undefined>(undefined);
    // 下拉列表
    const { current: pagination } = useRef<BrokerSearchProps>({
        page: 1,
        page_size: 20,
        sort_field: 'score',
        sort_type: -1,
    }); // 分页信息

    const [hasMore, setHasMore] = useState<boolean>(true);
    const { current: page } = useRef({ pageSize: 20, total: 0, current: 1, loading: false, scrollTop: 0 });

    /**
     * 设置当前选择的交易商业务类型
     */
    useEffect(() => {
        if (activeType) {
            setBusinessId(activeType);
            setActiveTabId(activeType);
        } else {
            const { id: firstId }: { id: number } = businessList[0] || {};
            setBusinessId(undefined);
            setActiveTabId(firstId);
        }
    }, [activeType, businessList]);

    // 自定义搜索
    const onSearchChange = debounce((e: any) => {
        const { value } = e?.target || {};
        setSearchValue(value);
        getBrokerFun(1, value);
    }, 200);

    const onClearInput = () => {
        onSearchChange('');
        form?.setFieldsValue({ selVal: '' });
    };

    // 数据改变
    const onValueChange = (v: any) => {
        if (v) {
            setSearchValue('');
            form?.setFieldsValue({ selVal: '' });
            onChange?.(v, activeIndex);
            onClose?.();
        }
    };

    /**
     * 业务列表 选择
     * @param item
     */
    const onBusinessTabClick = (item: any) => {
        const { id }: { id: number } = item;
        pagination.type = activeTabId;
        setActiveTabId(id);
    };

    // 获取交易商列表
    const getBrokerFun = useCallback(
        (current: number, shName?: string) => {
            page.loading = true;
            getBrokerList({
                ...pagination,
                page: current,
                name_abbreviation: shName,
            })
                .then((res: any) => {
                    const { state, data } = res || {};
                    if (state === 1) {
                        const { list = [], total } = data || {};
                        if (current === 1) {
                            setBrokerList(
                                (list || []).map((item: any) => {
                                    return { ...item, value: item?.id, label: item?.name_abbreviation };
                                }),
                            );
                        } else {
                            setBrokerList((l: any[]) => {
                                return [...l, ...(list || [])].map((item: any) => {
                                    return { ...item, value: item?.id, label: item?.name_abbreviation };
                                });
                            });
                        }
                        setHasMore(Math.ceil(total / page.pageSize) > current);
                        page.total = total;
                        page.current = current;
                    }
                })
                .finally(() => {
                    page.loading = false;
                });
        },
        [page],
    );

    useEffect(() => {
        if (open) {
            if (activeTabId) {
                pagination.type = activeTabId;
            }
            getBrokerFun(1);
        }
    }, [open, activeTabId, currentLocale, getBrokerFun]);

    /**
     * 禁用/启用页面滚动
     */
    useEffect(() => {
        if (open) {
            document.body.style.height = '100vh';
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.height = 'auto';
            document.body.style.overflow = 'auto';
        }
    }, [open]);

    const loadMoreData = useCallback(() => {
        if (page.loading || !hasMore) {
            return;
        }
        getBrokerFun(page.current + 1, searchVal);
    }, [getBrokerFun, page, hasMore, searchVal]);

    return (
        <div
            ref={listRef}
            className={c(styles.brokerMenu, open ? styles.brokerMenuOpen : null)}
            style={{ top: open ? 45 : '100vh' }}
        >
            <div className={styles.searchInputBox}>
                <Form form={form} className={styles.form}>
                    <Form.Item noStyle name="selVal">
                        <Input
                            placeholder={pleaseEnter('')}
                            className={styles.searchInput}
                            onInput={onSearchChange}
                            suffix={
                                searchVal ? (
                                    <span
                                        onClick={onClearInput}
                                        className={c(styles.closeIcon, 'iconfont', 'icon-shanchu')}
                                    />
                                ) : null
                            }
                            prefix={<span className={c(styles.prefix, 'iconfont', 'icon-sousuo')} />}
                        />
                    </Form.Item>
                </Form>

                {!businessId && (
                    <div className={styles.swiperBox}>
                        <Swiper observer resizeObserver grabCursor slidesPerView="auto" spaceBetween={16}>
                            {businessList.map((item: any) => (
                                <SwiperSlide
                                    className={c(styles.tabsItem, {
                                        [styles.active]: activeTabId === item.id,
                                    })}
                                    key={item.id}
                                    onClick={() => onBusinessTabClick(item)}
                                >
                                    {item.business}
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                )}
            </div>

            <InfiniteScroller
                initialLoad={false}
                pageStart={1}
                loadMore={loadMoreData}
                hasMore={hasMore}
                useWindow={false}
                getScrollParent={() => listRef?.current}
            >
                <ul className={styles.brokerMenuList}>
                    {brokerList.length !== 0 ? (
                        brokerList.map((item: any) => {
                            const { id, logo, value, label, business_name } = item || {};

                            return (
                                <li
                                    key={value}
                                    className={c(styles.selOption, {
                                        [styles.selOptionActive]: activeType === id,
                                    })}
                                    onClick={() => {
                                        onValueChange(item);
                                    }}
                                >
                                    <div className={c(styles.logo, 'logo')}>
                                        <NextImage
                                            src={logo}
                                            alt={business_name}
                                            place="logo_a_new"
                                            width={28}
                                            height={28}
                                        />
                                    </div>
                                    <span className={c(styles.text, 'text')}>{label}</span>
                                </li>
                            );
                        })
                    ) : (
                        <div className={styles.null_data}>
                            <NoContent />
                        </div>
                    )}
                </ul>
            </InfiniteScroller>
        </div>
    );
};

export default BrokerMenu;
