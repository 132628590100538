export const scriptRegex = {
    /** 获取script标签 */
    scriptTagRegex: /<script[^>]*>(.*?)<\/script>/gis,
    /** 获取script标签的内容 */
    scriptContentRegex: /<script\b[^>]*>([\s\S]*?)<\/script>/,
    /** 获取script的async属性 */
    asyncRegex: /async(?=\s|\b|=|$)/,
    /** 获取script的crossorigin属性 */
    crossoriginRegex:
        /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*crossorigin=\"([^\"]*)\"[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/,
    /** 获取script的src内容 */
    srcRegex: /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*src=\"([^\"]*)\"[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/,
};

/** 国内 11 位手机号码 */
export const cnPhoneReg = /^1[3-9]\d{9}$/;

/** 邮箱 */
export const contactWay = /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/;

/** 6位数字验证码 */
export const codePattern = /^[0-9]{6}$/;

/** 密码 */
export const passwordPattern = /^[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~`]{8,16}$/;
