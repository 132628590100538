import React from 'react';

import TransferCom from '@/components/public/TransferCom';
import MHeader from './m';
import PcHeader from './pc';

import type { HeaderMarketProps } from './types';

const Market: React.FC<HeaderMarketProps> = (props) => {
    return <TransferCom m={<MHeader {...props} />} ipad={<PcHeader {...props} />} pc={<PcHeader {...props} />} />;
};

export default Market;
