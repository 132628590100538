export const codeList = [
    {
        name: '联系我们',
        mts: 'Header.ContactUs',
        icon: 'icon-connect',
        list: [
            {
                title: 'whatsapp',
                phone: '852-54941066',
                codeUrl: '/download/whatsapp0416.webp',
            },
            {
                title: 'wechat',
                phone: '85254941066',
                codeUrl: '/download/wechat0416.webp',
            },
        ],
    },
    {
        name: 'APP',
        icon: 'icon-app1',
        link: '/app',
    },
];
